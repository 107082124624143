import { EquipamentoGrupoTarifacaoTipoEnum } from '../../../equipamento/equipamento.model';
export interface Alocacao {
    id: string;
    ativa: boolean;
    equipamento: any;
    contrato: any;
    codigo: string;
    codigoRecomendado: string;
    inicioVigencia: string;
    fimVigencia: string;
    dataInativacao: string;
    grupoTarifacaoTipo: EquipamentoGrupoTarifacaoTipoEnum;
    valorHoraDisponivelMaquina?: any;
    valorHoraDisponivelVeiculo?: any;
    valorHoraProduzida?: any;
    valorKmRodado?: any;
    usuarioEmail?: string;
    usuarioId?: string;
    created_at?:string;
}