import { Component, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from '../shared/components';
import { Beneficiaria } from './beneficiaria.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeneficiariaService } from './beneficiaria.service';
import { catchErr } from '../shared/utils';
import { Router } from '@angular/router';
import { NgxMaskPipe } from 'ngx-mask';

@Component({
    selector: 'app-beneficiaria',
    templateUrl: './beneficiaria.component.html',
    providers:[NgxMaskPipe]
})
export class BeneficiariaComponent implements OnInit {

    loading = true;
    filter: string;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Beneficiárias' },
    ]
    
    dataSource: Beneficiaria[]; 
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        { header: 'CNPJ', attr: 'cnpj' },
        { 
            tdClass: 'td-w-auto',
            btnProps: { label: 'Visualizar', color: 'accent' },
            click: {
              action: (rowData: Beneficiaria) => this.router.navigateByUrl(`/beneficiaria/${rowData.id}/visualizar`)
            }
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
              action: (rowData: Beneficiaria) => this.router.navigateByUrl(`/beneficiaria/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private beneficiariaService: BeneficiariaService,
        private maskPipe: NgxMaskPipe,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent? pageEvent.pageSize : this.pagination.pageSize

        this.beneficiariaService.getPaginated<Beneficiaria>(this.pagination.current, this.pagination.pageSize, this.filter).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((beneficiaria) => ({...beneficiaria, cnpj: this.maskPipe.transform(beneficiaria.cnpj, 'CPF_CNPJ')}))
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

}
