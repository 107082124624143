
interface EquipamentoImagem {
    id: string;
    key: string;
    name: string;
    url: string;
}

export interface EquipamentoCategoria {
    id: string;
    nome: string;
    imagens: EquipamentoImagem[];
    descricao: string;
}

export interface EquipamentoTipo {
    id: string;
    nome: string;
    imagens: EquipamentoImagem[];
    sigla: string;
    descricao: string;
    categoria: any;
}

export enum EquipamentoGrupoTarifacaoTipoEnum {
    MAQUINAS = 'MAQUINAS',
    VEICULOS = 'VEICULOS',
}

export interface EquipamentoGrupo {
    id: string;
    nome: string;
    imagens: EquipamentoImagem[];
    descricao: string;
    tipo: any;
    tarifacoes: EquipamentoGrupoTarifacaoTipoEnum;

    valorHoraDisponivelMaquina: number;
    valorHoraDisponivelVeiculo: number;
    valorHoraProduzida: number;
    valorKmRodado: number;
}

export interface Equipamento {
    id: string;
    grupo: any;
    marca: string;
    modelo: string;
    descricao: string;
    placa: string;
    fabricacaoAno: string;
    alocacaoAtual: any;
}

export function convertGrupoTarifacaoName(tarifacao: EquipamentoGrupoTarifacaoTipoEnum) {
    switch (tarifacao) {
        case EquipamentoGrupoTarifacaoTipoEnum.MAQUINAS:
            return 'Máquinas';
        case EquipamentoGrupoTarifacaoTipoEnum.VEICULOS:
            return 'Veículos';
        default:
            return tarifacao;
    }
}