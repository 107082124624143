
<div mat-dialog-title>Ajustar Faturamento do {{ equipamento.alocacaoCodigo }} ({{ equipamento.equipamentoPlaca }})</div>
<div mat-dialog-content>
    <mat-spinner class="mx-auto my-4" *ngIf="loading.main"></mat-spinner>
    <ng-container *ngIf="!loading.main">
        <div class="sticky-top d-flex bg-white pt-1 pb-3">
            <div class="callout border-success w-100 me-2">
                <div class="row">
                    <h2 class="fs-6 text-dark mb-2">TOTAL FATURAMENTO</h2>
                    <div class="col-12 col-md-4">
                        <div class="fw-bold fs-7 opacity-75">VALOR BASE</div>
                        <div>{{ totalizadores.custo | currency:'BRL' }}</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="fw-bold fs-7 text-primary">AJUSTADO</div>
                        <div class="text-primary">{{ totalizadores.valorReajustado ? (totalizadores.valorReajustado | currency:'BRL') : '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="fw-bold fs-7 text-success">ECONOMIA</div>
                        <div class="text-success">{{ totalizadores.diferenca ? (totalizadores.diferenca | currency:'BRL') : '---' }}</div>
                    </div>
                </div>
            </div>
            <div class="callout border-blue-300 w-100 ms-2">
                <div class="row">
                    <h2 class="fs-6 text-dark mb-2">CUSTO {{ equipamento.alocacaoCodigo }}</h2>
                    <div class="col-12 col-md-4">
                        <div class="fw-bold fs-7 opacity-75">VALOR BASE</div>
                        <div>{{ equipamento.custo }}</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="fw-bold fs-7 text-primary">AJUSTADO</div>
                        <div class="text-primary">{{ equipamento.valorReajustado ? (equipamento.valorReajustado | currency:'BRL') : '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="fw-bold fs-7 text-success">ECONOMIA</div>
                        <div class="text-success">{{ equipamento.diferenca ? (equipamento.diferenca | currency:'BRL') : '---' }}</div>
                    </div>
                </div>
            </div>
        </div>
        <table>
            <thead>
                <tr class="border-top border-bottom bg-light">
                    <th>Nº DIO</th>
                    <th>Serviço</th>
                    <th>Fim Operação</th>
                    <th>Orgão</th>
                    <th>Local de Apresentação</th>
                    <th>Custo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataSourceDIOs">
                    <td>{{ item.numero }}</td>
                    <td>{{ item.servico }}</td>
                    <td>{{ item.dataPeriodoFimOperacao }}</td>
                    <td>{{ item.orgao || '---' }}</td>
                    <td>{{ item.localApresentacao || '---' }}</td>
                    <td>
                        <mat-form-field appearance="outline" class="input-sm">
                            <input matInput [(ngModel)]="item.precificacoes.value" autocomplete="off"
                                currencyMask [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }">
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<div mat-dialog-actions class="px-3 pb-3 justify-content-end" *ngIf="!loading.main">
    <button mat-button (click)="closeDialog()" class="px-3">Fechar</button>
    <button mat-flat-button color="primary" (click)="simularFaturamento()" 
        [ngClass]="{'btn-dialog-spinner': loading.simular}" [disabled]="loading.simular">
        <mat-spinner class="btn-spinner" *ngIf="loading.simular"></mat-spinner>
        <span *ngIf="!loading.simular">Simular</span>
    </button>
</div>