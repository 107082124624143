import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../shared/components';
import { RolesEnum } from '../core/auth/roles';

@Component({
    selector: 'app-relatorio',
    templateUrl: './relatorio.component.html'
})
export class RelatorioComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios' },
    ]

    filteredRelatorios: any [];
    relatorios: any = [
        { titulo: 'DIO\'s Não Executados', routerLink: 'dio-nao-executado', descricao: 'Relatório de diário de operações não executados por contratos.', skipRoles: [RolesEnum.EMISSOR] },
        { titulo: 'Consumo por equipamento', routerLink: 'consumo-por-equipamento', descricao: 'Relatório de consumo por equipamento', skipRoles: [RolesEnum.EMISSOR] },
        { titulo: 'Valores Brutos por Beneficiários', routerLink: 'valor-bruto-beneficiario', descricao: 'Relatório de valores a serem pagos por tipo de tarifação (maquina ou veículo) de um determinado contrato em um período.', skipRoles: [RolesEnum.EMISSOR] },
        { titulo: 'Valores Monetário Brutos', routerLink: 'valor-bruto-monetario', descricao: 'Relatório de valores brutos monetários de diário de operações.', skipRoles: [RolesEnum.EMISSOR] },
        { titulo: 'Equipamentos Existentes', routerLink: 'equipamentos-existentes', descricao: 'Relatório com o status dos equipamentos/veículos cadastrados.', skipRoles: [] },
        { titulo: 'Valores Brutos Por Equipamentos', routerLink: 'valores-brutos-equipamentos', descricao: 'Relatório com o valor a ser pago por equipamentos.', skipRoles: [RolesEnum.EMISSOR] },
        { titulo: 'Aferições Por Período', routerLink: 'relatorio-afericoes', descricao: 'Relatório quantativo de DIOs com status Pendentes de Aferição e Aferidas.', skipRoles: [RolesEnum.GESTAO_ADMIN, RolesEnum.COORDENADOR] },
    ]

    ngOnInit() {
        this.filteredRelatorios = this.relatorios;
    }

    filter(term: string) {
        if (!term || term === '') {
            this.filteredRelatorios = Object.assign([], this.relatorios);
        }
        this.filteredRelatorios = Object.assign([], this.relatorios).filter(
            (item: any) => item.titulo.toLowerCase().indexOf(term.toLowerCase()) > -1
                || item.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1
        );
    }

    deveExibirRelatorio(skipRoles: RolesEnum[]) {
        return !skipRoles.includes(RolesEnum.EMISSOR);
    }


}
