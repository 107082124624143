import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormUsuarioComponent, ProfileComponent, UsuarioComponent, UsuarioService, ViewUsuarioComponent } from '.';
import { UsuarioAusenciaService } from './usuario-ausencia.service';
import { FormUsuarioAusenciaComponent } from './form-ausencia/form-ausencia.component';

@NgModule({
    declarations: [
        UsuarioComponent,
        FormUsuarioComponent,
        FormUsuarioAusenciaComponent,
        ProfileComponent,
        ViewUsuarioComponent
    ],
    imports: [ SharedModule ],
    providers: [
        UsuarioService,
        UsuarioAusenciaService
    ]
})
export class UsuarioModule { }
