<div class="d-flex align-items-end mb-2">
    <h1 class="fs-3 fw-light mb-0">Alocações</h1>
    <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/contrato', contrato.id, 'alocacao', 'nova']">NOVA ALOCAÇÃO</button>
</div>
<div class="d-flex align-items-center border-top p-2">
    <div class="flex-shrink-0">
        <mat-icon class="text-primary">description</mat-icon>
    </div>
    <div class="flex-grow-1 ms-2">
        <span class="fw-bold fs-7 text-muted">{{ contrato.nome | uppercase }}</span>
        <div class="opacity-50 fs-7 mb-2">{{ contrato.beneficiaria.nome }}</div>
    </div>
</div>
<generate-table 
    [dataSource]="dataSource" [columns]="columns" [loading]="loading"
    [total]="pagination.total" [pageSize]="pagination.pageSize" 
    (pageEvent)="search($event)"
></generate-table>