import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicoService } from '../servico.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { Servico } from '../servico.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-form-servico',
    templateUrl: './form-servico.component.html',
})
export class FormServicoComponent {

    loading: boolean;
    showInputError = showInputError;
    servico: Servico;

    constructor(
        private dialogRef: MatDialogRef<FormServicoComponent>,
        private servicoService: ServicoService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { servico: Servico },
    ) {
        this.servico = { ...data.servico }
    }

    save() {
        this.loading = true;
        let request: Observable<any>

        request = this.servico.id
            ? this.servicoService.update<Servico>(this.servico.id, this.servico)
            : this.servicoService.create<Servico>(this.servico)

        request.subscribe({
            next: () => {
                this.snackBar.open('Serviço salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
