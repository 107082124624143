<h1 mat-dialog-title class="px-3">Cancelar DIO</h1>
<div mat-dialog-content class="overflow-visible px-3 pb-0">
    <mat-form-field class="w-100">
        <mat-label>Motivo do Cancelamento</mat-label>
        <textarea type="textarea" rows="8" matInput name="motivo" [(ngModel)]="motivo"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="px-3 pb-3 justify-content-end">
    <button mat-button mat-dialog-close class="px-3">Fechar</button>
    <button mat-flat-button color="warn" (click)="cancelar()" [ngClass]="{'btn-dialog-spinner': loading}" [disabled]="!motivo ||  loading">
        <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">Cancelar</span>
    </button>
</div>