import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { Equipamento, EquipamentoGrupo } from '../../equipamento.model';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipamentoService } from '../../equipamento.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { EquipamentoGrupoService } from '../..';

@Component({
    selector: 'app-form-equipamento',
    templateUrl: './form-equipamento.component.html'
})
export class FormEquipamentoComponent implements OnInit {

    loading: any = { main: true, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    equipamento = {} as Equipamento;
    grupos: EquipamentoGrupo[];

    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private equipamentoService: EquipamentoService,
        private grupoService: EquipamentoGrupoService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.equipamento.id = this.activatedRoute.snapshot.params['id'];
        this.title = this.equipamento.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Equipamentos', route: '/equipamento', queryParams: { tab: 'equipamentos' } },
            { label: this.title + ' equipamento' },
        ]

        this.grupoService.getAll<EquipamentoGrupo>().subscribe({
            next: (res) => {
                this.grupos = res.data;
                if (this.equipamento.id) {
                    this.equipamentoService.getById<Equipamento>(this.equipamento.id).subscribe({
                        next: (res) => this.equipamento = res,
                        error: (err) => catchErr(err, this.snackBar)
                    }).add(() => this.loading.main = false);
                }
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    compareWith(item: any, selected: any) {
        return selected && item == selected.id
    }

    save() {
        this.loading.save = true;
        let request: Observable<Equipamento>

        const equipToSave = { ...this.equipamento }
        if (equipToSave.id) {
            equipToSave.grupo = equipToSave.grupo.id;
            request = this.equipamentoService.update<Equipamento>(equipToSave.id, equipToSave)
        } else {
            request = this.equipamentoService.create<Equipamento>(equipToSave)
        }

        request.subscribe({
            next: () => {
                this.snackBar.open('Equipamento salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/equipamento'], { queryParams: { tab: 'equipamentos' } });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show('equipamento', (dialogRef: MatDialogRef<any>) => {
            this.equipamentoService.remove<Equipamento>(this.equipamento.id).subscribe({
                next: () => {
                    this.snackBar.open('Equipamento apagado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/equipamento'], { queryParams: { tab: 'equipamentos' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }
}
