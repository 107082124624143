import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericCRUDService } from '../shared/services';
import { Data, Pagination } from '../shared/models';
import { Condutor } from './condutor.model';

@Injectable({
    providedIn: 'root'
})
export class CondutorService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/condutor`;
    }

    override getPaginated<Condutor>(page: number, pageSize: number, beneficiariaId?: string): Observable<Pagination<Condutor>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        beneficiariaId && params.append('beneficiariaId', `${beneficiariaId}`);

        return this.http.get<Pagination<Condutor>>(`${this.url}?${params}`);
    }

    getAllByBeneficiaria(beneficiariaId: string) {
      const params = new URLSearchParams();
      params.append('removePagination', `true`);
      params.append('beneficiariaId', beneficiariaId);
      return this.http.get<Data<Condutor>>(`${this.url}?${params}`);
    }

    removerCondutor<Condutor>(condutorId: string, beneficiariaId: string):  Observable<Condutor> {
        return this.http.delete<Condutor>(`${this.url}/${condutorId}/beneficiarias/${beneficiariaId}`);
    }
}
