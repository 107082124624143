<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>
        <mat-card-content *ngIf="!loading">
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light me-auto mb-0">Anotações</h1>
                <button mat-button color="warn" class="px-4" (click)="showRemoveDialog()" *ngIf="condutor.anotacao!.length">APAGAR ANOTAÇÕES</button>
                <button mat-flat-button color="primary" class="ms-3" (click)="showFormDialog()">NOVA ANOTAÇÃO</button>
            </div>
            <div class="d-flex align-items-start border-top pt-4 mb-2">
                <div class="flex-shrink-0">
                    <img height="110" width="110" class="img-cover rounded-circle mb-3 bg-default" [src]="condutor.avatarUrl || '/assets/img/default_user.jpeg'" />
                </div>
                <div class="row flex-grow-1 ms-3">
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">CONDUTOR</span>
                        <div class="lh-sm">{{ condutor.firstName | titlecase }} {{ condutor.lastName | titlecase }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">EMAIL</span>
                        <div class="lh-sm">{{ condutor.email }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">TELEFONE</span>
                        <div class="lh-sm">{{ condutor.telefone }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3 mb-md-0">
                        <span class="fw-bold fs-7 opacity-50">CNH</span>
                        <div class="lh-sm">{{ condutor.CNH }}</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <span class="fw-bold fs-7 opacity-50">VALIDADE CNH</span>
                        <div class="lh-sm">{{ condutor.validadeCNH | date:'dd/MM/yyyy' }}</div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!condutor.anotacao || (condutor.anotacao && !condutor.anotacao.length)">
                <mat-divider></mat-divider>
                <div class="text-center opacity-50 mt-4 mb-3">
                    Nenhuma anotação encontrada!
                </div>
            </ng-container>
            <div class="mb-2" *ngFor="let anotacao of condutor.anotacao">
                <div class="fs-7 opacity-50 mt-3 mb-1 ms-2">{{ anotacao.created_at | date:'short' }}</div>
                <div class="chat ms-1">
                    <div class="chat-body bg-gray-200">
                        <div class="fw-light lh-sm">{{ anotacao.anotacao }}</div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>