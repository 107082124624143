import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Data, Pagination } from '../models';
import { NumberFormatStyle } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class GenericCRUDService {

    private _url: string

    constructor(public http: HttpClient) { }

    get url(): string {
        return this._url;
    }

    set url(route: string) {
        this._url = `${environment.managerURL}${route}`
    }

    getAll<T>(): Observable<Data<T>> {        
        return this.http.get<Data<T>>(`${this._url}?removePagination=true`);
    }

    getPaginated<T>(page: number, pageSize: NumberFormatStyle): Observable<Pagination<T>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);

        return this.http.get<Pagination<T>>(`${this._url}?${params}`);
    }

    getById<T>(id: string): Observable<T> {
        return this.http.get<T>(`${this._url}/${id}`);
    }

    create<T>(object: T): Observable<T> {
        return this.http.post<T>(`${this._url}`, object);
    }

    update<T>(id: string, object: T): Observable<T> {
        return this.http.patch<T>(`${this._url}/${id}`, object);
    }

    remove<T>(id: string): Observable<T> {
        return this.http.delete<T>(`${this._url}/${id}`);
    }

    inativar<T>(id: string): Observable<T> {
        return this.http.patch<T>(`${this._url}/${id}/inativar`, {});
    }
}
