<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>

    <mat-card>
        <mat-card-content>
            <div class="row" *ngIf="!loading">
                <div class="col-12 d-flex align-items-end mb-2">
                    <h1 class="fs-3 fw-light mb-0">Faturamento {{ faturamento.id }}</h1>        
                </div>
                <div class="col-12 mb-3">
                    <mat-divider></mat-divider>
                </div>
                <div class="col-12 mb-3">
                    <span class="fw-bold fs-7 opacity-50">CONTRATO</span>
                    <div class="lh-sm">{{ faturamento.contrato.nome }}</div>
                </div>
                <div class="col-12 mb-3">
                    <span class="fw-bold fs-7 opacity-50">STATUS</span>
                    <div class="lh-sm">{{ faturamento.status || '---' }}</div>
                </div>
                <div class="col-12 mb-3">
                    <span class="fw-bold fs-7 opacity-50">DATA BASE INICIO</span>
                    <div class="lh-sm">{{ faturamento.dataBaseInicio || '---' }}</div>
                </div>
                <div class="col-12 mb-3">
                    <span class="fw-bold fs-7 opacity-50">DATA BASE FIM</span>
                    <div class="lh-sm">{{ faturamento.dataBaseFim || '---' }}</div>
                </div>

                <!-- LISTA DE DIOS -->
                <generate-table [dataSource]="dataSource" [columns]="columns" [loading]="loading" [total]="pagination.total"
                    [pageSize]="pagination.pageSize" (pageEvent)="search($event)"></generate-table>

                <div class="col-12 mb-3">
                    <mat-divider></mat-divider>
                </div>                
            </div>

            <div class="col-12 col-md-3" *ngIf="faturamento.status === 'CONCLUIDO'">
                <div>
                    <mat-form-field class="w-100">
                        <mat-label>Justificativa Reabertura</mat-label>
                        <textarea type="textarea" rows="8" matInput name="motivo" [(ngModel)]="justificativaReabertura"></textarea>
                    </mat-form-field>
                </div>
                <button mat-flat-button color="primary" class="btn-lg w-100"
                    [disabled]="loading" (click)="reabrir()">
                    <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
                    <span *ngIf="!loading">REABRIR FATURAMENTO</span>
                </button>                
            </div>
        </mat-card-content>
    </mat-card>
</div>
