import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { Alocacao, AlocacaoService, Contrato, ContratoService } from 'src/app/contrato';
import { Condutor, CondutorService } from '../../condutor';
import { LocaisApresentacaoService, Orgao, OrgaoService, RegiaoAdmin, RegiaoAdminService } from 'src/app/localidade';
import { Servico, ServicoService } from 'src/app/servico';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { StorageService } from 'src/app/shared/services';
import { StoragePageEnum } from 'src/app/shared/models';
import { RolesEnum } from 'src/app/core';
import { DioService } from '../dio.service';
import { Demandante, Dio } from '../dio.model';
import { DemandanteService } from '../demandante.service';
import { forkJoin } from 'rxjs';
import { LocaisApresentacao } from 'src/app/localidade/orgao/view-orgao/locais-apresentacao/locais-apresentacao.model';
import * as dayjs from 'dayjs';


@Component({
    selector: 'app-form-dio',
    templateUrl: './form-dio.component.html'
})
export class FormDioComponent implements OnInit {

    loading: any = { main: true, save: false, alocacao: false, condutor: false };
    breadcrumb: Breadcrumb[] = [];
    dio = {} as Dio;
    title: string;

    alocacoes: Alocacao[];
    condutores: Condutor[];
    contratos: Contrato[];
    demandantes: Demandante[];

    locaisApresentacao: LocaisApresentacao[];
    orgaos: Orgao[];
    regioesAdm: RegiaoAdmin[];
    regioesAdmDemandante: RegiaoAdmin[];
    servicos: Servico[];

    demandante = {} as Demandante;
    showDemandanteForm: boolean;
    disableLocal: boolean;

    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dioService: DioService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
        private alocacaoService: AlocacaoService,
        private regiaoAdmnistrativaService: RegiaoAdminService,
        private locaisApresentacaoService: LocaisApresentacaoService,
        private orgaoService: OrgaoService,
        private condutorService: CondutorService,
        private servicoService: ServicoService,
        private storageService: StorageService,
        private demandanteService: DemandanteService,
        private contratoService: ContratoService,
    ) { }

    ngOnInit() {
        this.dio.id = this.activatedRoute.snapshot.params['idDio'];
        this.title = this.dio.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Diários de Operação', route: '/dio' },
        ];

        this.dio.id && this.breadcrumb.push({ label: 'Visualizar DIO', route: `/dio/${this.dio.id}/visualizar` });
        this.breadcrumb.push({ label: this.title + ' DIO' });

        const storage = this.storageService.storage[StoragePageEnum.FormDio];
        if (storage && storage.dio) {
            this.dio = { ...storage.dio };
            this.storageService.remove(StoragePageEnum.FormDio);
        }

        this.getAllResources();
        if (this.dio.id) {            
            this.loading.main = true;
            this.dioService.getById<Dio>(this.dio.id).subscribe({
                next: (res) => {
                    this.dio = res;
                    this.dio.alocacao = { ...this.dio.alocacao, equipamento: `${this.dio.alocacao.equipamento.marca} ${this.dio.alocacao.equipamento.modelo}` };
                                        
                    this.condutorService.getAllByBeneficiaria(this.dio.contrato.beneficiaria.id).subscribe((res) => {
                        this.condutores = res.data;
                    });
                },
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }
    }

    compareWith(item: any, selected: any) {
        return item.id == selected
    }

    searchFn(term: string, item: any) {
        term = term?.toLocaleLowerCase();
        return item?.nome?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.titulo?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.firstName?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.lastName?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.codigo && item?.codigo.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.equipamento && item?.equipamento.toLocaleLowerCase().indexOf(term) > -1;
    }

    setContrato(contrato: Contrato) {
        this.getConditionalResources(contrato.id, contrato.beneficiaria.id);
        this.dio = { ...this.dio, contrato: contrato.id }
    }

    setOrgao(orgao?: Orgao) {
        this.disableLocal = false;
        if (orgao) {
            this.dio = {
                ...this.dio,
                orgao: orgao.id,
                regiaoAdm: orgao?.regiaoAdministrativa.id,
            };
        } else {
            this.dio = {
                ...this.dio,
                orgao: undefined,
                regiaoAdm: undefined,
                localApresentacao: undefined,
            };
            this.locaisApresentacao = undefined as any;
            this.disableLocal = true;
        }

        if (orgao?.locaisApresentacao) {
            if (!orgao?.locaisApresentacao.length) {
                this.disableLocal = true;
            } else {
                this.locaisApresentacao = orgao?.locaisApresentacao;
                this.dio.localApresentacao = orgao?.locaisApresentacao[0].id;
            }
        }
    }

    setRegiaoAdm(ra: RegiaoAdmin) {
        if (ra) {
            this.dio = {
                ...this.dio,
                regiaoAdm: ra.id,
            };
        }
    }

    addDemandante = ($event: any) => {
        this.showDemandanteForm = true;
        this.demandante.nome = $event;
    }

    routeWithStorage(route: string, objKey: string) {
        !this.dio.id && this.storageService.set(StoragePageEnum.FormCondutor, { condutor: this.dio })
        this.router.navigate([route], { queryParams: { returnObj: objKey, userRole: RolesEnum.CONTRATADO_CONDUTOR } });
    }

    saveOrUpdate() {
        this.dio.id ? this.update() : this.save();
    }

    update() {
        if (!this.dio.id) return;

        this.loading.save = true;
        delete this.dio.endereco;

        const DioToUpdate = {
            ...this.dio,
            servico: this.dio.servico && this.dio.servico.id ? this.dio.servico.id : this.dio.servico,
            alocacao: this.dio.alocacao && this.dio.alocacao.id ? this.dio.alocacao.id : this.dio.alocacao,
            condutor: this.dio.condutor && this.dio.condutor.id ? this.dio.condutor.id : this.dio.condutor,
            contrato: this.dio.contrato && this.dio.contrato.id ? this.dio.contrato.id : this.dio.contrato,
            orgao: this.dio.orgao && this.dio.orgao.id,
            localApresentacao: this.dio.localApresentacao && this.dio.localApresentacao.id,
            execucaoDetalhes: undefined,
            demandante: this.demandante && this.demandante.nome ? this.demandante : this.dio.demandante,
            regiaoAdm: this.dio.regiaoAdm && this.dio.regiaoAdm.id,
        }
        
        this.dioService.update<Dio>(this.dio.id, DioToUpdate).subscribe({
            next: () => {
                this.snackBar.open('DIO editada com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/dio']);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    save() {
        this.loading.save = true;
        delete this.dio.endereco;

        const DioToSave = {
            ...this.dio,
            execucaoDetalhes: undefined,
            demandante: this.demandante && this.demandante.nome ? this.demandante : this.dio.demandante,
        }

        this.dioService.create<Dio>(DioToSave).subscribe({
            next: () => {
                this.snackBar.open('DIO salva com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/dio']);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    private getAllResources() {
        forkJoin([
            this.contratoService.getAll<Contrato>(true),
            this.demandanteService.getAll<Demandante>(),
            this.locaisApresentacaoService.getAll<LocaisApresentacao>(),
            this.orgaoService.getAll<Orgao>(),
            this.regiaoAdmnistrativaService.getAll<RegiaoAdmin>(),
            this.servicoService.getAll<Servico>(),
        ]).subscribe({
            next: ([resContrato, resDemandante, resLocais, resOrgao, resRa, resServico]) => {
                this.contratos = resContrato.data;
                this.demandantes = resDemandante.data;
                this.locaisApresentacao = resLocais.data;
                this.orgaos = resOrgao.data;
                this.regioesAdm = resRa.data;
                this.regioesAdmDemandante = resRa.data;
                this.servicos = resServico.data;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false)
    }

    private getConditionalResources(contratoId: string, beneficiariaId: string) {
        this.loading.alocacao = true;
        this.loading.condutor = true;
        forkJoin([
            this.alocacaoService.getAll<Alocacao>(contratoId, undefined, true),
            this.condutorService.getAllByBeneficiaria(beneficiariaId),
        ]).subscribe({
            next: ([resAlocacao, resCondutor]) => {
                console.log('resAlocacao: ', resAlocacao);
                this.alocacoes = resAlocacao.data.map((alocacao) => ({
                    ...alocacao,
                    placa: alocacao.equipamento.placa,
                    equipamento: `${alocacao.equipamento.marca} ${alocacao.equipamento.modelo}`,
                    inicioVigencia: dayjs(alocacao.inicioVigencia).format('DD/MM/YYYY'),
                    fimVigencia: dayjs(alocacao.fimVigencia).format('DD/MM/YYYY')
                }));
                this.condutores = resCondutor.data;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => {
            this.loading.alocacao = false;
            this.loading.condutor = false;
        });
    }

    showRemoveDialog() {
        this.removeDialogService.show('DIO', (dialogRef: MatDialogRef<any>) => {
            this.dioService.remove<Dio>(this.dio.id || '').subscribe({
                next: () => {
                    this.snackBar.open('DIO apagada com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/dio'], { queryParams: { tab: 'dios' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        }, 'Apagar');
    }
}
