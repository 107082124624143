import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth/auth.service';
import { RolesEnum } from '../core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent {

    currentRole: string;

    constructor(private router: Router, private authService: AuthService) {

        this.currentRole = this.authService.user.realm_access ? this.authService.user.realm_access.roles[0] : '';

        if (this.currentRole === RolesEnum.FUNCIONARIO) {
            router.navigate(['/pendencias/atesto']);
        } else if (this.currentRole === RolesEnum.FISCAL) {
            router.navigate(['/pendencias/validacao']);
        } else if (this.currentRole === RolesEnum.AFERIDOR) {
            router.navigate(['/pendencias/afericao']);
        } else if (this.currentRole === RolesEnum.COORDENADOR) {
            router.navigate(['/pendencias/afericao-aprovacao']);
        } else {
            router.navigate(['/dio']);
        }
    }
}
