import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericCRUDService } from '../shared/services';

@Injectable({
    providedIn: 'root'
})
export class ConfigService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/sisdio-configurations`;
    }

    findAll<Config>(): Observable<Config[]> {
        return this.http.get<Config[]>(this.url);
    }

}
