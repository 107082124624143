import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { UsuarioRegister, UsuarioResponse } from 'src/app/usuario/usuario.model';
import { environment } from 'src/environments/environment';

interface Token {
    hash: string,
    expiresIn: number,
    refresh: string,
    refreshExpiresIn: number,
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    authenticate = new Subject<any>();

    constructor(
        private http: HttpClient,
        private router: Router,
    ) { }

    hasAuthentication(): Observable<boolean> {
        return this.authenticate.asObservable();
    }

    signOut() {
        localStorage.removeItem('SDtoken');
        localStorage.removeItem('SDuser');
        this.authenticate.next(false);
        this.router.navigate(['/sign-in']);
    }

    signInForm(form: { username: string, password: string }): Promise<any> {
        const formDataEncode = new URLSearchParams();
        formDataEncode.append('client_id', 'SISDIO_MANAGER');
        formDataEncode.append('grant_type', 'password');
        formDataEncode.append('username', form.username);
        formDataEncode.append('password', form.password);

        return this.signIn(formDataEncode);
    }

    signInRefreshToken(): Promise<any> {
        const formDataEncode = new URLSearchParams();
        formDataEncode.append('client_id', 'SISDIO_MANAGER');
        formDataEncode.append('grant_type', 'refresh_token');
        formDataEncode.append('refresh_token', this.token.refresh);
     
        return this.signIn(formDataEncode);
    }

    private signIn(formDataEncode: URLSearchParams) {
        const headers: any = { 'Content-Type': 'application/x-www-form-urlencoded' };
        return new Promise((resolve, reject) => {
            this.http.post(`${environment.keycloakURL}/protocol/openid-connect/token`, formDataEncode.toString(), { headers }).subscribe({
                next: (res: any) => {
                    const t = new Date();
                    this.token = {
                        hash: res.access_token,
                        expiresIn: t.setSeconds(t.getSeconds() + res.expires_in),
                        refresh: res.refresh_token,
                        refreshExpiresIn: t.setSeconds(t.getSeconds() + res.refresh_expires_in)
                    };

                    this.http.get(`${environment.keycloakURL}/protocol/openid-connect/userinfo`).subscribe({
                        next: (res: any) => {
                            console.log('res: ', res);
                            const headerX = { authorization: `Bearer ${this.token.hash}` }
                            this.user = res;
                            const rolesUsuario = res.realm_access.roles;
                            const roleNotAllowed = 'CONTRATADO_CONDUTOR';

                            if (rolesUsuario.includes(roleNotAllowed)) {
                              localStorage.removeItem('SDtoken');
                              localStorage.removeItem('AMtoken');
                              localStorage.removeItem('AMuser');
                              localStorage.removeItem('SDuser');
                              this.authenticate.next(false);

                              reject({
                                error: {
                                  error_description: 'Acesso não autorizado para usuários Condutores'
                                }
                              })
                              return;
                            }

                            this.authenticate.next(true);
                            resolve(this.user);
                        }, error: () => reject(false)
                    });
                }, error: (err) => reject(err)
            });
        });
    }

    register(user: UsuarioRegister) {
        return this.http.post(`${environment.managerURL}/users`, user);
    }

    resetPassword(email: string) {
        return this.http.put(`${environment.managerURL}/users/forgot-password`, { email });
    }

    set token(token) {
        localStorage.setItem('SDtoken', JSON.stringify(token));
    }

    get token(): Token {
        const token = localStorage.getItem('SDtoken');
        return token ? JSON.parse(token) : null;
    }

    set user(user) {
        localStorage.setItem('SDuser', JSON.stringify(user));
    }

    get user(): UsuarioResponse {
        const user = localStorage.getItem('SDuser');
        return user ? JSON.parse(user) : null;
    }
}
