import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.authService.token && this.authService.token.hash) {
            request = request.clone({
                setHeaders: { authorization: `Bearer ${this.authService.token.hash}` }
            });
        }

        const timeoutValue = request.headers.get('timeout') || 60000;

        return next.handle(request).pipe(
            timeout(Number(timeoutValue)),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    if (this.authService.token) {
                        this.authService.signInRefreshToken().then(() => {
                            return next.handle(request)
                        }).catch(() => this.signOut());
                    } else {
                        this.signOut()
                    }
                }
                return throwError(err);
            })
        );
    }

    private signOut() {
        this.authService.signOut();
        this.router.navigate(['/sign-in']);
    }
}