<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} Configuração</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Nome/Código da Configuração</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="configuration.nome" required [disabled]="configuration.id !== null && configuration.id !== undefined">
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- VALOR -->
                <mat-form-field class="col-12 col-md-3">
                    <mat-label>Valor</mat-label>
                    <input matInput name="valor" #nome="ngModel" [(ngModel)]="configuration.valor" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Valor é obrigatório</mat-error>
                </mat-form-field>

                <!-- TIPO -->
                <mat-form-field class="col-12 col-md-3">
                    <mat-label>Tipo</mat-label>
                    <input matInput name="tipo" #nome="ngModel" [(ngModel)]="configuration.tipo" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Tipo é obrigatório</mat-error>
                </mat-form-field>

                <!-- DESCRIÇÃO -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel"
                        [(ngModel)]="configuration.descricao"></textarea>
                </mat-form-field>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4"
                        [routerLink]="['/configuracoes']">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100"
                        [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>