<div class="row">
    <div class="col-12 d-flex align-items-end mb-2">
        <h1 class="fs-3 fw-light mb-0">{{ contrato.nome }}</h1>
        <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/contrato', contrato.id, 'editar']">EDITAR CONTRATO</button>
    </div>
    <div class="col-12 mb-2">
        <mat-divider></mat-divider>
    </div>
    <div class="col-12 col-md-4 mb-3">
        <span class="fw-bold fs-7 opacity-50">BENEFICIARIA</span>
        <div class="lh-sm">{{ contrato.beneficiaria.nome }}</div>
    </div>
    <div class="col-12 col-md-4 mb-3">
        <span class="fw-bold fs-7 opacity-50">INICIO</span>
        <div class="lh-sm">{{ contrato.inicioVigencia | date:'dd/MM/yyyy' }}</div>
    </div>
    <div class="col-12 col-md-4 mb-3">
        <span class="fw-bold fs-7 opacity-50">FIM</span>
        <div class="lh-sm">{{ contrato.fimVigencia | date:'dd/MM/yyyy' }}</div>
    </div>
    <div class="col-12 col-md-4 mb-3">
        <span class="fw-bold fs-7 opacity-50">VALOR CONTRATADO</span>
        <div class="lh-sm">{{ contrato.valorContratado | currency:'BRL' }}</div>
    </div>
    <div class="col-12 col-md-4 mb-3">
        <span class="fw-bold fs-7 opacity-50">VALOR EXECUTADO</span>
        <div class="lh-sm">{{ contrato.valorExecutado | currency:'BRL' }}</div>
    </div>
    <div class="col-12 col-md-4 mb-3">
        <span class="fw-bold fs-7 opacity-50">SEI</span>
        <div class="lh-sm">{{ contrato.sei }}</div>
    </div>
    <div class="col-12 mb-3">
        <span class="fw-bold fs-7 opacity-50">DESCRIÇÃO</span>
        <div>{{ contrato.descricao }}</div>
    </div>
    <div class="col-12 mb-3">
        <span class="fw-bold fs-7 opacity-50">EMPRESÁRIO RESPONSÁVEL</span>
        <mat-list role="list" class="list-border">
            <mat-list-item class="py-1 ps-2 pe-0" role="listitem">
                <div class="d-flex align-items-center">
                    <img height="40" width="40" class="img-cover rounded-circle bg-default" 
                        [src]="contrato.contratado.avatarUrl || '/assets/img/default_user.jpeg'" />
                    <div class="d-flex flex-column ms-3 me-auto">
                        <span>{{ contrato.contratado.firstName }} {{ contrato.contratado.lastName }}</span>
                        <span class="opacity-50 fs-7">{{ contrato.contratado.email }}</span>
                    </div>
                    <button mat-icon-button color="primary" [routerLink]="['/usuario', contrato.contratado.id, 'visualizar']" >
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="col-12 col-md-6 mb-3 mb-md-0">
        <span class="fw-bold fs-7 opacity-50">SERVIDOR NOVACAP RESPONSÁVEL</span>
        <mat-list role="list" class="list-border">
            <mat-list-item class="py-1 ps-2 pe-0" role="listitem">
                <div class="d-flex align-items-center">
                    <img height="40" width="40" class="img-cover rounded-circle bg-default" 
                        [src]="contrato.contratanteResponsavel.avatarUrl || '/assets/img/default_user.jpeg'" />
                    <div class="d-flex flex-column ms-3 me-auto">
                        <div>
                            {{ contrato.contratanteResponsavel.firstName }}
                            {{ contrato.contratanteResponsavel.lastName }}
                            <span class="status-badge bg-secondary text-white ms-1">Responsável</span>
                        </div>
                        <span class="opacity-50 fs-7">{{ contrato.contratanteResponsavel.email }}</span>
                    </div>
                    <button mat-icon-button color="primary" [routerLink]="['/usuario', contrato.contratanteResponsavel.id, 'visualizar']" >
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="col-12 col-md-6">
        <mat-list role="list" class="list-border mt-4">
            <mat-list-item class="py-1 ps-2 pe-0" role="listitem">
                <div class="d-flex align-items-center">
                    <img height="40" width="40" class="img-cover rounded-circle bg-default" 
                        [src]="contrato.contratanteSuplente.avatarUrl || '/assets/img/default_user.jpeg'" />
                    <div class="d-flex flex-column ms-3 me-auto">
                        <div>
                            {{ contrato.contratanteSuplente.firstName }}
                            {{ contrato.contratanteSuplente.lastName }}
                            <span class="status-badge bg-secondary text-white ms-1">Suplente</span>
                        </div>
                        <span class="opacity-50 fs-7">{{ contrato.contratanteSuplente.email }}</span>
                    </div>
                    <button mat-icon-button color="primary" [routerLink]="['/usuario', contrato.contratanteSuplente.id, 'visualizar']" >
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
            