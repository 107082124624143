import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from 'src/app/shared/components';
import { ContratoService } from '../contrato.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contrato } from '../contrato.model';
import { catchErr } from 'src/app/shared/utils';

@Component({
    selector: 'app-view-contrato',
    templateUrl: './view-contrato.component.html'
})
export class ViewContratoComponent implements OnInit {

    loading: boolean;
    contrato: Contrato;
    selectedTab: number;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Contratos', route: '/contrato' },
        { label: 'Visualizar contrato' },
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private contratoService: ContratoService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.selectedTab = this.getTab(this.activatedRoute.snapshot.queryParams['tab'])
        this.loading = true;
        this.contratoService.getById<Contrato>(this.activatedRoute.snapshot.params['idContrato']).subscribe({
            next: (res) => this.contrato = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    private getTab(tabName: string) {
        switch (tabName) {
            case 'tarifa': return 1;
            case 'alocacao': return 2;
            case 'dios': return 3;
            default: return 0;
        }
    }
}
