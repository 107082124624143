<mat-spinner class="mx-auto my-5" *ngIf="!condutores"></mat-spinner>

<ng-container *ngIf="condutores">
    <div mat-dialog-title> Associar Condutor</div>
    <div mat-dialog-content class="overflow-visible">
        <mat-form-field class="w-100">
            <mat-label>Filtrar lista de condutores</mat-label>
            <input matInput name="condutor" [(ngModel)]="term" (keyup)="searchCondutor()">
        </mat-form-field>

        <span class="fw-bold fs-7 opacity-50">CONDUTORES</span>
        <mat-selection-list #condutor [(ngModel)]="condutorSelected" [multiple]="false">
            <mat-list-option [ngClass]="{'border-bottom': !last}" *ngFor="let condutor of condutoresFiltered; last as last" [value]="condutor.id">
                {{ condutor.firstName | titlecase }} {{ condutor.lastName | titlecase }}
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div mat-dialog-actions class="px-3 pb-3 justify-content-end">
        <button mat-button mat-dialog-close class="px-3">Fechar</button>
        <button mat-flat-button color="primary" (click)="associarCondutor()" [ngClass]="{'btn-dialog-spinner': loading}" [disabled]="!condutor || loading">
            <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
            <span *ngIf="!loading">Associar</span>
        </button>
    </div>
</ng-container>