import { Component, OnInit } from '@angular/core';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { EquipamentoService } from '../equipamento.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Equipamento } from '../equipamento.model';
import { Pagination } from 'src/app/shared/models';
import { catchErr } from 'src/app/shared/utils';
import { Router } from '@angular/router';

@Component({
    selector: 'equipamento-list',
    templateUrl: './equipamento-list.component.html'
})
export class EquipamentoListComponent implements OnInit {

    loading = true;
    filter: string;
    dataSource: Equipamento[]; 
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Placa/Série', attr: 'placa' },
        { header: 'Marca', attr: 'marca' },
        { header: 'Prefixo', attr: 'alocacaoCodigo' },
        { header: 'Modelo', attr: 'modelo' },
        { header: 'Grupo', attr: 'grupo' },
        { header: 'Informações Adicionais', attr: 'descricao' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Visualizar', color: 'accent' },
            click: {
              action: (rowData: Equipamento) => this.router.navigateByUrl(`/equipamento/${rowData.id}/visualizar`)
            }
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
              action: (rowData: Equipamento) => this.router.navigateByUrl(`/equipamento/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private equipamentoService: EquipamentoService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent? pageEvent.pageSize : this.pagination.pageSize

        this.equipamentoService.getPaginated<Equipamento>(this.pagination.current, this.pagination.pageSize, this.filter).subscribe({
            next: (res: Pagination<Equipamento>) => {
                this.dataSource = res.data.map((equip) => ({ 
                  ...equip, 
                  grupo: `${equip.grupo?.tipo?.nome} / ${equip.grupo?.nome}`, 
                  alocacaoCodigo: equip?.alocacaoAtual?.codigo,
                }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
