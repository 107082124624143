import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericCRUDService } from '../../shared/services';
import { Pagination } from '../../shared/models';
import { NumberFormatStyle } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AnotacaoService extends GenericCRUDService {
    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/anotacao`;
    }

    override getPaginated<T>(page: number, pageSize: NumberFormatStyle, condutorId?: string): Observable<Pagination<T>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);

        if (condutorId) params.append('condutor', condutorId);

        return this.http.get<Pagination<T>>(`${this.url}?${params}`);
    }

    removeByCondutor(condutorId: string) {
        return this.http.delete(`${this.url}/condutor/${condutorId}`);
    }
}
