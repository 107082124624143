import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { EquipamentoGrupo, EquipamentoGrupoTarifacaoTipoEnum, EquipamentoTipo, convertGrupoTarifacaoName } from '../../equipamento.model';
import { EquipamentoGrupoService } from '../equipamento-grupo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { EquipamentoTipoService } from '../..';

@Component({
    selector: 'app-form-equipamento-grupo',
    templateUrl: './form-equipamento-grupo.component.html'
})
export class FormEquipamentoGrupoComponent implements OnInit {

    loading: any = { main: true, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    grupo = {} as EquipamentoGrupo;
    tipos: EquipamentoTipo[];
    tarifacoes: EquipamentoGrupoTarifacaoTipoEnum[] = [EquipamentoGrupoTarifacaoTipoEnum.MAQUINAS, EquipamentoGrupoTarifacaoTipoEnum.VEICULOS];

    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private grupoService: EquipamentoGrupoService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
        private tipoService: EquipamentoTipoService,
    ) { }

    ngOnInit() {
        this.grupo.id = this.activatedRoute.snapshot.params['id'];
        this.title = this.grupo.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Equipamentos', route: '/equipamento', queryParams: { tab: 'grupos' } },
            { label: this.title + ' grupo' },
        ]

        this.tipoService.getAll<EquipamentoTipo>().subscribe({
            next: (res) => {
                this.tipos = res.data;
                if (this.grupo.id)
                    this.getById();
                else
                    this.loading.main = false;
            }, error: (err) => {
                this.loading.main = false;
                catchErr(err, this.snackBar)
            }
        });
    }

    compareWith(item: any, selected: any) {
        return selected && item == selected.id
    }

    save() {
        this.loading.save = true;
        let request: Observable<EquipamentoGrupo>
        request = this.grupo.id
            ? this.grupoService.updateEquipamentoGrupo(this.grupo.id, this.grupo)
            : this.grupoService.createEquipamentoGrupo(this.grupo)

        request.subscribe({
            next: () => {
                this.snackBar.open('Grupo salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/equipamento'], { queryParams: { tab: 'grupos' } });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.grupo.nome, (dialogRef: MatDialogRef<any>) => {
            this.grupoService.remove<EquipamentoGrupo>(this.grupo.id).subscribe({
                next: () => {
                    this.snackBar.open('Grupo apagado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/equipamento'], { queryParams: { tab: 'grupos' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        })
    }

    filesOut($event: any) {
        this.grupo.imagens = this.grupo.imagens ? [...this.grupo.imagens, ...$event] : $event
    }

    fileRemove($event: { file: any, index: number }) {
        if ($event.file.id) {
            this.removeDialogService.show($event.file.name, (dialogRef: MatDialogRef<any>) => {
                dialogRef.componentInstance.showLoading(true);
                this.grupoService.removeImage($event.file.id).subscribe({
                    next: () => {
                        this.snackBar.open('Imagem apagada com sucesso!', '✕', { panelClass: ['success'] });
                        dialogRef.close();
                        this.getById();
                    }, error: (err) => catchErr(err, this.snackBar)
                }).add(() => dialogRef.componentInstance.showLoading(false));
            });
        } else {
            this.grupo.imagens.splice($event.index, 1)
        }
    }

    private getById() {
        this.loading.main = true;
        this.grupoService.getById<EquipamentoGrupo>(this.grupo.id).subscribe({
            next: (res) => this.grupo = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    changeTarifacao(event: EquipamentoGrupoTarifacaoTipoEnum) {
        if (event === EquipamentoGrupoTarifacaoTipoEnum.MAQUINAS) {
            this.grupo.valorHoraDisponivelVeiculo = 0;
            this.grupo.valorKmRodado = 0;
        } else if (event === EquipamentoGrupoTarifacaoTipoEnum.VEICULOS) {
            this.grupo.valorHoraDisponivelMaquina = 0;
            this.grupo.valorHoraProduzida = 0;
        }
    }

    getTarifacaoName(tarifacao: EquipamentoGrupoTarifacaoTipoEnum) {
        return convertGrupoTarifacaoName(tarifacao)
    }

}
