<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} tarifa</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm"
                novalidate>
                <!-- GRUPO -->
                <mat-form-field class="col-12">
                    <mat-label>{{ !grupos.length && !tarifa.id ? 'Todos os grupos foram preenchidos' :
                        'Grupo'}}</mat-label>
                    <mat-select name="grupo" #grupo="ngModel" [(ngModel)]="tarifa.grupoEquipamento"
                        [compareWith]="compareWith" [disabled]="!grupos.length && !tarifa.id" required
                        (selectionChange)="selectGrupo($event.value)">
                        <mat-option *ngFor="let grupo of grupos" [value]="grupo.id">
                            {{ grupo.tipo.sigla }} - {{ grupo.nome }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="showInputError(grupo, 'required')">Grupo é obrigatório</mat-error>
                </mat-form-field>

                <!-- MAQUINAS -->
                <ng-container *ngIf="tarifa.grupoTarifacaoTipo && tarifa.grupoTarifacaoTipo == 'MAQUINAS'">
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/hora produzida</mat-label>
                        <input matInput name="valorHoraProduzida" #valorHoraProduzida="ngModel"
                            [(ngModel)]="tarifa.valorHoraProduzida" required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorHoraProduzida, 'required')">Valor/hora produzida é
                            obrigatória</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/hora disponível</mat-label>
                        <input matInput name="valorHoraDisponivelMaquina" #valorHoraDisponivelMaquina="ngModel"
                            [(ngModel)]="tarifa.valorHoraDisponivelMaquina" required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorHoraDisponivelMaquina, 'required')">Valor/hora disponível
                            é obrigatória</mat-error>
                    </mat-form-field>
                </ng-container>

                <!-- VEÍCULOS -->
                <ng-container *ngIf="tarifa.grupoTarifacaoTipo && tarifa.grupoTarifacaoTipo == 'VEICULOS'">
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/km rodado</mat-label>
                        <input matInput name="valorKmRodado" #valorKmRodado="ngModel" [(ngModel)]="tarifa.valorKmRodado"
                            required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorKmRodado, 'required')">Valor/km rodado é
                            obrigatório</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/hora disponível</mat-label>
                        <input matInput name="valorHoraDisponivelVeiculo" #valorHoraDisponivelVeiculo="ngModel"
                            [(ngModel)]="tarifa.valorHoraDisponivelVeiculo" required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorHoraDisponivelVeiculo, 'required')">Valor/hora disponível
                            é obrigatória</mat-error>
                    </mat-form-field>
                </ng-container>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="tarifa.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4"
                        (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4"
                        [routerLink]="['/contrato', tarifa.contrato.id, 'visualizar']"
                        [queryParams]="{ tab:'tarifa' }">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100"
                        [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>