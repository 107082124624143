<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-card>
        <mat-tab-group mat-stretch-tabs="false" [selectedIndex]="selectedTab">
            <mat-tab label="Detalhes">
                <mat-card-content>
                    <detail-beneficiaria></detail-beneficiaria>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Condutores">
                <mat-card-content>
                    <condutor></condutor>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Contratos">
                <mat-card-content>
                    <div class="d-flex align-items-end mb-3">
                        <h1 class="fs-3 fw-light mb-0">Contratos</h1>
                        <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/contrato', 'novo']" [queryParams]="{ beneficiariaId: beneficiariaId }">NOVO CONTRATO</button>
                    </div>
                    <mat-list role="list" class="list-border" *ngIf="!contratos || !contratos.data || !contratos.data.length">
                        <mat-list-item class="py-3 text-center" role="listitem">
                            <span class="text-muted">Nenhum contrato vinculado à beneficiaria</span>
                        </mat-list-item>
                    </mat-list>
                    <mat-list role="list" class="list-border" *ngIf="contratos && contratos.data && contratos.data.length">
                        <mat-list-item class="py-1 pe-0" role="listitem" *ngFor="let contrato of contratos.data">
                            <div class="d-flex align-items-center">
                                <mat-icon fontSet="material-icons-outlined" class="opacity-75">description</mat-icon>
                                <div class="d-flex flex-column ms-3 me-auto">
                                    <span class="lh-sm">{{ contrato.nome }}</span>
                                    <span class="opacity-50 fs-7">{{ contrato.descricao }}</span>
                                </div>
                                <button mat-icon-button color="primary" [routerLink]="['/contrato', contrato.id, 'visualizar']" >
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>