import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { StoragePageEnum } from '../models';

const STORAGE_NAME = 'SDS';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    storage: { [componentEnum:string]: any };
    private storeDataSubjects: Map<string, BehaviorSubject<any>> = new Map();

    constructor() {
        this.storage = JSON.parse(localStorage.getItem(STORAGE_NAME) || '{}')
        const skeys = Object.keys(this.storage);
        skeys.forEach((key) => {
            if (this.storage[key])
                this.storeDataSubjects.set(key, new BehaviorSubject<any>(this.storage[key]));
        });
    }

    /** LEMBRE-SE: unsubscribe on ngDestroy */
    watch(key: string): Observable<any> | null {
        if (!this.storeDataSubjects.has(key))
            this.storeDataSubjects.set(key, new BehaviorSubject<any>(null));

        const storeDataKey = this.storeDataSubjects.get(key);
        if (storeDataKey) {
            storeDataKey.next(this.storage[key]);
            return storeDataKey.asObservable();
        }

        return null;
    }

    set(key: string, value: any) {
        this.storage[key] = value;
        localStorage.setItem(STORAGE_NAME, JSON.stringify(this.storage));

        const storeDataKey = this.storeDataSubjects.get(key);
        if (!this.storeDataSubjects.has(key) || !storeDataKey) {
            this.storeDataSubjects.set(key, new BehaviorSubject<any>(value));
        } else {
            storeDataKey.next(value)
        }
    }

    remove(key: string) {
        delete(this.storage[key])
        localStorage.setItem(STORAGE_NAME, JSON.stringify(this.storage));

        const storeDataKey = this.storeDataSubjects.get(key);
        if (!this.storeDataSubjects.has(key) || !storeDataKey) {
            this.storeDataSubjects.set(key, new BehaviorSubject<any>(null));
        } else {
            storeDataKey.next(null);
        }
    }

    getKeyList(search: string): string[] {
        const arr = Array.from(this.storeDataSubjects.keys());
        return arr.filter((x) => x.includes(search));
    }

    getStorageKey(storage: any): string{
        if(storage.beneficiaria) {
            return StoragePageEnum.FormBeneficiaria;
        } else if (storage.contrato) {
            return StoragePageEnum.FormContrato;
        } else if (storage.condutor) {
            return StoragePageEnum.FormCondutor;
        }

        return '';
    }
}
