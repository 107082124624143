import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { Breadcrumb, GenerateTableColumn } from '../shared/components';
import { Contrato, ContratoService } from '../contrato';
import { catchErr, convertToDatePicker, formatHumanTime } from '../shared/utils';
import { AjustarFaturamentoComponent, AjusteFaturamento, Faturamento, FaturamentoEquipamento, FaturamentoTotal, FinanceiroService, SimulacaoFaturamento } from './';
import { Dio, DioService, StatusExecucaoDIO } from '../dio';
import { MatDialog } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { FaturamentoService } from './faturamento/faturamento.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-financeiro',
    templateUrl: './financeiro.component.html',
    providers: [CurrencyPipe]
})
export class FinanceiroComponent implements OnInit {

    loading = { main: false, contratos: true, DIOs: false, simular: false, salvar: false };
    showEdit: boolean;
    motivo: string;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Financeiro' },
    ];

    contratos: Contrato[];
    filters: any = {
        contratoId: null,
        startAt: convertToDatePicker(dayjs().subtract(5, 'month').startOf('month').format('YYYY-MM-DD')),
        endAt: convertToDatePicker(dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')),
    };

    totalizadores: FaturamentoTotal;
    dataSource: FaturamentoEquipamento[];
    columns: GenerateTableColumn[] = [
        { header: 'Equipamento', attr: 'alocacaoCodigo' },
        { header: 'Placa/Série', attr: 'equipamentoPlaca' },
        { header: 'Hora Disp', attr: 'totalHorasDisponiveisEmMinutos' },
        { header: 'Hora Trab', attr: 'totalHorasFuncionamentoEmMinutos' },
        { header: 'Km', attr: 'totalKmRodados' },
        {
            header: 'Custo',
            template: (rowData: FaturamentoEquipamento) => `
                <div>${rowData.custo}</div>
                ${this.showEdit ? `
                    <div class="text-primary">${rowData.valorReajustado ? this.currencyPipe.transform(rowData.valorReajustado, 'BRL') : '---'}</div>
                    <div class="text-success">${rowData.diferenca ? this.currencyPipe.transform(rowData.diferenca, 'BRL') : '---'}</div>
                `: ``}`
        },
        { header: 'Qtde DIOs', attr: 'dios' },
    ]
    columnsEdit: GenerateTableColumn[] = [
        ...this.columns,
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'AJUSTAR' },
            click: {
                action: (rowData: FaturamentoEquipamento) => this.showAjusteDialog(rowData)
            }
        }
    ]

    dataSourceDIOs: any[];
    columnsDIO: GenerateTableColumn[] = [
        { header: 'Nº DIO', attr: 'numero' },
        { header: 'Serviço', attr: 'servico' },
        { header: 'Fim Operação', attr: 'dataPeriodoFimOperacao' },
        { header: 'Orgão', attr: 'orgao' },
        { header: 'Local de Apresentação', attr: 'localApresentacao' },
        {
            header: 'Custo',
            template: (rowData: any) => `${rowData.precificacoes && rowData.precificacoes.label ? rowData.precificacoes.label : null}`
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Visualizar', color: 'accent' },
            click: {
                action: (rowData: Dio) => this.router.navigateByUrl(`/dio/${rowData.id}/visualizar`)
            }
        },
    ]

    formatHumanTime = formatHumanTime;

    constructor(
        private contratoService: ContratoService,
        private currencyPipe: CurrencyPipe,
        private dialog: MatDialog,
        private dioService: DioService,
        private faturamentoService: FaturamentoService,
        private financeiroService: FinanceiroService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.contratoService.getAll<Contrato>().subscribe({
            next: (res) => this.contratos = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.contratos = false);
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    searchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.nome.toLocaleLowerCase().indexOf(term) > -1;
    }

    search() {
        this.showEdit = false;
        this.loading.main = true;

        const filters = { ...this.filters };
        filters.startAt = filters.startAt ? `${dayjs(filters.startAt).format('YYYY-MM-DD')}T00:00:00` : filters.startAt;
        filters.endAt = filters.endAt ? `${dayjs(filters.endAt).format('YYYY-MM-DD')}T23:59:59` : filters.endAt;

        this.contratoService.getFaturamento(this.filters.contratoId, filters.startAt, filters.endAt).subscribe({
            next: (res: Faturamento) => {
                this.dataSource = res.equipamentos.map((item) => this.buildResponse(item));
                this.totalizadores = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    showAjusteDialog(equipamento: FaturamentoEquipamento) {
        const dialogRef = this.dialog.open(AjustarFaturamentoComponent, {
            data: {
                filters: this.filters,
                totalizadores: this.totalizadores,
                equipamento: equipamento
            }, maxHeight: '90vh'
        });

        dialogRef.afterClosed().subscribe((data: { equipamento: FaturamentoEquipamento, dios: any }) => {
            if (data) {
                this.dataSourceDIOs = data.dios
                this.dataSource.map((equip) => {
                    if (equip.equipamentoId == data.equipamento.equipamentoId) {
                        equip.diferenca = data.equipamento?.diferenca;
                        equip.valorReajustado = data.equipamento?.valorReajustado;
                    }
                })
            }
        });
    }

    getDioByEquip($event: FaturamentoEquipamento, filters: any, callback?: Function) {
        this.loading.DIOs = true;

        filters.startAt = filters.startAt ? `${dayjs(filters.startAt).format('YYYY-MM-DD')}T00:00:00` : filters.startAt;
        filters.endAt = filters.endAt ? `${dayjs(filters.endAt).format('YYYY-MM-DD')}T23:59:59` : filters.endAt;

        this.dioService.getPaginated<Dio>(0, 50, {
            contrato: filters.contratoId,
            equipamento: $event.equipamentoId,
            startDataPeriodoFimOperacao: filters.startAt,
            endDataPeriodoFimOperacao: filters.endAt,
            statusExecucao: StatusExecucaoDIO.EXECUTADA
        }).subscribe({
            next: (res): any => {
                this.dataSourceDIOs = res.data.map((item) => this.buildResponseDIO(item));
                callback && callback(this.dataSourceDIOs);
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.DIOs = false)
    }

    criarFaturamento() {
        this.loading.salvar = true;

        const ajustes: AjusteFaturamento[] = this.dataSourceDIOs ? this.dataSourceDIOs.map((data) => ({
            dio: data.dio,
            valor: data.valorReajustado
        })) : [];

        const novoFaturamento = {
            'dataBaseInicio': `${dayjs(this.filters.startAt).format('YYYY-MM-DD')}`,
            'dataBaseFim': `${dayjs(this.filters.endAt).format('YYYY-MM-DD')}`,
            'valorAgregadoPreCorte': this.totalizadores.custo ? this.totalizadores.custo : 0,
            'valorTotalCortes': this.totalizadores.diferenca ? this.totalizadores.diferenca : 0,
            'contratoId': this.filters.contratoId
        }


        forkJoin([
            this.faturamentoService.criarFaturamento(novoFaturamento),
            this.financeiroService.reajustePrecificacao(this.motivo, ajustes),
        ]).subscribe({
            next: ([faturamento, reajustesFinanceiros]) => {
                console.log('response faturamento: ', faturamento);
                console.log('response reajustes: ', reajustesFinanceiros);
                this.snackBar.open('Faturamento criado com sucesso!', '✕', { panelClass: 'success' });
                this.router.navigateByUrl('/faturamentos');
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.salvar = false);


        /*
        this.faturamentoService.criarFaturamento(novoFaturamento).subscribe({
            next: () => {
                this.snackBar.open('Faturamento criado com sucesso!', '✕', { panelClass: 'success' });
                this.router.navigateByUrl('/faturamentos');
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.salvar = false)
        */
    }

    private buildResponse(item: FaturamentoEquipamento) {
        return {
            ...item,
            custo: this.currencyPipe.transform(item.custo, 'BRL'),
            totalHorasDisponiveisEmMinutos: formatHumanTime(item.totalHorasDisponiveisEmMinutos),
            totalHorasFuncionamentoEmMinutos: formatHumanTime(item.totalHorasFuncionamentoEmMinutos),
            totalKmRodados: `${item.totalKmRodados} km`,
        }
    }

    private buildResponseDIO(item: Dio) {
        const precificacao = item.precificacoes!.find((p) => p.ativa);
        const localApresentacao = item.orgao && item.orgao.locaisApresentacao ? item.orgao.locaisApresentacao[0] : null;

        return {
            id: item.id,
            numero: item.numero,
            servico: item.servico.titulo,
            dataPeriodoFimOperacao: dayjs(item.dataPeriodoFimOperacao).format('DD/MM/YYYY'),
            orgao: item.orgao ? item.orgao.nome : null,
            localApresentacao: localApresentacao ? localApresentacao.nome : null,
            precificacoes: precificacao ? {
                id: precificacao.id,
                value: precificacao.custoTotal,
                label: this.currencyPipe.transform(precificacao.custoTotal, 'BRL')
            } : null
        }
    }

}
