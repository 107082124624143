<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h1 class="fs-3 fw-light mb-0">Relatórios</h1>
                <mat-form-field appearance="outline" class="input-sm ms-auto me-3">
                    <mat-label>Filtrar relatórios</mat-label>
                    <input matInput #term (input)="filter(term.value)">
                    <mat-icon matSuffix class="py-0 px-2">search</mat-icon>
                </mat-form-field>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 mb-3" *ngFor="let relatorio of filteredRelatorios">
                    <div *ngIf="deveExibirRelatorio(relatorio.skipRoles)">
                        <mat-card class="shadow-none border action h-100" [routerLink]="['/relatorio', relatorio.routerLink]">
                            <mat-card-content>
                                <strong>{{ relatorio.titulo }}</strong>
                                <hr class="border-gray-400 my-2">
                                <div class="text-gray">{{ relatorio.descricao }}</div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="col-12" *ngIf="!filteredRelatorios || !filteredRelatorios.length">
                    <div class="rounded border text-center text-gray py-4">
                        Nenhum relatório encontrado!
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
