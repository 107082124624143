<ng-template [ngIf]="isContratadoAdm" [ngIfElse]="tableCondutores">
    <div class="p-4">
        <breadcrumb [items]="breadcrumb"></breadcrumb>
        <mat-card>
            <mat-card-content>
                <ng-template [ngTemplateOutlet]="tableCondutores"></ng-template>
            </mat-card-content>
        </mat-card>
    </div>
</ng-template>

<ng-template #tableCondutores>
    <div class="d-flex align-items-end mb-3">
        <h1 class="fs-3 fw-light mb-0">Condutores</h1>
        <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/beneficiaria/', beneficiariaId, 'condutor', 'novo']">
            NOVO CONDUTOR
        </button>
    </div>
    <generate-table [dataSource]="dataSource" [columns]="columns" [loading]="loading" [total]="pagination.total"
        [pageSize]="pagination.pageSize" (pageEvent)="search($event)"></generate-table>
</ng-template>