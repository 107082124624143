import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { catchErr, convertToDatePicker, formatHumanTime } from '../../shared/utils';
import { AjustarFaturamentoComponent, AjusteFaturamento, Faturamento, FaturamentoEquipamento, FaturamentoTotal, SimulacaoFaturamento } from '.././';
import { Dio } from '../../dio';
import { MatDialog } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { FaturamentoService } from './faturamento.service';

@Component({
    selector: 'app-faturamento',
    templateUrl: './faturamento.component.html',
    providers: [CurrencyPipe]
})
export class FaturamentoComponent implements OnInit {

    loading = { main: false, faturamentos: true, DIOs: false, simular: false, salvar: false };
    showEdit: boolean;
    motivo: string;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Faturamentos' },
    ];

    faturamentos: Faturamento[];
    filters: any = {
        contratoId: null,
        startAt: convertToDatePicker(dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')),
        endAt: convertToDatePicker(dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')),
    };

    totalizadores: FaturamentoTotal;
    dataSource: Faturamento[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Contrato', attr: 'nomeContrato' },
        { header: 'Data Base Inicio', attr: 'dataBaseInicio' },
        { header: 'Data Base Fim', attr: 'dataBaseFim' },
        { header: 'Valor Agregado', attr: 'valorAgregadoPreCorte' },
        { header: 'Valor Cortes', attr: 'valorTotalCortes' },
        { header: 'Valor Final Pós Cortes', attr: 'valorFinalPosCortes' },        
        { header: 'Status', attr: 'status' },
        { 
            tdClass: 'td-w-auto',
            btnProps: { label: 'Visualizar', color: 'accent' },
            click: {
              action: (rowData: Faturamento) => this.router.navigateByUrl(`/faturamentos/${rowData.id}/visualizar`)
            }
        },
    ]

    formatHumanTime = formatHumanTime;

    constructor(
        private currencyPipe: CurrencyPipe,
        private dialog: MatDialog,
        private faturamentoService: FaturamentoService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.faturamentoService.getAll<Faturamento>().subscribe({
            next: (res) => {
                console.log('response faturamentos: ', res);
                this.faturamentos = res.data;

                this.dataSource = res.data.map((faturamento) => (
                    {
                        ...faturamento, 
                        nomeContrato: faturamento.contrato.nome,
                        dataBaseInicio: `${dayjs(faturamento.dataBaseInicio).format('DD/MM/YYYY')}`,
                        dataBaseFim: `${dayjs(faturamento.dataBaseFim).format('DD/MM/YYYY')}`
                    }
                ))
                this.pagination.total = res.total;
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.faturamentos = false);
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    searchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.nome.toLocaleLowerCase().indexOf(term) > -1;
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.showEdit = false;
        this.loading.main = true;

        const filters = { ...this.filters };
        filters.startAt = filters.startAt ? `${dayjs(filters.startAt).format('YYYY-MM-DD')}T00:00:00` : filters.startAt;
        filters.endAt = filters.endAt ? `${dayjs(filters.endAt).format('YYYY-MM-DD')}T23:59:59` : filters.endAt;

        console.log('filters: ', filters);

        /*
        this.contratoService.getFaturamento(this.filters.contratoId, filters.startAt, filters.endAt).subscribe({
            next: (res: Faturamento) => {
                this.dataSource = res.equipamentos.map((item) => this.buildResponse(item));
                this.totalizadores = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
        */
    }

}

