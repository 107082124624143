import { NgModule } from "@angular/core";
import { MetricaService } from "./metrica.service";

@NgModule({
    providers: [
        MetricaService
    ]
})
export class MetricaModule {

}