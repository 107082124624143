import { Component } from '@angular/core';
import { Pagination } from 'src/app/shared/models';
import { Contrato, ContratoService } from 'src/app/contrato';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { AuthService } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';
import { FaturamentoService } from '../faturamento/faturamento.service';
import { Faturamento } from '../faturamento.model';

@Component({
    selector: 'app-view-faturamento',
    templateUrl: './view-faturamento.component.html'
})
export class ViewFaturamentoComponent {

    loading: boolean;
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }

    faturamentoId: string;
    contratos: Pagination<Contrato>;
    faturamento: Faturamento;
    justificativaReabertura: string;
    dataSource: any[];

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Faturamento', route: '/faturamentos' },
        { label: 'Visualizar Faturamento' },
    ]

    columns: GenerateTableColumn[] = [
        { header: 'Nº DIO', attr: 'nomeContrato' },
        { header: 'Serviço', attr: 'dataBaseInicio' },
        { header: 'Fim Operação', attr: 'dataBaseFim' },
        { header: 'Orgão', attr: 'valorAgregadoPreCorte' },
        { header: 'Local de Apresentação', attr: 'valorTotalCortes' },
        { header: 'Custo', attr: 'valorFinalPosCortes' },        
    ]

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private faturamentoService: FaturamentoService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.faturamentoId = this.activatedRoute.snapshot.params['idFaturamento'];
        this.loading = true;
        this.faturamentoService.getById<Faturamento>(this.faturamentoId).subscribe({
            next: (res) => this.faturamento = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = false;
        console.log('clicou search');
        
    }

    reabrir() {
        this.loading = true;
        this.faturamentoService.reabrirFaturamento(this.faturamentoId, this.justificativaReabertura).subscribe({
            next: (res: any) => { 
                this.faturamento.status = res.faturamento.status;
                console.log('response reabrir', res)
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
        
    }

}
