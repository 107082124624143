import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Data, Pagination } from 'src/app/shared/models';
import { GenericCRUDService } from 'src/app/shared/services';
import { Alocacao } from './alocacao.model';

@Injectable({
    providedIn: 'root'
})
export class AlocacaoService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/alocacao`;
    }

    override getAll<Alocacao>(contratoId?: string, equipamentoId?: string, ativas?: boolean): Observable<Data<Alocacao>> {
        const params = new URLSearchParams();
        params.append('removePagination', 'true');
        contratoId && params.append('contratoId', `${contratoId}`);
        equipamentoId && params.append('equipamentoId', `${equipamentoId}`);
        ativas && params.append('ativa', `${ativas}`);
        return this.http.get<Data<Alocacao>>(`${this.url}?${params}`);
    }

    override getPaginated<Alocacao>(page: number, pageSize: number, contratoId?: string): Observable<Pagination<Alocacao>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        contratoId && params.append('contratoId', `${contratoId}`);

        return this.http.get<Pagination<Alocacao>>(`${this.url}?${params}`);
    }

    realocacao(alocacao: Alocacao): Observable<Alocacao> {
        return this.http.post<Alocacao>(`${this.url}/realocacao/${alocacao.id}`, alocacao);
    }

    getAllByBeneficiaria(beneficiariaId: string) {
      const params = new URLSearchParams();
      params.append('removePagination', `true`);
      params.append('beneficiariaId', beneficiariaId);
      return this.http.get<Data<Alocacao>>(`${this.url}?${params}`);
    }

    getRecommendedCode(sigla: string): Observable<any> {
      return this.http.get<any>(`${this.url}/recommended-code/${sigla}`);
    }

    getExistsCode(code: string): Observable<any> {
      return this.http.get<any>(`${this.url}/exists-code/${code}`);
    }

    removeOne(id: string): Observable<any> {
      return this.http.delete<any>(`${this.url}/one/${id}`);
    }

    forceRemove(id: string): Observable<any> {
      return this.http.delete<any>(`${this.url}/one/${id}?force=true`);
    }
}
