import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { EquipamentoCategoria, EquipamentoTipo } from '../../equipamento.model';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipamentoTipoService } from '../equipamento-tipo.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { EquipamentoCategoriaService } from '../..';

@Component({
    selector: 'app-form-equipamento-tipo',
    templateUrl: './form-equipamento-tipo.component.html'
})
export class FormEquipamentoTipoComponent implements OnInit {

    loading: any = { main: true, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    tipo = {} as EquipamentoTipo;
    categorias: EquipamentoCategoria[];
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private categoriaService: EquipamentoCategoriaService,
        private tipoService: EquipamentoTipoService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.tipo.id = this.activatedRoute.snapshot.params['id'];
        this.title = this.tipo.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Equipamentos', route: '/equipamento', queryParams: { tab: 'tipos' } },
            { label: this.title + ' tipo' },
        ]
        this.categoriaService.getAll<EquipamentoCategoria>().subscribe({
            next: (res) => {
                this.categorias = res.data;
                if (this.tipo.id) 
                    this.getById();
                else 
                    this.loading.main = false;
            }, error: (err) => {
                this.loading.main = false;
                catchErr(err, this.snackBar)
            }
        });
    }

    compareWith(item: any, selected: any) {
        return selected && item == selected.id
    }

    save() {
        this.loading.save = true;
        let request: Observable<EquipamentoTipo>
        let body = this.tipo;

        if (this.tipo.categoria && typeof this.tipo.categoria === "object") {
          body.categoria = this.tipo.categoria.id
        } else if(this.tipo.categoria) {
          body.categoria = this.tipo.categoria
        }

        request = this.tipo.id
            ? this.tipoService.update<EquipamentoTipo>(this.tipo.id, body)
            : this.tipoService.create<EquipamentoTipo>(body)

        request.subscribe({
            next: () => {
                this.snackBar.open('Tipo salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/equipamento'], { queryParams: { tab: 'tipos' } });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.tipo.nome, (dialogRef: MatDialogRef<any>) => {
            this.tipoService.remove<EquipamentoTipo>(this.tipo.id).subscribe({
                next: () => {
                    this.snackBar.open('Tipo apagado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/equipamento'], { queryParams: { tab: 'tipos' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    filesOut($event: any) {
        this.tipo.imagens = this.tipo.imagens ? [...this.tipo.imagens, ...$event] : $event
    }

    fileRemove($event: { file: any, index: number }) {
        if ($event.file.id) {
            this.removeDialogService.show($event.file.name, (dialogRef: MatDialogRef<any>) => {
                this.tipoService.removeImage($event.file.id).subscribe({
                    next: () => {
                        this.snackBar.open('Imagem apagada com sucesso!', '✕', { panelClass: ['success'] });
                        this.getById();
                        dialogRef.close();
                    }, error: (err) => catchErr(err, this.snackBar)
                }).add(() => this.loading.save = false);
            });
        } else {
            this.tipo.imagens.splice($event.index, 1)
        }
    }

    private getById() {
        this.loading.main = true;
        this.tipoService.getById<EquipamentoTipo>(this.tipo.id).subscribe({
            next: (res) => this.tipo = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }
}
