<div class="d-none d-print-block p-3" *ngIf="hasDio()">
    <table class="border-top">
        <tbody>
            <tr class="text-center">
                <td rowspan="2">
                    <img src="assets/img/logo.png" alt="Logo" height="35">
                </td>
                <td rowspan="2" colspan="2">Diário de Operações</td>
                <td>Emissão</td>
                <td>Impresso</td>
                <td>DIO nº</td>
            </tr>
            <tr class="text-center">
                <td class="fw-bold">{{ dio.created_at | date:'short' }}</td>
                <td class="fw-bold">{{ now | date:'short' }}</td>
                <td class="fw-bold">{{ dio.numero }}</td>
            </tr>
            <tr>
                <td>Orgão Requisitante</td>
                <td colspan="5" class="fw-bold">{{ dio.orgao.nome | uppercase }}</td>
            </tr>
            <tr>
                <td>Proprietário</td>
                <td colspan="5" class="fw-bold">{{ dio.contrato.beneficiaria.nome | uppercase }}</td>
            </tr>
            <tr>
                <td>Contrato</td>
                <td colspan="5" class="fw-bold">{{ dio.contrato.nome | uppercase }}</td>
            </tr>
            <tr>
                <td>Descrição do Serviço</td>
                <td colspan="5" class="fw-bold">{{ dio.servico.descricao | uppercase }}</td>
            </tr>
            <tr>
                <td>Operador</td>
                <td colspan="5" class="fw-bold">
                    {{ dio.condutor.firstName | uppercase }} {{ dio.condutor.lastName | uppercase }}
                </td>
            </tr>
            <tr>
                <td>Local de Apresentação</td>
                <td colspan="5" class="fw-bold">{{ dio.localApresentacao | uppercase }}</td>
            </tr>
            <tr>
                <td>Identificador SEI</td>
                <td colspan="2" class="fw-bold">{{ dio.sei }}</td>
                <td>Processo SEI</td>
                <td colspan="2" class="fw-bold">{{ dio.precessoSei }}</td>
            </tr>
            <tr class="text-center">
                <td colspan="6">Identificação do equipamento</td>
            </tr>
            <tr>
                <td>Grupo</td>
                <td>Prefixo</td>
                <td>Placa/Série</td>
                <td>Marca</td>
                <td>Modelo</td>
                <td>Ano</td>
            </tr>
            <tr class="text-center">
                <td class="fw-bold">
                    <span *ngIf="dio.alocacao">{{ dio.alocacao.equipamento.grupo.nome | uppercase }}</span>
                </td>
                <td class="fw-bold">
                    <span *ngIf="dio.alocacao">{{ dio.alocacao.codigo | uppercase}}</span>
                </td>
                <td class="fw-bold">
                    <span *ngIf="dio.alocacao">{{ dio.alocacao.equipamento.placa }}</span>
                </td>
                <td class="fw-bold">
                    <span *ngIf="dio.alocacao">{{ dio.alocacao.equipamento.marca | uppercase }}</span>
                </td>
                <td class="fw-bold">
                    <span *ngIf="dio.alocacao">{{ dio.alocacao.equipamento.modelo | uppercase }}</span>
                </td>
                <td class="fw-bold">
                    <span *ngIf="dio.alocacao">{{ dio.alocacao.equipamento.fabricacaoAno }}</span>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- OPERAÇÕES EM SERVIÇO -->
    <table>
        <tbody>
            <tr class="text-center">
                <td colspan="8">Operações em Serviço</td>
            </tr>
            <tr class="text-center">
                <td colspan="2">Hora</td>
                <td colspan="4">Natureza</td>
                <td rowspan="2">Usuário</td>
                <td rowspan="2">Descrição detalhada</td>
            </tr>
            <tr class="text-center">
                <td>Inicio</td>
                <td>Fim</td>
                <td>HT</td>
                <td>HP</td>
                <td>HM</td>
                <td>HQ</td>
            </tr>
            <tr class="text-center" *ngIf="!dio.execucaoDetalhes || !dio.execucaoDetalhes.length">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr class="text-center" *ngFor="let execucao of dio.execucaoDetalhes">
                <td>{{ execucao.created_at | date:'short' }}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>{{ execucao.descricao }}</td>
            </tr>
        </tbody>
    </table>
    <table>
        <tbody>
            <tr>
                <td>Executor Local</td>
                <td>&nbsp;</td>
                <td>Data/Hora</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>Fiscal</td>
                <td>&nbsp;</td>
                <td>Data/Hora</td>
                <td>&nbsp;</td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-between pt-1">
        <div>
            Emitido por <span class="fw-bold">{{ dio.criadorNome | uppercase }}</span>
        </div>
        <div>
            Impresso por <span class="fw-bold">{{ authService.user.name | uppercase }}</span>
        </div>
    </div>
</div>