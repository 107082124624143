export interface Orgao {
    id: string,
    descricao: string;
    endereco: any;
    lat: number;
    lng: number;
    imagem: any;
    nome: string;
    regiaoAdministrativa: any;
    funcionarios: any[];
    locaisApresentacao: any[];
    sigla: string;
}

export interface Funcionario {
    id: string;
    avatarUrl?: string;
    email: string;
    firstName: string;
    lastName: string;
    nome: string;
    orgao: any;
    telefone: string;
    userVirtualId: string;
    usuario_id: string;

    // app
    user?: any;
}