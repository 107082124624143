import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { catchErr } from 'src/app/shared/utils';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Anotacao } from 'src/app/condutor';
import { AnotacaoService } from '../anotacao.service';

@Component({
    selector: 'app-form-anotacao',
    templateUrl: './form-anotacao.component.html'
})
export class FormAnotacaoComponent {

    loading: boolean;
    anotacao = {} as Anotacao;

    constructor(
        private dialogRef: MatDialogRef<FormAnotacaoComponent>,
        private anotacaoService: AnotacaoService,
        private snackBar: MatSnackBar,

        @Inject(MAT_DIALOG_DATA) public data: { condutorId: string },
    ) { }

    save() {
        this.loading = true;
        let request: Observable<any>

        const anotacaoToSave = {
            id: this.anotacao.id,
            anotacao: this.anotacao.anotacao,
            condutor: this.data.condutorId,
        } as Anotacao;

        request = anotacaoToSave.id
            ? this.anotacaoService.update<Anotacao>(anotacaoToSave.id, anotacaoToSave)
            : this.anotacaoService.create<Anotacao>(anotacaoToSave)

        request.subscribe({
            next: () => {
                this.snackBar.open('Anotação salva com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
