<h1 mat-dialog-title>Novo Funcionário</h1>
<div mat-dialog-content class="overflow-visible px-3 pb-0">
    <ng-select [items]="usuario$ | async" required
        placeholder="Usuário" typeToSearchText="Buscar usuário..." notFoundText="Nenhum item encontrado" loadingText="Buscando..."
        [loading]="loading.usuario" [typeahead]="usuarioInput$" [(ngModel)]="user" [compareWith]="compareWith">
        
        <!-- selected -->
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="ng-value-label">{{ item.firstName }} {{ item.lastName }}</span>
        </ng-template>

        <!-- lista -->
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <img class="rounded-circle me-2 img-cover" width="25" height="25" [src]="item.avatarUrl || 'assets/img/default_user.jpeg'" />
            <span>{{ item.firstName }} {{ item.lastName }}</span>
        </ng-template>
    </ng-select>
</div>
<div mat-dialog-actions class="px-3 pb-3 justify-content-end">
    <button mat-button mat-dialog-close class="px-3">Fechar</button>
    <button mat-flat-button color="primary" (click)="save()" [ngClass]="{'btn-dialog-spinner': loading.save}"[disabled]="!user || loading.save">
        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
        <span *ngIf="!loading.save">Salvar</span>
    </button>
</div>