<div class="d-flex justify-content-center align-items-center px-3 py-5 bg-waves">
    <mat-card class="col-12 col-md-4">
        <img height="70" src="/assets/img/user.png" alt="user" class="position-absolute top-0 start-50 translate-middle">
        <mat-card-content>
            <div class="text-center text-muted">
                <h1 class="text-indigo fs-4 mb-1 mt-5">CRIE SUA CONTA</h1>
                <h2 class="fs-6 fw-light mb-4">Informe os dados a seguir</h2>
            </div>
            <form class="d-flex flex-column" name="form" (ngSubmit)="form.valid && register(form)" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Nome</mat-label>
                    <input matInput name="firstName" #firstName="ngModel" ngModel required>
                    <mat-error *ngIf="showInputError(firstName, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- SOBRENOME -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Sobrenome</mat-label>
                    <input matInput name="lastName" #lastName="ngModel" ngModel required>
                    <mat-error *ngIf="showInputError(lastName, 'required')">Sobreome é obrigatório</mat-error>
                </mat-form-field>

                <!-- PHONE -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Telefone</mat-label>
                    <input matInput name="telefone" #telefone="ngModel" ngModel required mask="(00) 00000-0000">
                    <mat-error *ngIf="showInputError(telefone, 'required')">Telefone é obrigatório</mat-error>
                </mat-form-field>

                <!-- EMAIL -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" name="email" #email="ngModel" ngModel required>
                    <mat-error *ngIf="showInputError(email, 'required')">Email é obrigatório</mat-error>
                </mat-form-field>

                <!-- SENHA -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" name="password" #password="ngModel" ngModel required minlength="6">
                    <mat-error *ngIf="showInputError(password, 'required')">Senha é obrigatório</mat-error>
                    <mat-error *ngIf="showInputError(password, 'minlength')">Mínimo de 6 caracteres</mat-error>
                </mat-form-field>

                <!-- CONFIRMAÇÃO -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Confirmação de senha</mat-label>
                    <input matInput type="password" name="confirmPassword" #confirmPassword="ngModel" ngModel required minlength="6">
                    <mat-error *ngIf="showInputError(password, 'required')">Confirmação é obrigatória</mat-error>
                    <mat-hint class="text-danger" *ngIf="checkPassword(password.value, confirmPassword.value)">As senhas devem ser iguais</mat-hint>
                </mat-form-field>

                <button mat-flat-button color="primary" class="btn-lg" [disabled]="form.form.invalid || loading">
                    <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
                    <span *ngIf="!loading">REGISTRAR</span>
                </button>
                
                <div class="text-center mt-3">
                    <button type="button" mat-button color="primary" [routerLink]="['/sign-in']">Voltar para o login</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>