import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { GenerateTableColumn, GenerateTablePageEvent } from './generate-table.model';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface MaterialColumns {
    columnDef: string,
    header: string,
    tdClass?: string,
    thClass?: string,
    cell?: any,
    click?: {
      disabled?: Function,
      action?: Function
    },
    template?: Function
    btnProps?: {
        label: string,
        color?: 'primary' | 'accent' | 'warn',
    },
}

@Component({
    selector: 'generate-table',
    templateUrl: './generate-table.component.html',
    styleUrls: ['./generate-table.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class GenerateTableComponent implements OnInit {

    @Input() dataSource: any[];
    @Input() columns: GenerateTableColumn[];
    @Input() loading: boolean;

    @Input() trSize: 'md' | 'sm' = 'md';
    @Input() expanded: boolean;
    @Input() expandedContent: TemplateRef<any>;

    @Input() total: number;
    @Input() pageSize: number;

    @Output() openExpanded = new EventEmitter<any>();
    @Output() pageEvent = new EventEmitter<GenerateTablePageEvent>();

    materialColumns: MaterialColumns[];
    displayedColumns: string[];
    displayedColumnsWithExpand: string[];
    expandedElement: any;

    ngOnInit() {
        this.materialColumns = this.columns.map((column: GenerateTableColumn) => ({
            columnDef: `${Math.random()}`,
            header: column.header || '',
            tdClass: column.tdClass,
            thClass: column.thClass,
            btnProps: column.btnProps ? {...column.btnProps, color: column.btnProps?.color || 'primary'} : undefined,
            click: column.click || undefined,
            template: column.template || undefined,
            cell: column.attr ? (rowData: any) => (rowData[column.attr!] ? `${rowData[column.attr!]}` : '---') : undefined,
        }))

        this.displayedColumns = this.materialColumns.map(c => c.columnDef)
        this.displayedColumnsWithExpand = [...this.displayedColumns, 'expand'];
    }

    handlePageEvent(page: PageEvent) {
        this.pageEvent.emit({ current: page.pageIndex, pageSize: page.pageSize })
    }

    openExpandedContent($event: MouseEvent, rowData: any) {
        this.expandedElement = (this.expandedElement === rowData) ? null : rowData;
        this.expandedElement == rowData && this.openExpanded.emit(rowData);
        $event.stopPropagation();
    }

}
