import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core';
import { Breadcrumb } from 'src/app/shared/components';
import { Contrato } from '../..';

@Component({
    selector: 'detail-contrato',
    templateUrl: './detail-contrato.component.html'
})
export class DetailContratoComponent {

    @Input() contrato: Contrato;

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Contratos', route: '/contrato' },
        { label: 'Visualizar contrato' },
    ]

    constructor(public authService: AuthService) { }

}
