export interface Polo {
    id: string,
    nome: string,
    zoom: number,
    lat: string;
    lng: string;
}

export interface RegiaoAdmin {
    id: string,
    nome: string,    
    polo: any,
    descricao: string;
}