import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCRUDService } from '../shared/services';

@Injectable({
    providedIn: 'root'
})
export class DemandanteService extends GenericCRUDService {
    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/demandante`;
    }
}
