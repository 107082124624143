<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} equipamento</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>

                <!-- MARCA -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Marca</mat-label>
                    <input matInput name="marca" #marca="ngModel" [(ngModel)]="equipamento.marca">
                </mat-form-field>

                <!-- MODELO -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Modelo</mat-label>
                    <input matInput name="modelo" #modelo="ngModel" [(ngModel)]="equipamento.modelo" required>
                    <mat-error *ngIf="showInputError(modelo, 'required')">Modelo é obrigatório</mat-error>
                </mat-form-field>

                <!-- PLACA -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Placa/Série (Deve ser igual o Registro do ETRACK)</mat-label>
                    <input matInput name="placa" #placa="ngModel" [(ngModel)]="equipamento.placa" required>
                    <mat-error *ngIf="showInputError(placa, 'required')">Placa/Série é obrigatório (Deve ser igual o Registro do ETRACK)</mat-error>
                </mat-form-field>

                <!-- ANO DE FABRICACAO -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Ano de Fabricação</mat-label>
                    <input matInput name="fabricacaoAno" #fabricacaoAno="ngModel" [(ngModel)]="equipamento.fabricacaoAno" mask="0000">
                </mat-form-field>

                <!-- GRUPO -->
                <mat-form-field class="col-12">
                    <mat-label>Grupo</mat-label>
                    <mat-select name="grupo" #grupo="ngModel" [(ngModel)]="equipamento.grupo" [compareWith]="compareWith" required>
                        <mat-option *ngFor="let grupo of grupos" [value]="grupo.id">
                            {{ grupo.tipo.sigla }} - {{ grupo.nome }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="showInputError(grupo, 'required')">Grupo é obrigatório</mat-error>
                </mat-form-field>

                <!-- DESCRIÇÃO -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="equipamento.descricao"></textarea>
                </mat-form-field>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="equipamento.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" [routerLink]="['/equipamento']" 
                        [queryParams]="{tab: 'equipamentos'}">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>