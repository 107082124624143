import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { Aferidores, Dio } from '../../dio.model';
import { AfericaoService } from '../../afericao.service';

@Component({
    selector: 'app-update-aferidor',
    templateUrl: './update-aferidor.component.html',
})
export class UpdateAferidorComponent implements OnInit {
    loading: boolean;
    aferidores: Aferidores[]
    aferidoresFiltered: any;
    aferidorIdSelected: string;
    aferidorAtual: string;

    dio: Dio;
    dioId: string;
    term: string;
    
    showInputError = showInputError;

    constructor(
        private dialogRef: MatDialogRef<UpdateAferidorComponent>,
        private afericaoService: AfericaoService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: Dio,
    ) {
        this.dioId = data.id || '';
        this.dio = data;
        this.aferidorAtual = data.afericao.aferidor?.firstName + ' ' + data.afericao.aferidor?.lastName;
    }

    ngOnInit() {
        this.afericaoService.findAllAferidores().subscribe({
            next: (res) => {
                this.aferidores = res.data.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName, undefined, { sensitivity: 'base' }));
                this.aferidoresFiltered = this.aferidores;
            }, error: (err) => catchErr(err, this.snackBar)
        });
    }

    update() {
        this.loading = true;
        this.afericaoService.update(this.dioId, { aferidor: this.aferidorIdSelected }).subscribe({
            next: () => {
                this.snackBar.open('Aferidor associado com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    search() {
        this.aferidoresFiltered = this.aferidores;
        const term = this.term?.toLocaleLowerCase();
        this.aferidoresFiltered = this.aferidores.filter(c =>
            c.firstName.toLocaleLowerCase().indexOf(term) > -1 ||
            c.lastName.toLocaleLowerCase().indexOf(term) > -1
        );
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }
}
