<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} grupo</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm"
                novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Nome</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="grupo.nome" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- TIPO -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Tipo</mat-label>
                    <mat-select name="tipo" #tipo="ngModel" [(ngModel)]="grupo.tipo" [compareWith]="compareWith"
                        required>
                        <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">{{ tipo.sigla }}/{{ tipo.nome
                            }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="showInputError(tipo, 'required')">Tipo é obrigatório</mat-error>
                </mat-form-field>

                <!-- TARIFAÇÃO -->
                <mat-form-field class="col-12">
                    <mat-label>Tarifação</mat-label>
                    <mat-select name="tarifacao" [(ngModel)]="grupo.tarifacoes" required
                        (selectionChange)="changeTarifacao($event.value)">
                        <mat-option *ngFor="let tarifacao of tarifacoes" [value]="tarifacao">{{
                            getTarifacaoName(tarifacao)
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- MAQUINAS -->
                <ng-container *ngIf="grupo.tarifacoes && grupo.tarifacoes == 'MAQUINAS'">
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/hora produzida</mat-label>
                        <input matInput name="valorHoraProduzida" #valorHoraProduzida="ngModel"
                            [(ngModel)]="grupo.valorHoraProduzida" required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorHoraProduzida, 'required')">Valor/hora produzida é
                            obrigatória</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/hora disponível</mat-label>
                        <input matInput name="valorHoraDisponivelMaquina" #valorHoraDisponivelMaquina="ngModel"
                            [(ngModel)]="grupo.valorHoraDisponivelMaquina" required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorHoraDisponivelMaquina, 'required')">Valor/hora disponível
                            é obrigatória</mat-error>
                    </mat-form-field>
                </ng-container>

                <!-- VEÍCULOS -->
                <ng-container *ngIf="grupo.tarifacoes && grupo.tarifacoes == 'VEICULOS'">
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/km rodado</mat-label>
                        <input matInput name="valorKmRodado" #valorKmRodado="ngModel" [(ngModel)]="grupo.valorKmRodado"
                            required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorKmRodado, 'required')">Valor/km rodado é
                            obrigatório</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Valor/hora disponível</mat-label>
                        <input matInput name="valorHoraDisponivelVeiculo" #valorHoraDisponivelVeiculo="ngModel"
                            [(ngModel)]="grupo.valorHoraDisponivelVeiculo" required currencyMask
                            [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }"
                            autocomplete="off">
                        <mat-error *ngIf="showInputError(valorHoraDisponivelVeiculo, 'required')">Valor/hora disponível
                            é obrigatória</mat-error>
                    </mat-form-field>
                </ng-container>

                <!-- DESCRIÇÃO -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="grupo.descricao"></textarea>
                </mat-form-field>

                <!-- ARQUIVOS -->
                <div class="col-12">
                    <ng-dd-file [filesIn]="grupo.imagens" [multiple]="true"
                        [btnClass]="{ file: 'mat-button mat-primary', remove: 'mat-button mat-warn' }"
                        (filesOut)="filesOut($event)" (fileRemove)="fileRemove($event)"></ng-dd-file>
                </div>

                <div class="col-12">
                    <hr class="border-gray">
                </div>
                <div class="col-12 col-md-auto" *ngIf="grupo.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4"
                        (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" [routerLink]="['/equipamento']"
                        [queryParams]="{tab:'grupos'}">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100"
                        [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>