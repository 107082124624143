<ng-container *ngIf="loading">
    <div class="d-flex align-items-center justify-content-center bg-gray-100" style="min-height: 34.7rem;">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>

<table mat-table [dataSource]="dataSource" class="border-top" multiTemplateDataRows *ngIf="!loading">
    <ng-container *ngFor="let column of materialColumns" [matColumnDef]="column.columnDef">
        <th mat-header-cell *matHeaderCellDef class="lh-sm py-2 {{column.thClass}}">{{ column.header }}</th>
        <ng-container *ngIf="column.cell">
            <td [class]="column.tdClass" mat-cell *matCellDef="let rowData">{{column.cell(rowData)}}</td>
        </ng-container>

        <!-- column action -->
        <ng-container *ngIf="column.btnProps">
            <td [class]="column.tdClass" mat-cell *matCellDef="let rowData">
                <button mat-button [disabled]="column.click && column.click.disabled && column.click.disabled(rowData)" [color]="column.btnProps.color" (click)="column.click && column.click.action && column.click.action(rowData)">{{ column.btnProps.label }}</button>
            </td>
        </ng-container>

        <!-- column template -->
        <ng-container *ngIf="column.template">
            <td [class]="column.tdClass" mat-cell *matCellDef="let rowData">
                <div [innerHTML]="column.template(rowData) | safeHtml" (click)="column.click && column.click.action && column.click.action(rowData)"></div>
            </td>
        </ng-container>

        <!-- no data -->
        <tr *matNoDataRow>
            <td class="py-3 text-center text-muted" mat-cell [attr.colspan]="expanded ? displayedColumnsWithExpand.length :  displayedColumns.length">
                Nenhuma informação encontrada
            </td>
        </tr>
    </ng-container>

    <!-- Expanded Arrow -->
    <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
        <td class="td-w-auto" mat-cell *matCellDef="let rowData">
            <button mat-icon-button>
                <mat-icon *ngIf="expandedElement !== rowData">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === rowData">keyboard_arrow_up</mat-icon>
            </button>
        </td>
    </ng-container>

    <!-- Expanded Content Row -->
    <ng-container matColumnDef="expandedDetail">
        <td class="p-0" mat-cell *matCellDef="let rowData" [attr.colspan]="displayedColumnsWithExpand.length">
            <div class="element-detail" [@detailExpand]="rowData == expandedElement ? 'expanded' : 'collapsed'">
                <ng-container *ngTemplateOutlet="expandedContent; context: { $implicit: rowData }"></ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Headers -->
    <ng-container *ngIf="expanded">
        <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
        <tr mat-row *matRowDef="let rowData; columns: displayedColumnsWithExpand;"
            class="element-row" [class.expanded-row]="expandedElement === rowData"
            (click)="openExpandedContent($event, rowData)">
        </tr>
        <tr mat-row *matRowDef="let rowData; columns: ['expandedDetail']" class="detail-row"></tr>
    </ng-container>

    <ng-container *ngIf="!expanded">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{'table-sm': trSize == 'sm'}" *matRowDef="let rowData; columns: displayedColumns;"></tr>
    </ng-container>
</table>
<mat-paginator *ngIf="total && pageSize"
    (page)="handlePageEvent($event)" 
    [length]="total" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30, 50]" [showFirstLastButtons]="true"
></mat-paginator>