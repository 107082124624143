import { Component } from '@angular/core';
import { AuthService } from './core';
import { ApmService } from '@elastic/apm-rum-angular';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    hasAuth: any;

    constructor(
        private authService: AuthService,
        private apmService: ApmService,
    ) { }

    ngOnInit() {
        this.hasAuth = !!this.authService.token && !!this.authService.user;
        this.authService.hasAuthentication().subscribe((user: any) => this.hasAuth = !!user);

        const transaction = this.apmService.apm.startTransaction('sisdio-web', 'init-app');
        transaction?.end();
    }

}
