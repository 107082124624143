<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-tab-group mat-stretch-tabs="false">
            <mat-tab label="Informações Pessoais">
                <mat-card-content>
                    <form class="mt-2" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                        <div class="row g-0">
                            <div class="col-12 col-md-auto pe-3">
                                <div class="position-relative divAvatar">
                                    <img height="140" width="140" class="img-cover rounded-circle bg-default" [src]="perfil.avatarUrl || '/assets/img/default_user.jpeg'" />
                                    <button type="button" mat-mini-fab color="primary" class="position-absolute bottom-0 end-0" (click)="triggerIputFile()" [disabled]="loading.file">
                                        <mat-spinner class="btn-spinner" *ngIf="loading.file"></mat-spinner>
                                        <mat-icon *ngIf="!loading.file">edit</mat-icon>
                                    </button>
                                    <input id="fileDropRef" class="d-none" name="file" type="file" accept="image/*" (change)="changeAvatar($event)" #fileDropRef />
                                </div>
                            </div>
                            <div class="col-12 col-md row pe-0">
        
                                <!-- NOME -->
                                <mat-form-field class="col-12 col-md-6">
                                    <mat-label>Nome</mat-label>
                                    <input matInput name="firstName" #firstName="ngModel" [(ngModel)]="perfil.firstName" required>
                                    <mat-error *ngIf="showInputError(firstName, 'required')">Nome é obrigatório</mat-error>
                                </mat-form-field>
                
                                <!-- SOBRENOME -->
                                <mat-form-field class="col-12 col-md-6">
                                    <mat-label>Sobrenome</mat-label>
                                    <input matInput name="lastName" #lastName="ngModel" [(ngModel)]="perfil.lastName" required>
                                    <mat-error *ngIf="showInputError(lastName, 'required')">Sobreome é obrigatório</mat-error>
                                </mat-form-field>
                
                                <!-- EMAIL -->
                                <mat-form-field class="col-12 col-md-6 disabled">
                                    <mat-label>Email</mat-label>
                                    <input matInput type="email" name="email" [(ngModel)]="perfil.email" disabled>
                                </mat-form-field>
                            
                                <!-- ROLE -->
                                <div class="col-12 col-md-6" [accessControl]="RolesEnum.GESTAO_ADMIN">
                                    <ng-select class="w-100" name="role" [items]="rolesInfo" bindValue="value" placeholder="Perfil" required
                                        [(ngModel)]="perfil.role" [compareWith]="compareWith">
                                        <!-- selected -->
                                        <ng-template ng-label-tmp let-item="item">{{ item.value | translate }}</ng-template>
                                        <!-- lista -->
                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                            <div class="d-flex flex-column py-2">
                                                <span class="lh-sm">{{ item.value | translate }}</span>
                                                <div class="fs-7 opacity-50">{{ item.description }}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>

                                <!-- PHONE -->
                                <mat-form-field class="col-12 col-md-6">
                                    <mat-label>Telefone</mat-label>
                                    <input matInput name="telefone" #telefone="ngModel" [(ngModel)]="perfil.telefone" mask="(00) 00000-0000">
                                </mat-form-field>

                                <!-- CARGO -->
                                <mat-form-field class="col-12 col-md-6" [accessControl]="RolesEnum.GESTAO_ADMIN">
                                    <mat-label>Cargo</mat-label>
                                    <input matInput name="cargo" #cargo="ngModel" [(ngModel)]="perfil.cargo">
                                </mat-form-field>
                
                                <!-- DESCRIPTION -->
                                <mat-form-field class="col-12" [accessControl]="RolesEnum.GESTAO_ADMIN">
                                    <mat-label>Informações Adicionais</mat-label>
                                    <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="perfil.descricao"></textarea>
                                </mat-form-field>
        
                                <mat-slide-toggle class="col-12 col-md-auto align-self-center ps-3" name="enabled" [(ngModel)]="perfil.enabled" *ngIf="perfil.id">
                                    <span class="ms-1">ATIVO</span>
                                </mat-slide-toggle>
        
                                <div class="col-12 col-md-3 ms-auto">
                                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                                        <span *ngIf="!loading.save">ATUALIZAR</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Segurança">
                <mat-card-content>
                    <h1 class="fs-4 fw-light mb-1">ALTERAR SENHA</h1>
                    <h2 class="fs-6 fw-light mb-3">Informe e confirme a nova senha</h2>
                    <form class="row" name="form" (ngSubmit)="formPass.valid && changePassword(formPass)" #formPass="ngForm" novalidate>
                        <!-- SENHA -->
                        <mat-form-field class="col-12 col-md-6">
                            <mat-label>Nova senha</mat-label>
                            <input matInput type="password" name="password" #password="ngModel" ngModel required minlength="6">
                            <mat-error *ngIf="showInputError(password, 'required')">Senha é obrigatório</mat-error>
                            <mat-error *ngIf="showInputError(password, 'minlength')">Mínimo de 6 caracteres</mat-error>
                        </mat-form-field>
    
                        <!-- CONFIRMAÇÃO -->
                        <mat-form-field class="col-12 col-md-6">
                            <mat-label>Confirmação de senha</mat-label>
                            <input matInput type="password" name="confirmPassword" #confirmPassword="ngModel" ngModel required minlength="6">
                            <mat-error *ngIf="showInputError(password, 'required')">Confirmação é obrigatória</mat-error>
                            <mat-hint class="text-danger" *ngIf="checkPassword(password.value, confirmPassword.value)">As senhas devem ser iguais</mat-hint>
                        </mat-form-field>
        
                        <div class="col-12 col-md-3 ms-auto">
                            <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="formPass.form.invalid || loading.save">
                                <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                                <span *ngIf="!loading.save">ALTERAR</span>
                            </button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>
