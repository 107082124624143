import { Component, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { RelatorioConsumo, RelatorioFilter } from '../relatorio.model';
import { Alocacao, Contrato, ContratoService } from 'src/app/contrato';
import { Beneficiaria, BeneficiariaService } from 'src/app/beneficiaria';
import { GenericRelatorioComponent } from '../generic-relatorio.component';
import { catchErr } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RelatorioService } from '../relatorio.service';
import * as dayjs from 'dayjs';
import { CurrencyPipe } from '@angular/common';
import { dataAtualString, primeiroDiaDoMesString } from 'src/app/shared/date-utils';
import { Chart, registerables } from 'chart.js';

@Component({
    selector: 'app-relatorio-afericoes',
    templateUrl: './relatorio-afericoes.component.html',
    providers: [CurrencyPipe],
})
export class RelatorioAfericoesComponent extends GenericRelatorioComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios', route: '/relatorio' },
        { label: 'Aferições' },
    ]

    beneficiaria: string | undefined;
    contrato: string | undefined;
    dataInicioPesquisada: string | null;
    dataFimPesquisada: string | null;

    totals: { totalPendentesAfericao: 0, totalAferidas: 0 };
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
    columns: GenerateTableColumn[] = [
        { header: 'Contrato', attr: 'contrato' },
        { header: 'Prefixo', attr: 'prefixo' },
        { header: 'Placa/Série', attr: 'placa' },
        { header: 'Grupo', attr: 'grupo' },
        { header: 'N Dio', attr: 'numeroDio' },
        { header: 'Data Operação', attr: 'dataInicioExecucao' },
        { header: 'KM Real', attr: 'kmReal' },
        { header: 'Valor KM', attr: 'valorKm' },
        { header: 'Valor Devido', attr: 'valorDevido' },
    ];
    grafico: any;

    constructor(
        private relatorioService: RelatorioService,
        private beneficiariaService: BeneficiariaService,
        protected override contratoService: ContratoService,
        protected override snackBar: MatSnackBar,
        private readonly currencyPipe: CurrencyPipe,
    ) {
        super(contratoService, snackBar);
    }

    ngOnInit() {
        this.beneficiariaService.getAll<Beneficiaria>().subscribe({
            next: (res) => this.beneficiarias = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);

        this.toggleFilters = true;
        this.filters.dataInicio = primeiroDiaDoMesString();
        this.filters.dataFim = dataAtualString();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        const filters = { ...this.filters };

        this.loading = true;
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.getRelatorioAfericao(filters).subscribe({
            next: (res: any) => {                            
                this.totals = res.totals;

                this.configGrafico(this.totals);

                this.dataInicioPesquisada = this.filters.dataInicio ? dayjs(this.filters.dataInicio).format('DD/MM/YYYY') : null;
                this.dataFimPesquisada = this.filters.dataFim ? dayjs(this.filters.dataFim).format('DD/MM/YYYY') : null;
            }
        }).add(() => {
            this.loading = false;
            this.toggleFilters = false;
        });
    }

    setBeneficiaria(beneficiaria: Beneficiaria) {
        this.buscaContratos(beneficiaria.id);
    }

    configGrafico(totals: any){
        console.log('configurando grafico');
        const canvas = document.getElementById('myBarChart') as HTMLCanvasElement;
        const ctx = canvas?.getContext('2d');
    
        if(ctx){
            // Registra todos os componentes de chart.js
            Chart.register(...registerables);

            this.grafico = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Pendentes de Aferição', 'Aferidas'],
                    datasets: [{
                    label: 'Totais',
                    data: [totals.totalPendentesAfericao, totals.totalAferidas],
                    backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
                    borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                    borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                    y: {
                        beginAtZero: true
                    }
                    }
                }
            });
        } else {
            console.error('Canvas context not found');
        }
    }

    buildResponse(data: RelatorioConsumo) {
        this.contrato = data.contrato;
        this.beneficiaria = data.beneficiaria;

        return {
            contrato: data.contrato,
            prefixo: data.prefixo,
            placa: data.placa,
            grupo: data.grupo,
            numeroDio: data.numeroDio,
            dataInicioExecucao: data.dataInicioExecucao ? dayjs(data.dataInicioExecucao).format('DD/MM/YYYY') : '---',
            kmReal: data.kmReal + 'km',
            valorKm: this.currencyPipe.transform(data.valorKm, 'BRL') || data.valorKm,
            saldo: this.currencyPipe.transform(data.saldo, 'BRL') || data.saldo,
            excesso: this.currencyPipe.transform(data.excesso, 'BRL') || data.excesso,
            valorDevido: this.currencyPipe.transform(data.valorDevido, 'BRL') || data.valorDevido,
        }
    }

    export(type: string = 'csv') {
        this.loading = true;

        const filters = { ...this.filters, removePaingation: true };
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.downloadArquivoCsvOrPdf(filters, 'consumo-por-equipamento', type).subscribe({
            next: (res: any) => {
                const url = window.URL.createObjectURL(res.body as Blob);
                const startReport = filters.dataInicio ? dayjs(filters.dataInicio).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const endReport = filters.dataFim ? dayjs(filters.dataFim).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const fileName = `Consumo_por_equipamento_${startReport}_${endReport}`
                const anchor = document.createElement("a");
                anchor.download = `${fileName}.${type}`;
                anchor.href = url;
                anchor.click();
                this.toggleFilters = false;
                this.loading = false;
            }, error: (err) => console.log('err', err)
        });
    }


}

