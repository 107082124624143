import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AjustarFaturamentoComponent, FinanceiroComponent, FinanceiroService } from './';
import { FaturamentoComponent } from './faturamento/faturamento.component';
import { FaturamentoService } from './faturamento/faturamento.service';
import { ViewFaturamentoComponent } from './view-faturamento/view-faturamento.component';

@NgModule({
    declarations: [
        FinanceiroComponent,
        FaturamentoComponent,
        AjustarFaturamentoComponent,
        ViewFaturamentoComponent,
    ],
    imports: [SharedModule],
    providers: [FaturamentoService, FinanceiroService]
})
export class FinanceiroModule { }
