<h1 mat-dialog-title class="px-3">{{ servico.id ? 'Editar' : 'Novo' }} Serviço</h1>
<div mat-dialog-content class="overflow-visible px-3 pb-0">
    <!-- NOME -->
    <mat-form-field class="w-100">
        <mat-label>Título</mat-label>
        <input matInput name="titulo" #titulo="ngModel" [(ngModel)]="servico.titulo" required>
        <mat-error *ngIf="showInputError(titulo, 'required')">Título é obrigatório</mat-error>
    </mat-form-field>

    <!-- DESCRIPTION -->
    <mat-form-field class="w-100">
        <mat-label>Informações Adicionais</mat-label>
        <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="servico.descricao"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="px-3 pb-3 justify-content-end">
    <button mat-button mat-dialog-close class="px-3">Fechar</button>
    <button mat-flat-button color="primary" (click)="save()" [ngClass]="{'btn-dialog-spinner': loading}" [disabled]="!servico.titulo || loading">
        <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">Salvar</span>
    </button>
</div>