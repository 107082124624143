<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>
    <mat-card *ngIf="!loading">
        <mat-card-content>
            <div class="row">
                <div class="col-12 col-md-auto d-flex flex-column align-items-center divAvatar pe-3">
                    <img height="140" width="140" class="img-cover rounded-circle mb-3 bg-default" [src]="usuario.avatarUrl || '/assets/img/default_user.jpeg'" />
                    <div class="status-badge text-white px-3 py-1 fs-7 {{ usuario.enabled ? 'bg-success' : 'bg-secondary' }}">
                        {{ usuario.enabled ? 'ATIVO' : 'INATIVO' }}
                    </div>
                </div>
                <div class="col-12 col-md row">
                    <div class="col-12 mb-3">
                        <span class="fw-bold fs-7 opacity-50">NOME</span>
                        <div class="lh-sm">{{ usuario.firstName }} {{ usuario.lastName }}</div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <span class="fw-bold fs-7 opacity-50">EMAIL</span>
                        <div class="lh-sm">{{ usuario.email }}</div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <span class="fw-bold fs-7 opacity-50">TELEFONE</span>
                        <div class="lh-sm">{{ usuario.telefone | mask: '(00) 00000-0000' }}</div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <span class="fw-bold fs-7 opacity-50">PERFIL</span>
                        <div class="lh-sm">{{ usuario.role | translate }}</div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <span class="fw-bold fs-7 opacity-50">CARGO</span>
                        <div class="lh-sm">{{ usuario.cargo }}</div>
                    </div>
                    <div class="col-12 mb-3">
                        <span class="fw-bold fs-7 opacity-50">DESCRIÇÃO</span>
                        <div>{{ usuario.descricao }}</div>
                    </div>
                    <div>
                    </div>
                    <div class="col-12 mb-3">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="col-12 col-md-auto ms-auto">
                        <button type="button" mat-button color="primary" class="px-4" [routerLink]="['/usuario']">VOLTAR</button>
                        <button type="button" mat-button color="primary" class="px-4" (click)="sendResetPasswordEmail()">Enviar email de senha</button>
                    </div>                    
                    <div class="col-12 col-md-2">
                        <button mat-flat-button color="primary" class="w-100" 
                            [routerLink]="authService.user.id == usuario.id ? ['/perfil'] : ['/usuario', usuario.id, 'editar']">
                            EDITAR
                        </button>
                    </div>
                </div>
            </div>
            <br />
            <div class="col-12 col-md row">
                <div class="col-12 col-md-4">
                    <h2 class="fs-3 fw-light mb-0">AUSÊNCIAS:</h2>                    
                </div>                     
            </div>
            <div class="col-12 col-md row" *ngIf="ausencias && ausencias.length > 0">
                <div class="col-12">
                    <generate-table 
                        [dataSource]="ausencias" [columns]="columns" [loading]="loading"                        
                    ></generate-table>
                    <!--
                    <table class="border-top">
                        <tbody>
                            <tr class="text-center">
                                <th>Data Início</th>
                                <th>Data Fim</th>
                                <th>Motivo</th>
                                <th>Data Inclusão</th>
                                <th>Responsável</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let ausencia of ausencias" class="text-center">
                                <td>{{ ausencia.dataInicio | date:'dd/MM/yyyy' }}</td>
                                <td>{{ ausencia.dataFim | date:'dd/MM/yyyy' }}</td>
                                <td>{{ ausencia.motivo }}</td>
                                <td>{{ ausencia.created_at | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                                <td>{{ ausencia.responsavel }}</td>
                                <td>Apagar</td>
                            </tr>
                        </tbody>
                    </table>
                    -->
                </div>
            </div>
            <div class="col-12 col-md row">
                <div class="col-12 mb-3">
                    <mat-divider></mat-divider>
                </div>
                <div class="col-12 col-md-4">
                    <button mat-flat-button color="primary" class="w-100" 
                        [routerLink]="['/usuario', usuario.id, 'ausencia']">
                        NOVA AUSÊNCIA
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>