export enum RolesEnum {
    GESTAO_ADMIN = 'GESTAO_ADMIN',
    CONTRATADO_ADM = 'CONTRATADO_ADM',
    CONTRATADO_CONDUTOR = 'CONTRATADO_CONDUTOR',
    FUNCIONARIO = 'FUNCIONARIO',
    EXECUTOR_LOCAL = 'EXECUTOR_LOCAL',
    FISCAL = 'FISCAL',
    PADRAO = 'PADRAO',
    EMISSOR = 'EMISSOR',
    AFERIDOR = 'AFERIDOR',
    COORDENADOR = 'COORDENADOR',
}

export const RolesInfo = [{
    nomeExibicao: 'ADMIN SISDIO',
    value: RolesEnum.GESTAO_ADMIN,
    description: 'Gerente da NOVACAP',
}, {
    nomeExibicao: 'ADMIN BENEFICIÁRIA',
    value: RolesEnum.CONTRATADO_ADM,
    description: 'Fornecedor da NOVACAP',
}, {
    nomeExibicao: 'CONDUTOR',
    value: RolesEnum.CONTRATADO_CONDUTOR,
    description: 'Motorista/Operador de equipamentos',
}, {
    nomeExibicao: 'FUNCIONÁRIO',
    value: RolesEnum.FUNCIONARIO,
    description: 'Funcionários da Novacap responsáveis pela administração/orgão/polo',
}, {
    nomeExibicao: 'FISCAL',
    value: RolesEnum.FISCAL,
    description: 'Funcionário da Novacap responsável por fiscalizar a execução dos diários',
}, {
    nomeExibicao: 'PADRAO',
    value: RolesEnum.PADRAO,
    description: 'Usuário simples sem acesso ao sistema',
}, {
    nomeExibicao: 'EMISSOR',
    value: RolesEnum.EMISSOR,
    description: 'Emissor de DIOS para contratos existentes',
}, {
    nomeExibicao: 'AFERIDOR',
    value: RolesEnum.AFERIDOR,
    description: 'Aferidor de DIOS para contratos existentes',
},
{
    nomeExibicao: 'COORDENADOR',
    value: RolesEnum.COORDENADOR,
    description: 'Coordenador de Aferidores',
}]

export const Profiles = {
    GESTAO_ADMIN: [RolesEnum.GESTAO_ADMIN, RolesEnum.CONTRATADO_ADM, RolesEnum.CONTRATADO_CONDUTOR, RolesEnum.FISCAL, RolesEnum.FUNCIONARIO, RolesEnum.PADRAO],
    CONTRATADO_ADM: [RolesEnum.CONTRATADO_ADM],
    CONTRATADO_CONDUTOR: [RolesEnum.CONTRATADO_CONDUTOR],
    FISCAL: [RolesEnum.FISCAL],
    EXECUTOR_LOCAL: [RolesEnum.FUNCIONARIO],
    PADRAO: [RolesEnum.PADRAO],
    EMISSOR:  [RolesEnum.EMISSOR],
    AFERIDOR: [RolesEnum.AFERIDOR],
    COORDENADOR: [RolesEnum.COORDENADOR],
}