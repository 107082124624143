<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} local de apresentação</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12">
                    <mat-label>Nome</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="localApresentacao.nome" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- CEP -->
                <mat-form-field class="col-12 col-md-2">
                    <mat-label>CEP</mat-label>
                    <input matInput name="cep" #cep="ngModel" [(ngModel)]="localApresentacao.endereco.cep" mask="00000-000">
                </mat-form-field>

                <!-- RUA -->
                <mat-form-field class="col-12 col-md-10">
                    <mat-label>Logradouro</mat-label>
                    <input matInput name="rua" #rua="ngModel" [(ngModel)]="localApresentacao.endereco.rua">
                </mat-form-field>

                <!-- NUMERO -->
                <mat-form-field class="col-12 col-md-2">
                    <mat-label>Número</mat-label>
                    <input matInput name="numero" #numero="ngModel" [(ngModel)]="localApresentacao.endereco.numero">
                </mat-form-field>

                <!-- BAIRRO -->
                <mat-form-field class="col-12 col-md-5">
                    <mat-label>Bairro</mat-label>
                    <input matInput name="bairro" #bairro="ngModel" [(ngModel)]="localApresentacao.endereco.bairro">
                </mat-form-field>

                <!-- CIDADE -->
                <mat-form-field class="col-12 col-md-5">
                    <mat-label>Cidade</mat-label>
                    <input matInput name="cidade" #cidade="ngModel" [(ngModel)]="localApresentacao.endereco.cidade">
                </mat-form-field>

                <!-- ESTADO -->
                <mat-form-field class="col-12 col-md-2">
                    <mat-label>Estado</mat-label>
                    <mat-select name="estado" #estado="ngModel" [(ngModel)]="localApresentacao.endereco.estado">
                        <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- COMPLEMENTO -->
                <mat-form-field class="col-12 col-md-10">
                    <mat-label>Complemento</mat-label>
                    <input matInput name="complemento" #complemento="ngModel" [(ngModel)]="localApresentacao.endereco.complemento">
                </mat-form-field>

                <!-- LATITUDE -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Latitude</mat-label>
                    <input matInput name="lat" #lat="ngModel" [(ngModel)]="localApresentacao.lat">
                </mat-form-field>

                <!-- LONGITUDE -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Longitude</mat-label>
                    <input matInput name="lng" #lng="ngModel" [(ngModel)]="localApresentacao.lng">
                </mat-form-field>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="localApresentacao.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" 
                        [routerLink]="['/localidade/orgao', this.orgaoId, 'visualizar']" [queryParams]="{tab:'locais-apresentacao'}">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
