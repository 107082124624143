import * as dayjs from "dayjs";

export function primeiroDiaDoMes(): Date {
    const hoje = new Date();
    return new Date(hoje.getFullYear(), hoje.getMonth(), 1, 0, 0, 1);
}

export function primeiroDiaDoMesString(): any {
    return dayjs(primeiroDiaDoMes()).format('YYYY-MM-DDT00:00:00');
}

export function dataAtualString(): any {
    return dayjs(new Date()).format('YYYY-MM-DDT23:59:59');
}


export function convertMinutesToHours(minutes: number): { hours: number, minutes: number } {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  return { hours, minutes: remainingMinutes };
}

export function useTimeOneDate(date: Date, time: string){
    const [hours, minutes] = time.split(':').map(Number);
    
    const newDate = new Date(date);
    newDate.setHours(hours, minutes, 0, 0);

    return newDate;
}

export function convertDateFormat(dateString: string): string {
    // Split the input date string by the separator "/"
    const [day, month, year] = dateString.split('/');
  
    // Ensure the input date string is valid
    if (!day || !month || !year) {
      throw new Error('Invalid date format. Expected format is DD/MM/YYYY.');
    }
  
    // Return the formatted date string in the format YYYY-MM-DD
    return `${year}-${month}-${day}`;
}

export function formatarTempoMinutosESegundos(minutes: number): string {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = (minutes % 60).toFixed(0);
    return `${hours}h ${remainingMinutes} min`;
  } else {
    const remainingSeconds = ((minutes - Math.floor(minutes)) * 60).toFixed(0);
    return `${minutes.toFixed(0)} min, ${remainingSeconds} seg`;
  }
}
