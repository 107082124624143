import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AssociarCondutorComponent } from '../associar-condutor/associar-condutor.component';
import { AuthService, RolesEnum } from 'src/app/core';
import { Breadcrumb, ImgDialog } from 'src/app/shared/components';
import { CarouselComponent } from 'src/app/shared/components/carousel/carousel.component';
import { catchErr, formatHumanTime, getBadgeColor } from 'src/app/shared/utils';
import { DIOPrecificacao, Dio, ExecucaoDetalhes, StatusExecucaoDIO } from '../dio.model';
import { DioService } from '../dio.service';
import { AssinaturaDioComponent } from 'src/app/dio/view-dio/assinatura-dio/assinatura-dio.component';
import { CancelarDioComponent } from './cancelar-dio/cancelar-dio.component';
import { UsuarioService } from 'src/app/usuario';
import { UpdateAferidorComponent } from './update-aferidor/update-aferidor.component';
import { AfericaoService } from '../afericao.service';

@Component({
    selector: 'view-dio',
    templateUrl: './view-dio.component.html',
    styleUrls: ['./view-dio.component.css'],
})
export class ViewDioComponent {

    loading = true;
    idDio: string;
    dio = {} as Dio;
    currentRole: string;
    statusRole: string;
    precificacoes: DIOPrecificacao[];
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Diários de Operação', back: true },
        { label: 'Visualizar DIO' },
    ]

    getBadgeColor = getBadgeColor;
    formatHumanTime = formatHumanTime;
    RolesEnum = RolesEnum;

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private dioService: DioService,
        private afericaoService: AfericaoService,
        private router: Router,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private readonly userService: UsuarioService
    ) { }

    ngOnInit() {
        this.idDio = this.activatedRoute.snapshot.params['idDio'];
        this.currentRole = this.authService.user.realm_access.roles[0];
        this.getDio();
    }

    print() {
        window.print();
    }

    openAssociarCondutorDialog() {
        const dialogRef = this.dialog.open(AssociarCondutorComponent, { data: { dio: this.dio, beneficiaria: this.dio.contrato.beneficiaria.id } });
        dialogRef.afterClosed().subscribe((reload) => !!reload && this.getDio());
    }

    openUpdateAferidorDialog() {
        const dialogRef = this.dialog.open(UpdateAferidorComponent, { 
          data: this.dio, 
        });
        dialogRef.afterClosed().subscribe((reload) => !!reload && this.getDio());
    }

    openSignatureDialog(assinatura: any) {
        const data = {
            nome: `${this.dio.funcionarioResponsavelOrgao || ''}`,
            imagens: [{ url: assinatura.url }], // TODO pegar assinatura correta
            observacao: assinatura.observacao
        }
        this.dialog.open(ImgDialog, { data });
    }

    openCarousel(execucao: ExecucaoDetalhes[]) {
        const data = execucao.map((e) => ({
            src: e.url,
            alt: e.name,
            description: e.descricao,
        }));
        this.dialog.open(CarouselComponent, { data, height: '100vh', width: '60vw' });
    }

    openToSignDialog() {
        this.dialog.open(AssinaturaDioComponent, {
            data: {
                dioId: this.dio.id,
                dioNumber: this.dio.numero,
                statusExecucao: this.dio.statusExecucao
            }, width: '500px'
        })
    }

    syncDadosDeExecucao() {
        this.loading = true;
        this.dioService.synchronizeDadosDeExecucao(this.idDio).subscribe({
            next: () => this.getDio(),
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    allowedEditDio() {
        const allowedRoles = [
            RolesEnum.GESTAO_ADMIN.toString(),
            RolesEnum.CONTRATADO_ADM.toString(),
            RolesEnum.EMISSOR.toString()
        ];

        return (allowedRoles.includes(this.currentRole)) && this.dio.statusExecucao === StatusExecucaoDIO.AGUARDANDO_EXECUCAO;
    }

    allowedAferirDio() {
        const allowedRoles = [
            RolesEnum.GESTAO_ADMIN.toString(),
            RolesEnum.COORDENADOR,                 
            RolesEnum.AFERIDOR
        ];

        return (allowedRoles.includes(this.currentRole));
    }

    allowedViewMapaDio() {
        const allowedRoles = [
            RolesEnum.GESTAO_ADMIN.toString(),                        
            RolesEnum.COORDENADOR,
            RolesEnum.EMISSOR,
            RolesEnum.FUNCIONARIO,
            RolesEnum.FISCAL,
            RolesEnum.AFERIDOR
        ];

        return (allowedRoles.includes(this.currentRole)) && (this.dio.statusExecucao == 'AGUARDANDO_FUNCIONARIO' || this.dio.statusExecucao == 'AGUARDANDO_FISCAL');
    }

    allowedToCancelDio() {
        return this.dio.statusExecucao !== StatusExecucaoDIO.EXECUTADA &&
            this.dio.statusExecucao !== StatusExecucaoDIO.EXPIRADA &&
            this.dio.statusExecucao !== StatusExecucaoDIO.CANCELADA;
    }

    allowedSignatureDio() {
        const statusRole = this.dio.statusExecucao?.substring(this.dio.statusExecucao?.indexOf('_') + 1)!;
        return (
            (this.currentRole === RolesEnum.GESTAO_ADMIN || this.currentRole === RolesEnum.FUNCIONARIO || this.currentRole === RolesEnum.FISCAL) &&
            (statusRole == RolesEnum.FUNCIONARIO || statusRole == RolesEnum.FISCAL)
        ) || (this.dio.statusExecucao === StatusExecucaoDIO.CANCELADA && this.currentRole == RolesEnum.FISCAL);
    }

    showCancelarDialog() {
        this.dialog.open(CancelarDioComponent, { data: { dio: this.dio } });
    }

    private getDio() {
        this.loading = true;
        this.dioService.getById<Dio>(this.idDio).subscribe({
            next: (res) => {
                let execucaoDetalhes;
                if (res?.execucaoDetalhes && res?.execucaoDetalhes.length) {
                    for (const execucao of res.execucaoDetalhes) {
                        if (execucao.tipo === 'SISTEMA' && (execucao.kmRodados && execucao.kmRodados > 0)) {
                            execucaoDetalhes = execucao;
                            continue;
                        }

                        if (execucao.tipo === 'SISTEMA') {
                            continue;
                        }

                        if(execucao?.kmRodados && execucao?.kmRodados < 0) {
                            execucao.kmRodados = 0;
                        }
                        execucaoDetalhes = execucao;
                    }
                }

                this.dio = {
                    ...this.dio,
                    ...res,
                    execucaoDetalhesValida: execucaoDetalhes
                };
                if (res?.afericao?.aferidor?.userId) {                  
                  this.userService.getById<any>(res.afericao.aferidor.userId).subscribe((user) => {
                        this.dio = {
                            ...this.dio,
                            afericao: {
                                ...res.afericao,
                                aferidor: {
                                  ...user,
                                  ...res.afericao
                                }
                            }
                        };
                    });
                }

                this.dio.assinaturas = this.dio.assinaturas.sort((a, b) => a.created_at.localeCompare(b.created_at));

                if (
                    (this.dio.statusExecucao === StatusExecucaoDIO.EXECUTADA
                        || this.dio.statusExecucao === StatusExecucaoDIO.AGUARDANDO_FUNCIONARIO)
                    && this.currentRole != RolesEnum.AFERIDOR
                ) {
                    this.findPrecificacoes();
                }
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    canReadPrecificacoes() {
        const rolesNotAllowed = ![
          RolesEnum.AFERIDOR.toString(),           
          RolesEnum.COORDENADOR.toString(),
        ].includes(this.currentRole);
        const isExecutada = this.dio.statusExecucao === StatusExecucaoDIO.EXECUTADA;
        const isAguardandoFuncionario = this.dio.statusExecucao === StatusExecucaoDIO.AGUARDANDO_FUNCIONARIO;
        const precificacaoExists = this.precificacoes && this.precificacoes.length

        return (isExecutada || isAguardandoFuncionario) && precificacaoExists && rolesNotAllowed;
    }

    canReadTarifacoes() {
        const rolesNotAllowed = ![
          RolesEnum.AFERIDOR.toString(), 
          RolesEnum.COORDENADOR.toString(),
        ].includes(this.currentRole);
        const alocacaoExists = this.dio.alocacao;

        return alocacaoExists && rolesNotAllowed;
    }

    canChangeAferidor() {
      const rolesAllowed = [
        RolesEnum.COORDENADOR.toString(),
        RolesEnum.GESTAO_ADMIN.toString(),
      ].includes(this.currentRole);
      const statusesAllowed = this.dio.statusExecucao == 'EXECUTADA' && this.dio.statusAuditoria == 'AGUARDANDO_AUDITORIA' ;

      return statusesAllowed && rolesAllowed;
    }

    iniciarAfericao() {
        this.afericaoService.startAfericao(this.idDio).subscribe({
            next: (res) => {
                this.router.navigate(['/dio', this.idDio, 'aferir']);
            },
            error: (err) => catchErr(err, this.snackBar)
        });
    }

    private findPrecificacoes() {
        this.loading = true;
        this.dioService.findPrecificacoesByDIOId(this.idDio).subscribe({
            next: (res) => this.precificacoes = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}