import { Component, OnInit } from '@angular/core';
import { Observable, Subject, catchError, concat, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { UsuarioResponse, UsuarioService } from 'src/app/usuario';
import { Contrato } from '../contrato.model';
import { catchErr, convertToBase64, convertToDatePicker, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { ContratoService } from '../contrato.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RolesEnum } from 'src/app/core';
import { Beneficiaria, BeneficiariaService } from 'src/app/beneficiaria';
import { Pagination, StoragePageEnum } from 'src/app/shared/models';
import * as dayjs from 'dayjs';
import { StorageService } from 'src/app/shared/services';
import { Location } from '@angular/common';

@Component({
    selector: 'form-contrato',
    templateUrl: './form-contrato.component.html'
})
export class FormContratoComponent implements OnInit {

    breadcrumb: Breadcrumb[];
    loading: any = { 
        main: false,
        save: false,
        beneficiaria: false,
        contratado: false,
        responsavel: false,
        suplente: false
    };

    responsaveis: UsuarioResponse[] = [];

    beneficiaria$: Observable<Beneficiaria[]>;
    beneficiariaInput$ = new Subject<string>();
    contratado$: Observable<UsuarioResponse[]>;
    contratadoInput$ = new Subject<string>();
    responsavel$: Observable<UsuarioResponse[]>;

  

    responsavelInput$ = new Subject<string>();
    suplente$: Observable<UsuarioResponse[]>;
    suplenteInput$ = new Subject<string>();

    title: string;
    contrato = {} as Contrato;
    showInputError = showInputError;

    constructor(
        private location: Location,
        private activatedRoute: ActivatedRoute,
        private contratoService: ContratoService,
        private beneficiariaService: BeneficiariaService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private usuarioService: UsuarioService,
        private snackBar: MatSnackBar,
        private storageService: StorageService
    ) { }

    ngOnInit() {
        console.log('oninit');
        const storage = this.storageService.storage[StoragePageEnum.FormContrato];
        if (storage && storage.contrato) {
            this.contrato = { ...storage.contrato };
            this.storageService.remove(StoragePageEnum.FormContrato);
        }

        this.contratado$ = this.getData(this.contratadoInput$, 'contratado', (term: string) => this.usuarioService.getPaginated<UsuarioResponse>(0, 20, term, RolesEnum.CONTRATADO_ADM))
        this.responsavel$ = this.getData(this.responsavelInput$, 'responsavel', (term: string) => this.usuarioService.getPaginated<UsuarioResponse>(0, 20, term, RolesEnum.GESTAO_ADMIN))
        this.suplente$ = this.getData(this.suplenteInput$, 'suplente', (term: string) => this.usuarioService.getPaginated<UsuarioResponse>(0, 20, term, RolesEnum.GESTAO_ADMIN))
        this.beneficiaria$ = this.getData(this.beneficiariaInput$, 'beneficiaria', (term: string) => this.beneficiariaService.getPaginated<Beneficiaria>(0, 20, term))
       
        
        const beneficiariaId = this.activatedRoute.snapshot.queryParams['beneficiariaId'];
        if (beneficiariaId) {
          this.beneficiariaService.getById<Beneficiaria>(beneficiariaId as string).subscribe((res) => {
            this.contrato = {
              ...this.contrato,
              beneficiaria: res as Beneficiaria,
            }
            this.responsaveis = res.responsaveis;
          })
        }

        this.contrato.id = this.activatedRoute.snapshot.params['idContrato'];
        this.title = this.contrato.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Contratos', route: '/contrato' },
            { label: this.title + ' contrato' },
        ]
        if (this.contrato.id) {
            this.loading.main = true;
            this.contratoService.getById<Contrato>(this.contrato.id).subscribe({
                next: (res) => this.contrato = {
                    ...res, 
                    inicioVigencia: convertToDatePicker(res.inicioVigencia), 
                    fimVigencia: convertToDatePicker(res.fimVigencia)
                }, error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }
    }

    setBeneficiaria(beneficiaria: Beneficiaria) {
      this.contrato = {
        ...this.contrato,
        beneficiaria: beneficiaria,
      }

      this.beneficiariaService.getById<Beneficiaria>(beneficiaria.id).subscribe((res) => {
        this.responsaveis = res.responsaveis;
      });
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    routeWithStorage(route: string, objKey: string, userRole?: string) {
        console.log('current path', this.location.path());
        const pathBase64 = convertToBase64(this.location.path());
        console.log('previewsPage: ', pathBase64);
        !this.contrato.id && this.storageService.set(StoragePageEnum.FormContrato, { contrato: this.contrato })
        this.router.navigate([route], { queryParams: { returnObj: objKey, userRole, 'previewsPage': pathBase64 } });
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>

        const contratoToSave = { ...this.contrato }
        contratoToSave.beneficiariaId = contratoToSave.beneficiaria.id;
        contratoToSave.inicioVigencia = dayjs(contratoToSave.inicioVigencia).format('YYYY-MM-DD');
        contratoToSave.fimVigencia = dayjs(contratoToSave.fimVigencia).format('YYYY-MM-DD');
        contratoToSave.valorContratado = +contratoToSave.valorContratado;
        contratoToSave.valorExecutado = +contratoToSave.valorExecutado;

        if(typeof contratoToSave.contratado !== 'string') {
          contratoToSave.contratado = contratoToSave.contratado.id;
        }

        if(typeof contratoToSave.contratanteSuplente !== 'string'){
            contratoToSave.contratanteSuplente = contratoToSave.contratanteSuplente.id;
        }

        if (contratoToSave.id) {
            contratoToSave.contratado = contratoToSave.contratado.id;
            contratoToSave.contratanteResponsavel = contratoToSave.contratanteResponsavel.id;
            contratoToSave.contratanteSuplente = contratoToSave.contratanteSuplente.id;
            request = this.contratoService.update<Contrato>(contratoToSave.id, contratoToSave)
        } else {
            request = this.contratoService.create<Contrato>(contratoToSave)
        }

        request.subscribe({
            next: (res: Contrato) => {
                this.snackBar.open('Contrato salvo com sucesso!', '✕', { panelClass: ['success'] });
                !contratoToSave.id 
                    ? this.router.navigateByUrl('/contrato')
                    : this.router.navigateByUrl(`/contrato/${res.id}/editar?tab=alocacao`)
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.contrato.nome, (dialogRef: MatDialogRef<any>) => {
            this.contratoService.inativar<Contrato>(this.contrato.id).subscribe({
                next: () => {
                    this.snackBar.open('Contrato inativado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigateByUrl('/contrato');
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        }, 'Inativar', undefined, 'Inativar');
    }

    searchFn(term: string, item: any) {
      term = term.toLocaleLowerCase();
      return  item.firstName.toLocaleLowerCase().indexOf(term) > -1 ||
              item.lastName.toLocaleLowerCase().indexOf(term) > -1;
    }

    private getData(inputSubject: Subject<string>, loading: string, observable: Function) {
        return concat(
            of([]), // default items
            inputSubject.pipe(
                distinctUntilChanged(),
                tap(() => this.loading[loading] = true),
                switchMap((term) => 
                    term ? observable(term).pipe( 
                        map((res: Pagination<any>) => res.data), // select by attr
                        catchError(() => of([])), // empty list on error
                        tap(() => this.loading[loading] = false)
                    ) as Promise<any> : new Promise(() => this.loading[loading] = false) // not search all
                )
            )
        );
    }
}
