import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { Polo } from '../../localidade.model';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { PoloService } from '../polo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-form-polo',
    templateUrl: './form-polo.component.html',
})
export class FormPoloComponent implements OnInit {

    loading: any = { main: false, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    polo = {} as Polo;
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private poloService: PoloService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.polo.id = this.activatedRoute.snapshot.params['id'];
        this.title = this.polo.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Localidades', route: '/localidade' },
            { label: this.title + ' polo' },
        ]
        if (this.polo.id)
            this.getById();
    }

    save() {
        this.loading.save = true;
        let request: Observable<Polo>

        request = this.polo.id
            ? this.poloService.update<Polo>(this.polo.id, this.polo)
            : this.poloService.create<Polo>(this.polo)

        request.subscribe({
            next: () => {
                this.snackBar.open('Polo salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/localidade']);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.polo.nome, (dialogRef: MatDialogRef<any>) => {
            this.poloService.remove<Polo>(this.polo.id).subscribe({
                next: () => {
                    this.snackBar.open('Grupo apagado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/localidade']);
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        })
    }

    private getById() {
        this.loading.main = true;
        this.poloService.getById<Polo>(this.polo.id).subscribe({
            next: (res) => this.polo = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }
}
