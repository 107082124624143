import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { AssociarCondutorComponent, AferirDioComponent, DioComponent, FormDioComponent, ViewDioComponent, CancelarAfericaoDioComponent, AssinaturaDioComponent, CancelarDioComponent, PrintDioComponent } from './';
import { UpdateAferidorComponent } from './view-dio/update-aferidor/update-aferidor.component';

@NgModule({
    declarations: [
        AferirDioComponent,
        AssinaturaDioComponent,
        AssociarCondutorComponent,
        UpdateAferidorComponent,
        CancelarAfericaoDioComponent,
        CancelarDioComponent,
        DioComponent,
        FormDioComponent,
        PrintDioComponent,
        ViewDioComponent,
    ],
    imports: [
        SharedModule,
        GoogleMapsModule
    ],
    exports: [DioComponent]
})
export class DioModule { }
