import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as dayjs from 'dayjs';
import { Contrato, ContratoService } from 'src/app/contrato';
import { Beneficiaria } from 'src/app/beneficiaria';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { catchErr, formatHumanTime } from 'src/app/shared/utils';
import { RelatorioService } from '../relatorio.service';
import { CurrencyPipe } from '@angular/common';
import { dataAtualString, primeiroDiaDoMesString } from 'src/app/shared/date-utils';

export interface VBBFilters {
    beneficiaria?: Beneficiaria,
    contrato?: Contrato,
    dataInicio?: string,
    dataFim?: string,
    classificacao?: string,
    removePagination?: boolean,
    page?: number,
}
@Component({
    selector: 'app-valor-bruto-beneficiario',
    templateUrl: './valor-bruto-beneficiario.component.html',
    providers: [CurrencyPipe],
})
export class ValorBrutoBeneficiarioComponent implements OnInit {
    loading: boolean;
    toggleFilters: boolean;
    filters = {} as VBBFilters;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios', route: '/relatorio' },
        { label: 'Valores Brutos por Beneficiários' },
    ]

    contratos: Contrato[];
    classificacoes = ['VEICULOS', 'MAQUINAS']

    dataSource: any[];
    csvData: any[];
    totalizadores: any = {};

    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
    columns: GenerateTableColumn[] = [
        { header: 'N° DIO', attr: 'dioNumero', tdClass: 'text-nowrap' },
        { header: 'Contrato', attr: 'contrato' },
        { header: 'Prefixo', attr: 'prefixo' },
        { header: 'Placa/Série', attr: 'registro' },
        { header: 'Grupo', attr: 'grupo' },
        { header: 'Início Op.', attr: 'dataInicioOperacao' },
        { header: 'Fim Op.', attr: 'dataFimOperacao' },
        { header: 'km', attr: 'kmRodados' },
        { header: 'Valor/km', attr: 'valorPorKm' },
        { header: 'Pgto do km', attr: 'pagamentoKm', thClass: 'text-nowrap' },
        { header: 'Hrs Disponíveis', attr: 'horasDisponiveis' },
        { header: 'Valor/Hr Disponível', attr: 'valorHoraDisponivel' },
        { header: 'Pgto/Hr Disponível', attr: 'pagamentoHoraDisponivel' },
        { header: 'Valor Total', attr: 'valorTotalDIO' },
    ];

    constructor(
        private contratoService: ContratoService,
        private readonly relatorioService: RelatorioService,
        private readonly currencyPipe: CurrencyPipe,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.contratoService.getAll<Contrato>().subscribe({
            next: (res) => this.contratos = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);

        this.filters.dataInicio = primeiroDiaDoMesString();
        this.filters.dataFim = dataAtualString();

        this.getAll(this.filters);
    }

    searchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.nome.toLocaleLowerCase().indexOf(term) > -1;
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    print() {
        window.print();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize
        this.filters.page = pageEvent ? pageEvent.current : this.pagination.current;
        const filters = { ...this.filters };
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.getAll(filters);
    }

    export(type: string = 'csv') {
      this.loading = true;
      const filters = { ...this.filters, removePaingation: true };
      filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
      filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;
      this.relatorioService.downloadArquivoCsvOrPdf(filters, 'dios/valores-brutos', type).subscribe({
          next: (res: any) => {
            const url = window.URL.createObjectURL(res.body as Blob);
            const startReport = filters.dataInicio ? dayjs(filters.dataInicio).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
            const endReport = filters.dataFim ? dayjs(filters.dataFim).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
            const fileName = `Valores_Brutos_${startReport}_${endReport}`
            const anchor = document.createElement("a");
            anchor.download = `${fileName}.${type}`;
            anchor.href = url;
            anchor.click();
            this.toggleFilters = false;
            this.loading = false;
          }, error: (err) => {
            this.loading = false;
            catchErr(err, this.snackBar)
          }
      });
    }

    private getAll(filters: VBBFilters) {
        this.loading = true;
        this.relatorioService.getValoresBrutos(filters, this.pagination).subscribe((res: any) => {
            this.totalizadores = {
                ...res.totals,
                totalHorasDisponiveis: formatHumanTime(res.totals.totalHorasDisponiveis) || 0,
                totalRegistros: res.report.total
            };
            this.dataSource = this.sanitizeResponse(res.report.data);
            this.pagination = {
              ...this.pagination,
              total: res.report.total, 
            }
            this.toggleFilters = false;
            this.loading = false;
        });
    }

    private sanitizeResponse(data: any[]) {
        return data.map((item: any) => ({
            ...item,
            dataInicioOperacao: item.dataInicioOperacao && dayjs(item.dataInicioOperacao).format('DD/MM/YYYY HH:mm:ss'),
            dataFimOperacao: item.dataFimOperacao && dayjs(item.dataFimOperacao).format('DD/MM/YYYY HH:mm:ss'),
            kmRodados: `${item?.kmRodados || 0} km`,
            valorPorKm: this.currencyPipe.transform(item.valorPorKm, 'BRL') || item.valorPorKm,
            pagamentoKm: this.currencyPipe.transform(item.pagamentoKm, 'BRL') || item.pagamentoKm,
            horasDisponiveis: formatHumanTime(item.horasDisponiveis),
            valorHoraDisponivel: this.currencyPipe.transform(item.valorHoraDisponivel, 'BRL') || item.valorHoraDisponivel,
            pagamentoHoraDisponivel: this.currencyPipe.transform(item.pagamentoHoraDisponivel, 'BRL') || item.pagamentoHoraDisponivel,
            valorTotalDIO: this.currencyPipe.transform(item.valorTotalDIO, 'BRL') || item.valorTotalDIO
        }));
    }
}
