<mat-spinner class="mx-auto my-5" *ngIf="!aferidores"></mat-spinner>

<ng-container *ngIf="aferidores">
    <div mat-dialog-title>Associar Aferidor</div>
    <div mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Buscar aferidor</mat-label>
            <input matInput name="aferidor" autocomplete="off" [(ngModel)]="term" (keyup)="search()">
        </mat-form-field>

        <span class="fw-bold fs-7 opacity-50">AFERIDOR ATUAL</span>
        <div class="mb-3 ms-3 mt-2 text-black">{{ aferidorAtual }}</div>

        <span class="fw-bold fs-7 opacity-50">AFERIDORES</span>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6 py-1 my-1 rounded border text-black action" 
                    *ngFor="let aferidor of aferidoresFiltered" (click)="aferidorIdSelected = aferidor.id"
                    [ngClass]="aferidorIdSelected == aferidor.id ? 'border-primary' : 'border-white'">
                    {{ aferidor.firstName | titlecase }} {{ aferidor.lastName | titlecase }}
                </div>
            </div>
        </div>

        <div class="text-center opacity-50" *ngIf="!aferidoresFiltered || !aferidoresFiltered.length">
            Nenhum aferidor encontrado
        </div>
    </div>
    <div mat-dialog-actions class="px-3 pb-3 justify-content-end">
        <button mat-button mat-dialog-close class="px-3">Fechar</button>
        <button mat-flat-button color="primary" (click)="update()" [ngClass]="{'btn-dialog-spinner': loading}" [disabled]="!aferidorIdSelected || loading">
            <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
            <span *ngIf="!loading">Associar</span>
        </button>
    </div>
</ng-container>