import { Component, Input, OnInit } from '@angular/core';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { Router } from '@angular/router';
import { TarifaService } from './tarifa.service';
import { Tarifa } from './tarifa.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';
import { CurrencyPipe } from '@angular/common';
import { Contrato } from '../../contrato.model';

@Component({
    selector: 'tarifa',
    templateUrl: './tarifa.component.html',
    providers: [CurrencyPipe]
})
export class TarifacaoComponent implements OnInit {
    @Input() contrato: Contrato;

    loading = true;
    dataSource: Tarifa[];

    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Grupo', attr: 'nomeGrupo' },
        { header: 'Tipo Tarifação Grupo', attr: 'grupoTarifacaoTipo' },
        { header: 'Valor/h disponível (Máquina)', attr: 'valorHoraDisponivelMaquina' },
        { header: 'Valor/h produzida', attr: 'valorHoraProduzida' },
        { header: 'Valor/h disponível (Veículo)', attr: 'valorHoraDisponivelVeiculo' },
        { header: 'Valor Km Rodado', attr: 'valorKmRodado' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
                action: (rowData: Tarifa) => this.router.navigateByUrl(`/contrato/${rowData.contrato.id}/tarifa/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private currencyPipe: CurrencyPipe,
        private tarifaService: TarifaService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.tarifaService.getByContractId<Tarifa>(this.pagination.current, this.pagination.pageSize, this.contrato.id).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((tarifa) => ({
                    ...tarifa,
                    nomeGrupo: tarifa.grupoEquipamento?.nome,
                    grupoTarifacaoTipo: tarifa.grupoTarifacaoTipo,
                    valorHoraDisponivelMaquina: this.currencyPipe.transform(tarifa.valorHoraDisponivelMaquina, 'BRL') || tarifa.valorHoraDisponivelMaquina,
                    valorHoraDisponivelVeiculo: this.currencyPipe.transform(tarifa.valorHoraDisponivelVeiculo, 'BRL') || tarifa.valorHoraDisponivelVeiculo,
                    valorHoraProduzida: this.currencyPipe.transform(tarifa.valorHoraProduzida, 'BRL') || tarifa.valorHoraProduzida,
                    valorKmRodado: this.currencyPipe.transform(tarifa.valorKmRodado, 'BRL') || tarifa.valorKmRodado,
                }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
