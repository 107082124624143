import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb, GenerateTableColumn } from 'src/app/shared/components';
import { catchErr, convertFromBase64, showInputError } from 'src/app/shared/utils';
import { UsuarioService, UsuarioResponse, Ausencia } from '..';
import { Observable } from 'rxjs';
import { AuthService, RolesEnum, RolesInfo } from 'src/app/core';
import { StorageService } from 'src/app/shared/services';
import { StoragePageEnum } from 'src/app/shared/models';
import { Aferidores } from 'src/app/dio/dio.model';
import { OrgaoService } from 'src/app/localidade/orgao/orgao.service';
import { Orgao } from 'src/app/localidade';
import { AfericaoService } from 'src/app/dio';
import { UsuarioAusenciaService } from '../usuario-ausencia.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-form-usuario-ausencia',
    templateUrl: './form-ausencia.component.html'
})
export class FormUsuarioAusenciaComponent implements OnInit {

    loading = { main: false, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    usuario = {} as UsuarioResponse;
    ausencia = {} as Ausencia;
    rolesInfo = RolesInfo;
    showInputError = showInputError;
    currentRole: string;
    
    constructor(
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private usuarioAusenciaService: UsuarioAusenciaService,
        private router: Router,
        private authService: AuthService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        console.log('logged user: ', this.authService.user);
        this.usuario.id = this.activatedRoute.snapshot.params['id'];

        this.title = this.usuario.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Usuários', route: '/usuario' },
            { label: this.title + ' usuário' },
        ]
        if (this.usuario.id) {
            this.loading.main = true;
            this.usuarioService.getById<UsuarioResponse>(this.usuario.id).subscribe({
                next: (res) => { 
                  this.usuario = res                  
                },
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }
    }

    compareWith(item: any, selected: any) {
        return item.value == selected
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>

        this.ausencia.dataInicio = this.ausencia.dataInicio ? `${dayjs(this.ausencia.dataInicio).format('YYYY-MM-DD')}` : this.ausencia.dataInicio;
        this.ausencia.dataFim = this.ausencia.dataFim ? `${dayjs(this.ausencia.dataFim).format('YYYY-MM-DD')}` : this.ausencia.dataFim;

        if (this.ausencia.id) {
            request = this.usuarioAusenciaService.update<Ausencia>(this.ausencia.id, this.ausencia)
        } else {
            this.ausencia.usuario = this.usuario.id ? this.usuario.id : '';
            this.ausencia.responsavelCadastro = this.authService.user.name + ' (' + this.authService.user.email + ')';
            console.log('criando ausencia: ', this.ausencia);
            request = this.usuarioAusenciaService.create<Ausencia>(this.ausencia)
        }

        request.subscribe({
            next: (res) => {
                console.log('res: ', res);
                const id = this.usuario.id;
                this.snackBar.open('Ausência cadastrada com sucesso!', '✕', { panelClass: ['success'] });

                this.router.navigate(['/usuario', id, 'visualizar']);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    isFuncionario(): boolean {
        return this.usuario && this.usuario.role === RolesEnum.FUNCIONARIO;
    }

    voltar(){
        const previewPageBase64 = this.activatedRoute.snapshot.queryParams['previewsPage'];

        if(previewPageBase64){
            const path = convertFromBase64(previewPageBase64);
            this.router.navigateByUrl(path);

        } else {
            this.router.navigateByUrl('');
        }

    }

}

