import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormCondutorComponent } from './form-condutor/form-condutor.component';
import { ListCondutorComponent } from './condutor.component';
import { AnotacaoComponent } from './anotacao/anotacao.component';
import { AnotacaoService } from './anotacao/anotacao.service';
import { CondutorService } from './condutor.service';
import { FormAnotacaoComponent } from './anotacao/form-anotacao/form-anotacao.component';

@NgModule({
    declarations: [
        FormCondutorComponent,
        ListCondutorComponent,
        FormAnotacaoComponent,
        AnotacaoComponent,
    ],
    imports: [SharedModule],
    providers: [
        CondutorService,
        AnotacaoService,
    ],
    exports: [
      FormCondutorComponent,
      ListCondutorComponent,
      FormAnotacaoComponent,
      AnotacaoComponent,
    ]
})
export class CondutorModule { }
