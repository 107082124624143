<div class="d-flex align-items-end mb-3">
    <h1 class="fs-3 fw-light mb-1">Grupo de equipamento</h1>
    <mat-form-field appearance="outline" class="input-sm ms-auto me-3">
        <mat-label>Buscar grupos</mat-label>
        <input matInput [(ngModel)]="filter" (keyup.enter)="search()">
        <button mat-icon-button matSuffix (click)="search()">
            <mat-icon>search</mat-icon>
        </button>
    </mat-form-field>
    <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['grupo/novo']">NOVO GRUPO</button>
</div>
<generate-table 
    [dataSource]="dataSource" [columns]="columns" [loading]="loading"
    [total]="pagination.total" [pageSize]="pagination.pageSize" 
    (pageEvent)="search($event)"
></generate-table>