import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';

export interface Breadcrumb {
    label: string;
    route?: string;
    queryParams?: Params;
    back?: boolean;
}

@Component({
    selector: 'breadcrumb',
    template: `
        <mat-card class="d-print-none mb-3">
            <mat-card-content class="py-2">
                <ul class="breadcrumb">
                    <li *ngFor="let item of items">
                        <a [routerLink]="[item.route]" [queryParams]="item.queryParams" *ngIf="item.route">{{ item.label }}</a>
                        <a href="javascript:;" (click)="back()" *ngIf="item.back">{{ item.label }}</a>
                        <span *ngIf="!item.route && !item.back">{{ item.label }}</span>
                    </li>
                </ul>
            </mat-card-content>
        </mat-card>
    `,
    styles: [`
        .breadcrumb { list-style: none; padding: 0; display: flex; }
        .breadcrumb li { color: var(--bs-gray); font-weight: lighter; position: relative; }
        .breadcrumb li:not(:first-child) { padding-left: 2rem; }
        .breadcrumb li:not(:first-child)::before { 
            content: '›';
            position: absolute;
            font-weight: bold;
            left: .8rem;
        }
    `]
})
export class BreadcrumbComponent {
    @Input() items: Breadcrumb[]

    back() {
        window.history.back();
    }
}
