import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb } from 'src/app/shared/components';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Config } from '../config.model';
import { ConfigService } from '../config.service';

@Component({
    selector: 'form-config',
    templateUrl: './form-config.component.html'
})
export class FormConfigComponent implements OnInit {

    breadcrumb: Breadcrumb[];
    loading: any = {
        main: false,
    };

    title: string;
    configuration = {} as Config;
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private configService: ConfigService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.configuration.id = this.activatedRoute.snapshot.params['idConfig'];
        this.title = this.configuration.id ? 'Editar' : 'Nova';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Configurações', route: '/configuracoes' },
            { label: this.title + ' configuração' },
        ]

        if (this.configuration.id) {
            this.loading.main = true;
            this.configService.getById<Config>(this.configuration.id).subscribe({
                next: (res) => this.configuration = res,
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>

        const configToSave = { ...this.configuration }

        request = configToSave.id
            ? this.configService.update<Config>(configToSave.id, configToSave)
            : this.configService.create<Config>(configToSave)

        request.subscribe({
            next: () => {
                this.snackBar.open('Confiuguação salva com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigateByUrl('/configuracoes')
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }
}
