interface Usuario {
    firstName: string;
    lastName: string;
    email: string;
    telefone: string;
    orgao?: string;
}

export interface UsuarioRegister extends Usuario {
    username: string;
    password: string;
}

export interface UsuarioRequest extends Usuario {
    role: string;
    cargo: string;
    descricao: string;

    id?: string;
    username?: string;
}

export interface UsuarioResponse extends UsuarioRequest {
    sub: string;
    avatarUrl: string;
    createdAt: number;
    name: string;
    realm_access: { roles: string[] }
    enabled: boolean;
}

export interface Ausencia {
    id: string;
    usuario: string;
    created_at: string;
    dataInicio: string;
    dataFim: string;
    motivo: string;
    responsavelCadastro: string;
    responsavel: string;
}