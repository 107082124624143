import { Component, OnInit } from '@angular/core';
import { LocaisApresentacaoService } from '../../../..';
import { StatesEnum } from 'src/app/shared/models';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { LocaisApresentacao } from '../locais-apresentacao.model';

@Component({
    selector: 'app-form-locais-apresentacao',
    templateUrl: './form-locais-apresentacao.component.html',
})
export class FormLocaisApresentacaoComponent implements OnInit {

    loading: any = { main: false, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    orgaoId: string;
    localApresentacao = { endereco: {} } as LocaisApresentacao;
    states = Object.values(StatesEnum);

    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private locaisApresentacaoService: LocaisApresentacaoService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.orgaoId = this.activatedRoute.snapshot.params['idOrgao'];
        this.localApresentacao.id = this.activatedRoute.snapshot.params['idLocal'];
        this.title = this.localApresentacao.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Localidades', route: '/localidade', queryParams: { tab: 'orgao' } },
            { label: 'Visualizar orgão', route: `/localidade/orgao/${this.orgaoId}/visualizar`, queryParams: { tab: 'locais-apresentacao' } },
            { label: this.title + ' local de apresentação' },
        ]

        if (this.localApresentacao.id)
            this.getById();
    }

    save() {
        this.loading.save = true;
        let request: Observable<LocaisApresentacao>

        const localToSave = { ...this.localApresentacao }
        localToSave.orgao = this.orgaoId;
        
        request = localToSave.id
            ? this.locaisApresentacaoService.update<LocaisApresentacao>(localToSave.id, localToSave)
            : this.locaisApresentacaoService.create<LocaisApresentacao>(localToSave)
        
        request.subscribe({
            next: () => {
                this.snackBar.open('Local de apresentação salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate([`/localidade/orgao/${this.orgaoId}/visualizar`], { queryParams: { tab: 'locais-apresentacao' } });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.localApresentacao.nome, (dialogRef: MatDialogRef<any>) => {
            this.locaisApresentacaoService.remove<LocaisApresentacao>(this.localApresentacao.id).subscribe({
                next: () => {
                    this.snackBar.open('Local de apresentação apagado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate([`/localidade/orgao/${this.orgaoId}/visualizar`], { queryParams: { tab: 'locais-apresentacao' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    private getById() {
        this.loading.main = true;
        this.locaisApresentacaoService.getById<LocaisApresentacao>(this.localApresentacao.id).subscribe({
            next: (res) => {
                this.localApresentacao = res;
                this.localApresentacao.endereco = this.localApresentacao.endereco || {};
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }
}
