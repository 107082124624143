<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Contratos</h1>
                <button mat-icon-button color="primary" class="btn-sm ms-auto" (click)="toggleFilters = !toggleFilters">
                    <mat-icon>tune</mat-icon>
                </button>
                <button mat-flat-button color="primary" class="ms-3" [routerLink]="['novo']">NOVO CONTRATO</button>
            </div>

            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters">
                <div class="border-top px-1 py-3">
                    <div class="row align-items-end">
                        <div class="col-12 col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Nome/Código do contrato</mat-label>
                                <input matInput name="nome" #nome="ngModel" [(ngModel)]="filters.nome">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Beneficiaria/CNPJ</mat-label>
                                <input matInput name="beneficiaria" #beneficiaria="ngModel" [(ngModel)]="filters.beneficiaria">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-4">
                            <span class="fw-bold fs-7 opacity-50">DATA DE CRIAÇÃO</span>
                            <div class="d-flex">
                                <mat-form-field class="me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="startCreatedAt" name="inicio" #inicio="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.startCreatedAt">
                                    <mat-datepicker-toggle matIconSuffix [for]="startCreatedAt"></mat-datepicker-toggle>
                                    <mat-datepicker #startCreatedAt></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="endCreatedAt" name="fim" #fim="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.endCreatedAt">
                                    <mat-datepicker-toggle matIconSuffix [for]="endCreatedAt"></mat-datepicker-toggle>
                                    <mat-datepicker #endCreatedAt></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <span class="fw-bold fs-7 opacity-50">INICIO VIGENCIA</span>
                            <div class="d-flex">
                                <mat-form-field class="me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="startInicioVigencia" name="inicio" #inicio="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.startInicioVigencia">
                                    <mat-datepicker-toggle matIconSuffix [for]="startInicioVigencia"></mat-datepicker-toggle>
                                    <mat-datepicker #startInicioVigencia></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="endInicioVigencia" name="fim" #fim="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.endInicioVigencia">
                                    <mat-datepicker-toggle matIconSuffix [for]="endInicioVigencia"></mat-datepicker-toggle>
                                    <mat-datepicker #endInicioVigencia></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <span class="fw-bold fs-7 opacity-50">FIM VIGENCIA</span>
                            <div class="d-flex">
                                <mat-form-field class="me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="startFimVigencia" name="inicio" #inicio="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.startFimVigencia">
                                    <mat-datepicker-toggle matIconSuffix [for]="startFimVigencia"></mat-datepicker-toggle>
                                    <mat-datepicker #startFimVigencia></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="endFimVigencia" name="fim" #fim="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.endFimVigencia">
                                    <mat-datepicker-toggle matIconSuffix [for]="endFimVigencia"></mat-datepicker-toggle>
                                    <mat-datepicker #endFimVigencia></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 col-md-auto ms-auto">
                            <button mat-flat-button color="secondary" class="w-100" (click)="clean()">LIMPAR</button>
                        </div>
                        <div class="col-12 col-md-2">
                            <button mat-flat-button color="accent" class="w-100" (click)="search()">FILTRAR</button>
                        </div>
                    </div>
                </div>
            </ng-collapse>
            <generate-table 
                [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                [total]="pagination.total" [pageSize]="pagination.pageSize" 
                (pageEvent)="search($event)"
            ></generate-table>
        </mat-card-content>
    </mat-card>
</div>