<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-tab-group mat-stretch-tabs="false" [selectedIndex]="selectedTab">
            <mat-tab label="Categorias">
                <mat-card-content>
                    <equipamento-categoria></equipamento-categoria>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Tipos">
                <mat-card-content>
                    <equipamento-tipo></equipamento-tipo>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Grupos">
                <mat-card-content>
                    <equipamento-grupo></equipamento-grupo>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Equipamentos">
                <mat-card-content>
                    <equipamento-list></equipamento-list>
                </mat-card-content>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>
