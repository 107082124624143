import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { Funcionario, FuncionarioService, Orgao, OrgaoService, RegiaoAdmin,RegiaoAdminService } from '../..';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, catchError, concat, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { StatesEnum } from 'src/app/shared/models';
import { UsuarioResponse, UsuarioService } from 'src/app/usuario';
import { RolesEnum } from 'src/app/core';

export interface LoadingOrgao {
    main: boolean,
    save: boolean,
    funcionarios: boolean,
}

@Component({
    selector: 'app-form-orgao',
    templateUrl: './form-orgao.component.html'
})
export class FormOrgaoComponent implements OnInit {

    loading: LoadingOrgao = { main: true, save: false, funcionarios: false };
    breadcrumb: Breadcrumb[];

    title: string;
    orgao = { endereco: {}, imagem: [] } as Orgao;
    regioesAdministrativas: RegiaoAdmin[];
    states = Object.values(StatesEnum)
    showInputError = showInputError;

    funcionarios$: Observable<UsuarioResponse[]>;
    funcionariosInput$ = new Subject<string>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private regiaoAdminService: RegiaoAdminService,
        private orgaoService: OrgaoService,
        private usuarioService: UsuarioService,
        private funcionarioService: FuncionarioService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.orgao.id = this.activatedRoute.snapshot.params['idOrgao'];
        this.title = this.orgao.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Localidades', route: '/localidade', queryParams: { tab: 'orgao' } },
            { label: this.title + ' orgão' },
        ]
        
        this.pesquisarFuncionarios();

        if(this.orgao.id){
            this.popularFuncionarios(this.orgao.id);
        }

        this.regiaoAdminService.getAll<RegiaoAdmin>().subscribe({
            next: (res) => {
                this.regioesAdministrativas = res.data;
                if (this.orgao.id) 
                    this.getById();
                else 
                    this.loading.main = false;
            }, error: (err) => {
                this.loading.main = false;
                catchErr(err, this.snackBar)
            }
        });
    }

    compareWith(item: any, selected: any) {
        return selected && item == selected.id
    }

    filesOut($event: any) {
        this.orgao.imagem = $event
    }

    save() {
        this.loading.save = true;
        let request: Observable<Orgao>

        const orgaoToSave = { ...this.orgao }
        orgaoToSave.endereco = JSON.stringify(orgaoToSave.endereco);
        orgaoToSave.imagem = orgaoToSave.imagem ? orgaoToSave.imagem[0] : orgaoToSave.imagem
        if (orgaoToSave.id) {
            request = this.orgaoService.update<Orgao>(orgaoToSave.id, orgaoToSave)
        } else {        
            request = this.orgaoService.create<Orgao>(orgaoToSave)
        }

        request.subscribe({
            next: () => {
                this.snackBar.open('Orgão salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/localidade'], { queryParams: { tab: 'orgao' } });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.orgao.nome, (dialogRef: MatDialogRef<any>) => {
            this.orgaoService.remove<Orgao>(this.orgao.id).subscribe({
                next: () => {
                    this.snackBar.open('Orgão apagado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/localidade'], { queryParams: { tab: 'orgao' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    private getById() {
        this.loading.main = true;
        this.orgaoService.getById<Orgao>(this.orgao.id).subscribe({
            next: (res) => {
                this.orgao = res;
                this.orgao.imagem = this.orgao.imagem ? [this.orgao.imagem] : undefined;
                this.orgao.endereco = this.orgao.endereco || {};
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    private pesquisarFuncionarios() {
        this.funcionarios$ = concat(
            of([]), // default items
            this.funcionariosInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.loading.funcionarios = true),
                switchMap((term) => {
                    return term ?
                        this.usuarioService.getPaginated<UsuarioResponse>(0, 20, term).pipe(
                            map((res) => {
                              return res.data.filter((usuario) => (usuario.role === RolesEnum.FUNCIONARIO || usuario.role === RolesEnum.FISCAL));
                            }), // select by attr
                            catchError(() => of([])), // empty list on error
                            tap(() => this.loading.funcionarios = false)
                        ) : new Promise(() => this.loading.funcionarios = false) // not search all
                })
            )
        ) as Observable<UsuarioResponse[]>;
    }

    removeExecutorLocal(funcionario: any) {
        // this.beneficiariaService.removeResponsavel(responsavel.id).subscribe();
    }

    private popularFuncionarios(orgaoId: string){
        this.funcionarioService.getFuncionariosByOrgao<Funcionario>(1, 20, orgaoId).subscribe({
            next: (res) => {
                this.orgao.funcionarios = res.map((funcionario) => ({
                    ...funcionario,
                    nome: `${funcionario.firstName} ${funcionario.lastName}`,
                    email: funcionario.email,                    
                }));
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }


}
