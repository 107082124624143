import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCRUDService } from '../../shared/services';
import { AjusteFaturamento, CreateFaturamento, Faturamento, SimulacaoFaturamento } from '.././faturamento.model';

@Injectable({
    providedIn: 'root'
})
export class FaturamentoService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/faturamentos`;
    }

    simularFaturamento(idContrato: string, startDate: string, endDate: string, arrayAjuste: AjusteFaturamento[]) {
        const params = new URLSearchParams();
        params.append('startDate', `${startDate}`);
        params.append('endDate', `${endDate}`);
        return this.http.post<SimulacaoFaturamento>(`${this.url}/simulate/reajuste/${idContrato}?${params}`, arrayAjuste);
    }

    criarFaturamento(faturamento: CreateFaturamento) {
        return this.http.post(`${this.url}`, { ...faturamento });
    }

    reabrirFaturamento(faturamentoId: string, justificativa: string) {
        return this.http.patch(`${this.url}/${faturamentoId}/reabrir`, { justificativa });
    }

}
