import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { RolesEnum } from 'src/app/core';
import { GenericCRUDService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { Orgao } from 'src/app/localidade/orgao/orgao.model';
import { Pagination } from 'src/app/shared/models';

@Injectable({
    providedIn: 'root'
})
export class OrgaoService extends GenericCRUDService {

    orgaoUsuarioURL = `${environment.managerURL}/admin/funcionario`

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/orgao`;
    }

    override create<Orgao>(object: Orgao): Observable<Orgao> {
        const form = this.getFormData(object);
        return this.http.post<Orgao>(`${this.url}`, form);
    }

    override update<Orgao>(id: string, object: Orgao): Observable<Orgao> {
        const form = this.getFormData(object);
        return this.http.patch<Orgao>(`${this.url}/${id}`, form);
    }

    getByUsuario<Orgao>(usuarioId: string): Observable<Orgao[]> {        
        return this.http.get<Orgao[]>(`${this.orgaoUsuarioURL}/${usuarioId}/orgaos`);
    }

    override getPaginated<Orgao>(page: number, pageSize: number, nome?: string): Observable<Pagination<Orgao>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        nome && params.append('nome', `${nome}`);

        console.log('paramns: ', params);

        return this.http.get<Pagination<Orgao>>(`${this.url}?${params}`);
    }

    private getFormData(object: any) {
      const formData: FormData = new FormData();
      for (const key in object) {
          if (Array.isArray(object[key])) {
              for (const imgKey in object[key]) {
                  formData.append(key, object[key][imgKey]);
              }
          } else {
              formData.append(key, object[key]);
          }
      }
      return formData;
    }
}
