import { Contrato } from "../contrato";

export interface CreateFaturamento {
    contratoId: string
    dataBaseInicio: string
    dataBaseFim: string
    valorAgregadoPreCorte: number
    valorTotalCortes: number
    dios?: string[]
}

export interface Faturamento {
    id: string;
    contrato: Contrato
    status: string
    contratoId: string
    dataBaseInicio: string
    dataBaseFim: string
    equipamentos: FaturamentoEquipamento[]
    total: FaturamentoTotal
}

export interface FaturamentoEquipamento {
    alocacaoCodigo: string;
    custo: number | string | any;
    dios: number;
    equipamentoId: string;
    equipamentoPlaca: string;
    equipamentos: number;
    totalHorasDisponiveisEmMinutos: number | string | any;
    totalHorasFuncionamentoEmMinutos: number | string | any;
    totalKmRodados: number | string | any;

    diferenca?: number;
    valorReajustado?: number;
}

export interface FaturamentoTotal {
    custo: number;
    dios: number;
    equipamentos: number;
    totalHorasDisponiveisEmMinutos: number;
    totalHorasFuncionamentoEmMinutos: number;
    totalKmRodados: number;

    diferenca?: number;
    valorReajustado?: number;
}

export interface AjusteFaturamento {
    valor: number;
    dio: string;
}

export interface SimulacaoFaturamento {
    dios: SimulacaoValoresDios[];
    equipamentos: SimulacaoValoresEquip[];
    total: SimulacaoValores;
    errors: any[];
}

interface SimulacaoValores {
    diferenca: number;
    valorBase: number;
    valorReajustado: number;
}

interface SimulacaoValoresDios extends SimulacaoValores {
    dio: string
}

interface SimulacaoValoresEquip extends SimulacaoValores {
    equipamentoId: string
}