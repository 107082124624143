import { Component, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTableColumn } from '../shared/components';
import { Router } from '@angular/router';
import { Config } from './config.model';
import { ConfigService } from './config.service';
import { catchErr } from '../shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit {

    loading = true;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Configurações' },
    ]

    dataSource: Config[];
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        { header: 'Valor', attr: 'valor' },
        { header: 'Tipo', attr: 'tipo' },
        { header: 'Descrição', attr: 'descricao' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
                action: (rowData: Config) => this.router.navigateByUrl(`/configuracoes/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private configService: ConfigService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.configService.findAll<Config>().subscribe({
            next: (res) => this.dataSource = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
