import { Component, OnInit } from '@angular/core';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination, RemoveDialogService } from 'src/app/shared/components';
import { FormFuncionarioComponent, FuncionarioService, Funcionario } from '../../..';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'funcionario',
    templateUrl: './funcionario.component.html'
})
export class FuncionarioComponent implements OnInit {

    loading: boolean;
    dataSource: Funcionario[];
    orgaoId: string;

    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        {
            tdClass: 'td-w-auto',
            template: (rowData: Funcionario) => `<img height="40" width="40" class="img-cover rounded-circle bg-default" src="${rowData.avatarUrl || '/assets/img/default_user.jpeg'}" />`
        },
        { header: 'Nome', attr: 'nome' },
        { header: 'Email', attr: 'email' },
        { header: 'Papel', attr: 'role' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Apagar', color: 'warn' },
            click: { action: (rowData: Funcionario) => this.showRemoveDialog(rowData) }
        }
    ]

    constructor(
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private funcionarioService: FuncionarioService,
        private removeDialogService: RemoveDialogService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.orgaoId = this.activatedRoute.snapshot.params['idOrgao'];
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.funcionarioService.getFuncionariosByOrgao<Funcionario>(this.pagination.current, this.pagination.pageSize, this.orgaoId).subscribe({
            next: (res) => {
                this.dataSource = res.map((funcionario) => ({
                    ...funcionario,
                    nome: `${funcionario.firstName} ${funcionario.lastName}`,
                    email: funcionario.email,                    
                }));
                //this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    showFormDialog() {
        const dialogRef = this.dialog.open(FormFuncionarioComponent, { data: { orgaoId: this.orgaoId } });
        dialogRef.afterClosed().subscribe((reload) => !!reload && this.search());
    }

    showRemoveDialog(funcionario: Funcionario) {
        this.removeDialogService.show(funcionario.nome, (dialogRef: MatDialogRef<any>) => {
            this.funcionarioService.remove<Funcionario>(funcionario.id).subscribe({
                next: () => {
                    this.snackBar.open('Funcionário apagado com sucesso!', '✕', { panelClass: ['success'] });
                    dialogRef.close();
                    this.search();
                }, error: (err) => catchErr(err, this.snackBar)
            });
        });
    }
}
