import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { Alocacao, Contrato, ContratoService } from 'src/app/contrato';
import { Beneficiaria, BeneficiariaService } from 'src/app/beneficiaria';
import { catchErr } from 'src/app/shared/utils';
import * as dayjs from 'dayjs';
import { RelatorioService } from '../relatorio.service';
import { Dio } from 'src/app/dio/dio.model';
import { Equipamento } from 'src/app/equipamento';
import { GenericRelatorioComponent } from '../generic-relatorio.component';
import { dataAtualString, primeiroDiaDoMesString } from 'src/app/shared/date-utils';

@Component({
    selector: 'app-dio-nao-executado',
    templateUrl: './dio-nao-executado.component.html'
})
export class DioNaoExecutadoComponent extends GenericRelatorioComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios', route: '/relatorio' },
        { label: 'DIO\'s Não Executados' },
    ]

    dataSource: any[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
    columns: GenerateTableColumn[] = [
        { header: 'N° DIO', attr: 'numeroDio' },
        { header: 'Contrato', attr: 'contratoNome' },
        { header: 'Prefixo', attr: 'prefixo' },
        { header: 'Placa/Série', attr: 'placa' },
        { header: 'Data Operação', attr: 'dataPeriodoInicioOperacao' },
    ];

    constructor(
        private relatorioService: RelatorioService,
        private beneficiariaService: BeneficiariaService,
        protected override contratoService: ContratoService,
        protected override snackBar: MatSnackBar,
    ) {
        super(contratoService, snackBar);
    }

    ngOnInit() {
        this.beneficiariaService.getAll<Beneficiaria>().subscribe({
            next: (res) => this.beneficiarias = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);

        this.filters.dataInicio = primeiroDiaDoMesString();
        this.filters.dataFim = dataAtualString();

        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        const filters = { ...this.filters };
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.getRelatorioDiosNaoExecutadas(filters).subscribe({
            next: (res: any) => {
                if (res.report.data) {
                    this.dataSource = res.report.data.map((item: any) => this.buildResponse(item));
                }
            }
        }).add(() => this.loading = false);
    }

    setBeneficiaria(beneficiaria: Beneficiaria) {
        this.buscaContratos(beneficiaria.id);
    }

    buildResponse(item: any) {
        let dataOperacao = '---';

        if (item.dataPeriodoInicioOperacao && item.dataPeriodoFimOperacao) {
            const dataInicioOperacao = dayjs(item.dataPeriodoInicioOperacao).format('DD/MM/YYYY');
            const dataFimOperacao = dayjs(item.dataPeriodoFimOperacao).format('DD/MM/YYYY');
            dataOperacao = 'De ' + dataInicioOperacao + " até " + dataFimOperacao;
        }

        return {
            numeroDio: item.numeroDio,
            contratoNome: item.contratoNome,
            alocacaoCode: item.alocacaoCode,
            equipamentoNome: item.equipamentoNome,
            placa: item.placa,
            beneficiaria: item.beneficiaria,
            prefixo: item.prefixo,
            dataPeriodoInicioOperacao: item.dataPeriodoInicioOperacao ? dayjs(item.dataPeriodoInicioOperacao).format('DD/MM/YYYY HH:mm:ss') : '',
            dataLimiteExecucao: dayjs(item.dataLimiteExecucao).format('DD/MM/YYYY HH:mm:ss'),
            created_at: dayjs(item.created_at).format('DD/MM/YYYY HH:mm:ss'),
        }
    }

    export(type: string = 'csv') {
        this.loading = true;

        const filters = { ...this.filters, removePaingation: true };
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.downloadArquivoCsvOrPdf(filters, 'dios-nao-executados', type).subscribe({
            next: (res: any) => {
                const url = window.URL.createObjectURL(res.body as Blob);
                const startReport = filters.dataInicio ? dayjs(filters.dataInicio).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const endReport = filters.dataFim ? dayjs(filters.dataFim).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const fileName = `Dios_nao_executados_${startReport}_${endReport}`
                const anchor = document.createElement("a");
                anchor.download = `${fileName}.${type}`;
                anchor.href = url;
                anchor.click();
                this.toggleFilters = false;
                this.loading = false;
            }, error: (err) => console.log('err', err)
        });
    }

}
