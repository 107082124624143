<div class="carousel">
    <ng-container *ngFor="let slide of slides; let i = index">
        <img *ngIf="i === currentSlide" style="max-height: 100%" [src]="slide.src" [alt]="slide.description" class="slide" />
    </ng-container>

    <button class="control prev" (click)="onPreviousClick()">
        <span class="arrow left"></span>
    </button>
    <button class="control next" (click)="onNextClick()">
        <span class="arrow right"></span>
    </button>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close color="primary">X</button>
    </mat-dialog-actions>
</div>