import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UsuarioRegister } from 'src/app/usuario';

@Component({
    selector: 'register',
    templateUrl: './register.component.html'
})
export class RegisterComponent {

    loading: boolean;
    showInputError = showInputError;

    constructor(
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    checkPassword(password: string, confirmPassword: string) {
        return password && confirmPassword && password != confirmPassword
    }

    register(form: NgForm) {
        this.loading = true;
        const usuario: UsuarioRegister = form.form.value;
        usuario.username = usuario.email || '';
        this.authService.register(usuario).subscribe({
            next: () => {
                this.router.navigateByUrl('/sign-in');
                this.snackBar.open('Usuário criado! Favor verificar o email cadastrado!', '✕', { panelClass: ['success'] });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

}
