import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Data, Pagination } from 'src/app/shared/models';
import { GenericCRUDService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class TarifaService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/tarifacao`;
    }

    override getAll<Tarifa>(contratoId?: string): Observable<Data<Tarifa>> {
        const params = new URLSearchParams();
        params.append('removePagination', 'true');
        contratoId && params.append('contratoId', `${contratoId}`);
        return this.http.get<Data<Tarifa>>(`${this.url}?${params}`);
    }

    getByContractId<Tarifa>(page: number, pageSize: number, contratoId?: string): Observable<Pagination<Tarifa>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);

        if (contratoId) {
            params.append('contratoId', `${contratoId}`);
        }

        return this.http.get<Pagination<Tarifa>>(`${this.url}?${params}`);
    }
}
