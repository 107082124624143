import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core';
import { Breadcrumb } from 'src/app/shared/components';
import { catchErr, getBadgeColor } from 'src/app/shared/utils';
import { AlocacaoService } from '../alocacao.service';
import { Alocacao } from '../alocacao.model';

@Component({
    selector: 'view-alocacao',
    templateUrl: './view-alocacao.component.html',
})
export class ViewAlocacaoComponent {

    loading = true;
    idAlocacao: string;
    idContrato: string;

    alocacao = {} as Alocacao;

    getBadgeColor = getBadgeColor;
    breadcrumb: Breadcrumb[];
    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private alocacaoService: AlocacaoService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
      this.idAlocacao = this.activatedRoute.snapshot.params['idAlocacao'];
      this.idContrato = this.activatedRoute.snapshot.params['idContrato'];
      this.breadcrumb = [
        { label: 'Início' },
        { label: 'Contratos', route: '/dio' },
        { label: 'Visualizar contrato', route: `/contrato/${this.idContrato}/visualizar`,queryParams: { tab: 'alocacao' } },
        { label: 'Alocação' },
      ]
      this.getAlocacao();
    }

    private getAlocacao() {
        this.loading = true;
        this.alocacaoService.getById<Alocacao>(this.idAlocacao).subscribe({
            next: (res) => {
                this.alocacao = res;
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}