import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CondutorModule } from '../condutor/condutor.module';
import { 
    BeneficiariaComponent,
    BeneficiariaService,
    ViewBeneficiariaComponent,
    FormBeneficiariaComponent,
    DetailBeneficiariaComponent
} from '.';

@NgModule({
    declarations: [
        BeneficiariaComponent,
        ViewBeneficiariaComponent,
        FormBeneficiariaComponent,
        DetailBeneficiariaComponent,
    ],
    imports: [
        SharedModule,
        CondutorModule
    ],
    providers: [
        BeneficiariaService,
    ]
})
export class BeneficiariaModule { }
