import { Component, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { RelatorioConsumo, RelatorioFilter } from '../relatorio.model';
import { Alocacao, Contrato, ContratoService } from 'src/app/contrato';
import { Beneficiaria, BeneficiariaService } from 'src/app/beneficiaria';
import { GenericRelatorioComponent } from '../generic-relatorio.component';
import { catchErr } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RelatorioService } from '../relatorio.service';
import * as dayjs from 'dayjs';
import { DioDetalhesExecucao } from 'src/app/dio/dio.model';
import { Equipamento } from 'src/app/equipamento';
import { CurrencyPipe } from '@angular/common';
import { dataAtualString, primeiroDiaDoMesString } from 'src/app/shared/date-utils';

@Component({
    selector: 'app-relatorio-consumo-por-equipamento',
    templateUrl: './relatorio-consumo-por-equipamento.component.html',
    providers: [CurrencyPipe],
})
export class RelatorioConsumoPorEquipamentoComponent extends GenericRelatorioComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios', route: '/relatorio' },
        { label: 'Consumo por Equipamento' },
    ]

    beneficiaria: string | undefined;
    contrato: string | undefined;
    dataInicioPesquisada: string | null;
    dataFimPesquisada: string | null;

    dataSource: any[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
    columns: GenerateTableColumn[] = [
        { header: 'Contrato', attr: 'contrato' },
        { header: 'Prefixo', attr: 'prefixo' },
        { header: 'Placa/Série', attr: 'placa' },
        { header: 'Grupo', attr: 'grupo' },
        { header: 'N Dio', attr: 'numeroDio' },
        { header: 'Data Operação', attr: 'dataInicioExecucao' },
        { header: 'KM Real', attr: 'kmReal' },
        { header: 'Valor KM', attr: 'valorKm' },
        { header: 'Valor Devido', attr: 'valorDevido' },
    ];

    constructor(
        private relatorioService: RelatorioService,
        private beneficiariaService: BeneficiariaService,
        protected override contratoService: ContratoService,
        protected override snackBar: MatSnackBar,
        private readonly currencyPipe: CurrencyPipe,
    ) {
        super(contratoService, snackBar);
    }

    ngOnInit() {
        this.beneficiariaService.getAll<Beneficiaria>().subscribe({
            next: (res) => this.beneficiarias = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);

        this.toggleFilters = true;
        this.filters.dataInicio = primeiroDiaDoMesString();
        this.filters.dataFim = dataAtualString();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        const filters = { ...this.filters };

        if (!filters.beneficiaria) {
            this.snackBar.open('Informe a beneficiaria', '✕', { panelClass: ['error'] });
            return;
        }

        if (!filters.contrato) {
            this.snackBar.open('Informe o contrato', '✕', { panelClass: ['error'] });
            return;
        }

        this.loading = true;
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.getRelatorioConsumoPorEquipamento(filters).subscribe({
            next: (res: any) => {
                if (res.report.data) {
                    this.dataSource = res.report.data.map((item: any) => this.buildResponse(item));
                }

                this.dataInicioPesquisada = this.filters.dataInicio ? dayjs(this.filters.dataInicio).format('DD/MM/YYYY') : null;
                this.dataFimPesquisada = this.filters.dataFim ? dayjs(this.filters.dataFim).format('DD/MM/YYYY') : null;
            }
        }).add(() => {
            this.loading = false;
            this.toggleFilters = false;
        });
    }

    setBeneficiaria(beneficiaria: Beneficiaria) {
        this.buscaContratos(beneficiaria.id);
    }

    buildResponse(data: RelatorioConsumo) {
        this.contrato = data.contrato;
        this.beneficiaria = data.beneficiaria;

        return {
            contrato: data.contrato,
            prefixo: data.prefixo,
            placa: data.placa,
            grupo: data.grupo,
            numeroDio: data.numeroDio,
            dataInicioExecucao: data.dataInicioExecucao ? dayjs(data.dataInicioExecucao).format('DD/MM/YYYY') : '---',
            kmReal: data.kmReal + 'km',
            valorKm: this.currencyPipe.transform(data.valorKm, 'BRL') || data.valorKm,
            saldo: this.currencyPipe.transform(data.saldo, 'BRL') || data.saldo,
            excesso: this.currencyPipe.transform(data.excesso, 'BRL') || data.excesso,
            valorDevido: this.currencyPipe.transform(data.valorDevido, 'BRL') || data.valorDevido,
        }
    }

    export(type: string = 'csv') {
        this.loading = true;

        const filters = { ...this.filters, removePaingation: true };
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.downloadArquivoCsvOrPdf(filters, 'consumo-por-equipamento', type).subscribe({
            next: (res: any) => {
                const url = window.URL.createObjectURL(res.body as Blob);
                const startReport = filters.dataInicio ? dayjs(filters.dataInicio).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const endReport = filters.dataFim ? dayjs(filters.dataFim).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const fileName = `Consumo_por_equipamento_${startReport}_${endReport}`
                const anchor = document.createElement("a");
                anchor.download = `${fileName}.${type}`;
                anchor.href = url;
                anchor.click();
                this.toggleFilters = false;
                this.loading = false;
            }, error: (err) => console.log('err', err)
        });
    }


}

