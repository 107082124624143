import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { DioService } from '../../dio.service';
import { Dio } from '../../dio.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-cancelar-dio',
    templateUrl: './cancelar-dio.component.html',
})
export class CancelarDioComponent {

    loading: boolean;
    showInputError = showInputError;
    motivo: string;
    dio: Dio

    constructor(
        private dialogRef: MatDialogRef<CancelarDioComponent>,
        private dioService: DioService,
        private snackBar: MatSnackBar,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: { dio: Dio },
    ) {
      this.dio = data.dio;
    }

    cancelar() {
        this.loading = true;
        if(!this.data.dio && !this.dio.id) {
          return;
        }

        const statusExecucaoAnterior = this.data.dio.statusExecucao;        

        this.dioService.cancelDio(this.dio.id || '', 'DIO_NAO_ACOLHIDO', this.motivo).subscribe({
            next: () => {
                this.snackBar.open('Cancelamento realizado com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);

                statusExecucaoAnterior === 'AGUARDANDO_FUNCIONARIO' ? this.router.navigateByUrl(`/pendencias/atesto`) : this.router.navigateByUrl(`/pendencias/validacao`);

            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
