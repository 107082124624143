<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} usuário</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Nome</mat-label>
                    <input matInput name="firstName" #firstName="ngModel" [(ngModel)]="usuario.firstName" required>
                    <mat-error *ngIf="showInputError(firstName, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- SOBRENOME -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Sobrenome</mat-label>
                    <input matInput name="lastName" #lastName="ngModel" [(ngModel)]="usuario.lastName" required>
                    <mat-error *ngIf="showInputError(lastName, 'required')">Sobreome é obrigatório</mat-error>
                </mat-form-field>

                <!-- EMAIL -->
                <mat-form-field class="col-12 col-md-6" [ngClass]="{'disabled': usuario.id}">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" name="email" #email="ngModel" [(ngModel)]="usuario.email" [readonly]="usuario.id" required>
                    <mat-error *ngIf="showInputError(email, 'required')">Email é obrigatório</mat-error>
                </mat-form-field>

                <!-- ROLE -->
                <div class="col-12 col-md-6">
                    <ng-select name="role" [disabled]="currentRole === 'CONTRATADO_ADM'" [items]="rolesInfo" bindValue="value" placeholder="Perfil" required
                        [(ngModel)]="usuario.role" (change)="roleSelected($event.value)" [compareWith]="compareWith">
                        <!-- selected -->
                        <ng-template ng-label-tmp let-item="item">{{ item.value | translate }}</ng-template>
                        <!-- lista -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="d-flex flex-column py-2">
                                <span class="lh-sm">{{ item.value | translate }}</span>
                                <div class="fs-7 opacity-50">{{ item.description }}</div>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- ORGAO (PARA FUNCIONARIO) -->
                <div class="col-12 col-md-6" *ngIf="usuario.role === 'FUNCIONARIO'">
                    <ng-select name="orgao" [items]="orgaos" bindValue="id" placeholder="Orgão" [searchFn]="searchFn"
                        [(ngModel)]="usuario.orgao" [compareWith]="compareWith" (change)="setOrgao($event)"
                        (clear)="setOrgao()">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                            {{ item.nome }}
                        </ng-template>
                    </ng-select>                
                </div>
                

                <!-- PHONE -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Telefone</mat-label>
                    <input matInput name="telefone" #telefone="ngModel" [(ngModel)]="usuario.telefone" mask="(00) 00000-0000">
                </mat-form-field>

                <!-- CARGO -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Cargo</mat-label>
                    <input matInput name="cargo" #cargo="ngModel" [(ngModel)]="usuario.cargo">
                </mat-form-field>

                <!-- DESCRIPTION -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="usuario.descricao"></textarea>
                </mat-form-field>

                <mat-slide-toggle class="col-12 col-md-auto ps-3" name="enabled" [(ngModel)]="usuario.enabled" *ngIf="usuario.id">
                    <span class="ms-1">ATIVO</span>
                </mat-slide-toggle>

                <mat-slide-toggle *ngIf="aferidorUser && usuario.role == 'AFERIDOR' && usuario.id" class="col-12 col-md-auto ps-3" name="enabledForDio" (click)="enableOrDisableAferidor()" [(ngModel)]="aferidorUser.ativo">
                  <span class="ms-1">ATIVO PARA AFERIR DIOS</span>
                </mat-slide-toggle> 

                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" (click)="voltar()">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
