import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { 
    EquipamentoCategoriaComponent,
    EquipamentoCategoriaService,
    EquipamentoListComponent,
    EquipamentoService,
    EquipamentoGrupoComponent,
    EquipamentoGrupoService,
    EquipamentoTipoComponent,
    EquipamentoTipoService,
    FormEquipamentoCategoriaComponent,
    FormEquipamentoComponent,
    FormEquipamentoGrupoComponent,
    FormEquipamentoTipoComponent,
    EquipamentoComponent,
    ViewEquipamentoComponent,
} from '.';

@NgModule({
    declarations: [
        EquipamentoCategoriaComponent,
        EquipamentoListComponent,
        EquipamentoGrupoComponent,
        EquipamentoComponent,
        EquipamentoTipoComponent,
        FormEquipamentoCategoriaComponent,
        FormEquipamentoComponent,
        FormEquipamentoGrupoComponent,
        FormEquipamentoTipoComponent,
        ViewEquipamentoComponent
    ],
    imports: [SharedModule],
    providers: [
        EquipamentoCategoriaService,
        EquipamentoGrupoService,
        EquipamentoService,
        EquipamentoTipoService,
    ]
})
export class EquipamentoModule { }
