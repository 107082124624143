<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card class="p-4">
        <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>
        <div class="row" *ngIf="!loading">
            <div class="col-12 d-flex align-items-center justify-content-between mb-2">
                <h1 class="fs-3 fw-light mb-0">ALOCAÇÃO / <strong>{{ alocacao.codigo }}</strong></h1>
                <button mat-flat-button color="primary" [routerLink]="['/contrato', idContrato, 'alocacao', idAlocacao, 'editar']">EDITAR ALOCAÇÃO</button>
            </div>
            <div class="col-12 mb-2">
                <mat-divider></mat-divider>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">PREFIXO</span>
                <div class="lh-sm">{{ alocacao.codigo }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">PLACA/SÉRIE DO EQUIPAMENTO</span>
                <div class="lh-sm">{{ alocacao.equipamento.placa || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">TIPO DE VEÍCULO</span>
                <div class="lh-sm">{{ alocacao.grupoTarifacaoTipo || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">INICIO VIGÊNCIA</span>
                <div class="lh-sm">{{ alocacao.inicioVigencia | date:'dd/MM/yyyy HH:mm:ss' || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">FIM VIGÊNCIA</span>
                <div class="lh-sm">{{ alocacao.fimVigencia | date:'dd/MM/yyyy HH:mm:ss' || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">CRIADO EM</span>
                <div class="lh-sm">{{ alocacao.created_at | date:'dd/MM/yyyy HH:mm:ss' || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">VALOR HORA DISPONÍVEL MÁQUINA</span>
                <div class="lh-sm">{{ alocacao.valorHoraDisponivelMaquina | currency:'BRL' || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">VALOR HORA DISPONÍVEL VEÍCULO</span>
                <div class="lh-sm">{{ alocacao.valorHoraDisponivelVeiculo | currency:'BRL' || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">VALOR HORA PRODUZIDA</span>
                <div class="lh-sm">{{ alocacao.valorHoraProduzida | currency:'BRL' || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">VALOR POR KM RODADOS</span>
                <div class="lh-sm">{{ alocacao.valorKmRodado | currency:'BRL' || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">USUÁRIO EMAIL</span>
                <div class="lh-sm">{{ alocacao.usuarioEmail || '---' }}</div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <span class="fw-bold fs-7 opacity-50">USUÁRIO ID</span>
                <div class="lh-sm">{{ alocacao.usuarioId || '---' }}</div>
            </div>
            <div class="col-12 col-md-4">
                <span class="fw-bold fs-7 opacity-50">CONTRATO</span>
                <div class="lh-sm">{{ alocacao.contrato.nome || '---' }}</div>
                <div class="lh-sm fs-7 text-gray" *ngIf="alocacao.contrato.beneficiaria">{{ alocacao.contrato.beneficiaria.nome }}</div>
            </div>
        </div>
    </mat-card>
</div>