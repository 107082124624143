<h1 mat-dialog-title>Nova Anotação</h1>
<div mat-dialog-content class="overflow-visible px-3 pb-0">
    <mat-form-field class="col-12 col-md-12">
        <mat-label>Anotação</mat-label>
        <textarea type="textarea" rows="5" matInput name="anotacao" #anotacao.anotacao="ngModel" [(ngModel)]="anotacao.anotacao"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="px-3 pb-3 justify-content-end">
    <button mat-button mat-dialog-close class="px-3">Fechar</button>
    <button mat-flat-button color="primary" (click)="save()" [ngClass]="{'btn-dialog-spinner': loading}" [disabled]="!anotacao.anotacao || loading">
        <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">Salvar</span>
    </button>
</div>
