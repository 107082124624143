import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ServicoComponent, FormServicoComponent } from './';

@NgModule({
    declarations: [
        ServicoComponent,
        FormServicoComponent
    ],
    imports: [SharedModule],
    providers: []
})
export class ServicoModule { }
