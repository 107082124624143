import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface Slide {
    src: string;
    alt: string;
    description: string;
}

@Component({
    selector: "carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: ["./carousel.component.css"],
})
export class CarouselComponent {

    slides: Slide[] = [];
    currentSlide = 0;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Slide[]) {
        this.slides = data;
    }

    onPreviousClick() {
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    }

    onNextClick() {
        const next = this.currentSlide + 1;
        this.currentSlide = next === this.slides.length ? 0 : next;
    }
}