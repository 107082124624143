<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Relatório de Aferições</h1>
                <button mat-icon-button color="primary" class="d-print-none btn-sm ms-auto" (click)="toggleFilters = !toggleFilters">
                    <mat-icon>tune</mat-icon>
                </button>            
            </div>

            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters" >
                <div class="border-top px-1 py-3">
                    <div class="row align-items-end">
                        <div class="col-12 col-md-6">
                            <ng-select name="beneficiaria" [items]="beneficiarias" bindValue="id" placeholder="Beneficiaria" 
                                [searchFn]="searchFn" [(ngModel)]="filters.beneficiaria" [compareWith]="compareWith"
                                (change)="setBeneficiaria($event)">
                                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                                    {{ item.nome}}
                                </ng-template>
                            </ng-select>                        
                        </div>
                        <div class="col-12 col-md-6">
                            <ng-select name="contrato" [items]="contratos" bindValue="id" bindLabel="nome" placeholder="Contrato"
                                [searchFn]="searchFn" [(ngModel)]="filters.contrato" [compareWith]="compareWith">
                            </ng-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="d-flex">
                                <mat-form-field class="flex-fill me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="dataInicio" name="inicio" #inicio="ngModel"
                                        autocomplete="off" [(ngModel)]="filters.dataInicio">
                                    <mat-datepicker-toggle matIconSuffix [for]="dataInicio"></mat-datepicker-toggle>
                                    <mat-datepicker #dataInicio></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field class="flex-fill">
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="dataFim" name="fim" #fim="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.dataFim">
                                    <mat-datepicker-toggle matIconSuffix [for]="dataFim"></mat-datepicker-toggle>
                                    <mat-datepicker #dataFim></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            
                        </div>
                        <div class="col-12 col-md-2 mx-auto">
                            <button mat-flat-button color="accent" class="w-100" (click)="search()">FILTRAR</button>
                        </div>
                    </div>
                </div>
            </ng-collapse>
                  
            <ng-container *ngIf="totals">
                <div class="col-12 mb-3">
                    <mat-divider></mat-divider>
                </div>
                <h2 class="fs-6 mb-1">Aferições</h2>
                <div class="col-12 col-md-4 mb-3">
                    <span class="fw-bold fs-7 opacity-50">PENDENTES DE AFERIÇÃO</span>
                    <div class="lh-sm">{{ totals.totalPendentesAfericao }}</div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <div class="col-12 col-md-4 mb-3">
                    <span class="fw-bold fs-7 opacity-50">AFERIDAS</span>
                    <div class="lh-sm">{{ totals.totalAferidas }}</div>
                </div>
                <div class="col-12 col-md-4 mb-3"></div>
                    <span class="fw-bold fs-7 opacity-50">TOTAL</span>
                    <div class="lh-sm">{{ totals.totalPendentesAfericao + totals.totalAferidas }}</div>
                </div>        
                <div class="col-12 col-md-4 mb-3">
                </div>
                <div class="col-12 col-md-4 mb-3">
                </div>
            </ng-container>

            <div style="display: block; width: 600px; height: 400px;">
                <canvas id="myBarChart"></canvas>
            </div>              
        </mat-card-content>
    </mat-card>
</div>
