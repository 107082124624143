import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from 'src/app/shared/components';

@Component({
    selector: 'app-view-orgao',
    templateUrl: './view-orgao.component.html'
})
export class ViewOrgaoComponent implements OnInit {

    selectedTab: number;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Localidades', route: '/localidade', queryParams: { tab: 'orgao' } },
        { label: 'Visualizar orgão' },
    ];

    constructor(private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.selectedTab = this.getTab(this.activatedRoute.snapshot.queryParams['tab'])
    }

    private getTab(tabName: string) {
        switch (tabName) {
            case 'funcionario': return 1;
            case 'locais-apresentacao': return 2;
            default: return 0;
        }
    }
}
