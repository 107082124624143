import { Component, Inject, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTablePageEvent, GenerateTablePagination } from '../shared/components';
import { Demandante, Dio, DioFilters, StatusAfericaoEnum, StatusAuditoriaDIO, StatusExecucaoDIO, statusAuditoria, statusExecucao } from './dio.model';
import { DioService } from './dio.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, getBadgeColor } from '../shared/utils';
import { Contrato } from '../contrato/contrato.model';
import { Servico } from 'src/app/servico';
import { Alocacao } from '../contrato/view-contrato/alocacao/alocacao.model';
import { Condutor, CondutorService } from 'src/app/condutor';
import { Beneficiaria, BeneficiariaService } from 'src/app/beneficiaria';
import { forkJoin, of } from 'rxjs';
import { Orgao, OrgaoService } from 'src/app/localidade';
import { DemandanteService } from './demandante.service';
import { Equipamento, EquipamentoService } from 'src/app/equipamento';
import { isEmpty, mapValues, pickBy } from 'lodash-es';
import { AuthService, RolesEnum } from 'src/app/core';
import { ContratoService } from 'src/app/contrato';
import { NgxMaskPipe } from 'ngx-mask';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UsuarioResponse } from '../usuario';
import * as dayjs from 'dayjs';

@Component({
    selector: 'dio',
    templateUrl: './dio.component.html',
    providers: [NgxMaskPipe]
})
export class DioComponent implements OnInit {

    loading = { main: true, filter: false };
    toggleFilters: boolean;
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Diários de Operação' },
    ]

    dataSource: any[];
    filters = {} as DioFilters | any;

    condutores: Condutor[];
    contratos: Contrato[];
    demandantes: Demandante[];
    equipamentos: Equipamento[];
    orgaos: Orgao[];

    orgao: Orgao;
    user: UsuarioResponse;
    currentRole: string;
    currentBeneficiaria = {} as Beneficiaria;
    currentStatusExecucao: string;

    titlePage: string;
    showFields = { status: true, BenConEqui: true }

    statusExecucao = statusExecucao;
    getBadgeColor = getBadgeColor;

    constructor(
        private authService: AuthService,
        private condutorService: CondutorService,
        private contratoService: ContratoService,
        private demandanteService: DemandanteService,
        private dioService: DioService,
        private beneficiariaService: BeneficiariaService,
        private equipamentoService: EquipamentoService,
        private orgaoService: OrgaoService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.currentStatusExecucao = params['statusExecucao'];
            this.showFields.status = !this.currentStatusExecucao;
            this.titlePage =
                this.currentStatusExecucao === 'atesto' ? 'Diários de Operação - Pendentes de Atesto' :
                this.currentStatusExecucao === 'validacao' ? 'Diários de Operação - Pendentes de Validação' :
                this.currentStatusExecucao === 'afericao' ? 'Diários de Operação - Pendentes de Aferição' :
                this.currentStatusExecucao === 'afericao-aprovacao' ? 'Diários de Operação - Pendentes de Aprovação' :
                'Diários de Operação';

            this.currentStatusExecucao && this.search();
        });

        this.user = this.authService.user;
        this.currentRole = this.authService.user.realm_access ? this.authService.user.realm_access.roles[0] : '';
        this.getQueryParam();

        if (this.currentRole === RolesEnum.CONTRATADO_ADM) {
            this.beneficiariaService.getBeneficiariaByUserVirtualId(this.authService.user.sub).subscribe((beneficiaria) => {
                this.filters.beneficiaria = beneficiaria.data[0].id;
                this.currentBeneficiaria = beneficiaria.data[0];
                this.getAllResources(beneficiaria.data[0].id);
                this.search();
            });
        } else {
            this.getAllResources();
            this.search();
        }
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading.main = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        const filters = { ...this.filters };

        filters.startCreatedAt = filters.startCreatedAt ? `${dayjs(filters.startCreatedAt).format('YYYY-MM-DD')}T00:00:00` : filters.startCreatedAt;
        filters.endCreatedAt = filters.endCreatedAt ? `${dayjs(filters.endCreatedAt).format('YYYY-MM-DD')}T23:59:59` : filters.endCreatedAt;
        filters.startDataInicioExecucao = filters.startDataInicioExecucao ? `${dayjs(filters.startDataInicioExecucao).format('YYYY-MM-DD')}T00:00:00` : filters.startDataInicioExecucao;
        filters.endDataInicioExecucao = filters.endDataInicioExecucao ? `${dayjs(filters.endDataInicioExecucao).format('YYYY-MM-DD')}T23:59:59` : filters.endDataInicioExecucao;
        filters.startDataFimExecucao = filters.startDataFimExecucao ? `${dayjs(filters.startDataFimExecucao).format('YYYY-MM-DD')}T00:00:00` : filters.startDataFimExecucao;
        filters.endDataFimExecucao = filters.endDataFimExecucao ? `${dayjs(filters.endDataFimExecucao).format('YYYY-MM-DD')}T23:59:59` : filters.endDataFimExecucao;
        filters.startDateLimite = filters.startDateLimite ? `${dayjs(filters.startDateLimite).format('YYYY-MM-DD')}T00:00:00` : filters.startDateLimite;
        filters.endDateLimite = filters.endDateLimite ? `${dayjs(filters.endDateLimite).format('YYYY-MM-DD')}T23:59:59` : filters.endDateLimite;

        if (this.currentStatusExecucao === 'atesto') {
            filters.statusExecucao = StatusExecucaoDIO.AGUARDANDO_FUNCIONARIO;
        } else if (this.currentStatusExecucao === 'validacao') {
            filters.statusExecucao = StatusExecucaoDIO.AGUARDANDO_FISCAL;
        } else if (this.currentStatusExecucao === 'afericao') {
            filters.statusExecucao = StatusExecucaoDIO.EXECUTADA;
            filters.statusAuditoria = StatusAuditoriaDIO.AGUARDANDO_AUDITORIA
            filters.orderBy = 'dataDisponibilizacao';
            if (this.currentRole === RolesEnum.AFERIDOR) {
                filters.aferidor = this.user.id;
            }
        } else if (this.currentStatusExecucao === 'afericao-aprovacao') {
            filters.statusAfericao = StatusAfericaoEnum.PENDENTE_APROVACAO;
        }

        this.getAll(filters);
    }

    getAll(filters: DioFilters) {
        this.setQueryParam();
        this.dioService.getAllByParametersV2<Dio>(this.pagination.current, this.pagination.pageSize, pickBy(filters)).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((data) => {

                    if (this.currentStatusExecucao === 'afericao') {
                        if (data?.dataDisponibilizacao) {
                            const alredyTenDays = dayjs(new Date()).diff(dayjs(data?.dataDisponibilizacao), 'days');
                            return {
                                ...this.buildResponseV2(data),
                                alreadyTenDays: alredyTenDays >= 10,
                            }
                        }
                    }

                    return this.buildResponseV2(data)
                });
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    buildResponse(data: Dio) {
        const demandante = data.demandante as Demandante;
        const servico = data.servico as Servico;
        const alocacao = data.alocacao as unknown as Alocacao;
        const contrato = data.contrato as Contrato;
        const condutor = data.condutor as Condutor;
        const equipamento = alocacao?.equipamento as Equipamento;
        const beneficiaria = contrato.beneficiaria as Beneficiaria;

        return {
            ...data,
            demandanteNome: demandante?.nome,
            servicoNome: servico?.titulo,
            contratoNome: contrato?.nome,
            alocacaoCode: alocacao && alocacao?.codigo,
            equipamentoNome: equipamento && `${alocacao.codigo} (${equipamento?.placa})`,
            beneficiariaNome: beneficiaria && beneficiaria?.nome,
            condutorNome: (condutor?.firstName && condutor?.lastName) ? `${condutor?.firstName} ${condutor?.lastName}` : null,
            dataLimiteExecucao: dayjs(data.dataLimiteExecucao).format('DD/MM/YYYY HH:mm:ss'),
            created_at: dayjs(data.created_at).format('DD/MM/YYYY HH:mm:ss'),
        }
    }

    buildResponseV2(data: any) {
        return {
            ...data,
            equipamentoNome: data.equipamentoPlaca && `${data.alocacaoCodigo} (${data.equipamentoPlaca})`,
            dataLimiteExecucao: dayjs(data.dataLimiteExecucao).format('DD/MM/YYYY HH:mm:ss'),
            created_at: dayjs(data.created_at).format('DD/MM/YYYY HH:mm:ss'),
        }
    }

    getAllResources(beneficiaria?: string) {
        this.loading.filter = true;
        const condutorRequest = beneficiaria ? this.condutorService.getAllByBeneficiaria(beneficiaria) : this.condutorService.getAll<Condutor>();
        const contratoRequest = beneficiaria ? this.contratoService.getAllByBeneficiaria(beneficiaria) : this.contratoService.getAll<Contrato>();
        const equipamentoRequest = beneficiaria ? this.equipamentoService.getAllByBeneficiaria(beneficiaria) : this.equipamentoService.getAll<Equipamento>();
        const orgaoRequest = this.orgaoService.getAll<Orgao>();
        const demandanteRequest = this.demandanteService.getAll<Demandante>();
        const orgaoUsarioRequest = this.user.id && (this.currentRole === RolesEnum.FUNCIONARIO || this.currentRole === RolesEnum.FISCAL)
            ? this.orgaoService.getByUsuario<Orgao>(this.user.id)
            : of([]);

        forkJoin([condutorRequest, contratoRequest, equipamentoRequest, orgaoRequest, demandanteRequest, orgaoUsarioRequest]).subscribe({
            next: ([resCondutor, resContrato, resEquip, resOrgao, resDemandante, resOrgaosUsuarios]) => {
                this.condutores = resCondutor.data;
                this.contratos = resContrato.data;
                this.equipamentos = resEquip.data;
                this.orgaos = resOrgao.data;
                this.demandantes = resDemandante.data;

                const orgaosUsuarios = resOrgaosUsuarios;
                if (orgaosUsuarios.length > 0) {
                    this.orgaos = orgaosUsuarios;
                    this.showFields.BenConEqui = false;
                }
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.filter = false);
    }

    searchCondutor(term: string, item: Condutor) {
        term = term.toLocaleLowerCase();
        return item.firstName.toLocaleLowerCase().indexOf(term) > -1 ||
            item.lastName.toLocaleLowerCase().indexOf(term) > -1;
    }

    searchEquipamento(term: string, item: Equipamento) {
        term = term?.toLocaleLowerCase();
        return item.marca && item?.marca.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.placa && item?.placa.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.modelo && item?.modelo.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.alocacaoAtual && item?.alocacaoAtual.codigo && item?.alocacaoAtual.codigo.toLocaleLowerCase().indexOf(term) > -1;
    }

    searchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.nome.toLocaleLowerCase().indexOf(term) > -1;
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    showCreateDio() {
        const rolesNotAllowed = [
            RolesEnum.CONTRATADO_ADM.toString(),
            RolesEnum.AFERIDOR.toString(),
            RolesEnum.COORDENADOR.toString(),
        ];

        return !(rolesNotAllowed.includes(this.currentRole))
            && this.currentRole !== RolesEnum.FUNCIONARIO
            && this.currentRole !== RolesEnum.FISCAL
    }

    private setQueryParam() {
        let queryParams = {} as Params;
        mapValues(this.filters, (value, key) => queryParams = value ? { ...queryParams, ...{ [key]: value } } : queryParams)

        const urlTree = this.router.createUrlTree([], { queryParams, queryParamsHandling: '' });
        this.router.navigateByUrl(urlTree);
    }

    private getQueryParam() {
        mapValues(this.activatedRoute.snapshot.queryParams, (value, key) => this.filters[key] = value);

        this.toggleFilters = !isEmpty(this.filters)
    }

    /**
     * This function helps performance when angular show a list
     * 
     * @param index 
     * @param item 
     * @returns 
     */
    trackByFn(index: number, item: any): any {
        return item.id;
    }

}