import { Component, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { RelatorioConsumo, RelatorioFilter } from '../relatorio.model';
import { Alocacao, Contrato, ContratoService } from 'src/app/contrato';
import { Beneficiaria, BeneficiariaService } from 'src/app/beneficiaria';
import { GenericRelatorioComponent } from '../generic-relatorio.component';
import { catchErr } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RelatorioService } from '../relatorio.service';
import * as dayjs from 'dayjs';
import { CurrencyPipe } from '@angular/common';
import { dataAtualString, primeiroDiaDoMesString } from 'src/app/shared/date-utils';
import { Chart, registerables } from 'chart.js';

@Component({
    selector: 'app-relatorio-aprovacoes',
    templateUrl: './relatorio-aprovacoes.component.html',
    providers: [CurrencyPipe],
})
export class RelatorioAprovacoesComponent extends GenericRelatorioComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios', route: '/relatorio' },
        { label: 'Aferições' },
    ]

    beneficiaria: string | undefined;
    contrato: string | undefined;
    dataInicioPesquisada: string | null;
    dataFimPesquisada: string | null;
    dataSource: any[];

    totals: { totalPendentesAfericao: 0, totalAferidas: 0 };
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
    columns: GenerateTableColumn[] = [
        { header: 'No DIO', attr: 'numeroDio' },
        { header: 'Contrato', attr: 'nomeContrato' },
        { header: 'Cód Alocação', attr: 'codigoAlocacao' },
        { header: 'Grupo', attr: 'grupoTarifacao' },
        { header: 'Email Aferidor', attr: 'aferidorEmail' },
        { header: 'Data Aprovação', attr: 'dataFinalizacao' },
        { header: 'Tempo Inicialização', attr: 'totalMinutesTempoInicializacao' },
        { header: 'Tempo Atribuição', attr: 'totalMinutesTempoInicializacao' },
        { header: 'Tempo Aferição', attr: 'totalMinutesTempoAfericao' },
    ];
    grafico: any;

    constructor(
        private relatorioService: RelatorioService,
        private beneficiariaService: BeneficiariaService,
        protected override contratoService: ContratoService,
        protected override snackBar: MatSnackBar,
        private readonly currencyPipe: CurrencyPipe,
    ) {
        super(contratoService, snackBar);
    }

    ngOnInit() {
        this.beneficiariaService.getAll<Beneficiaria>().subscribe({
            next: (res) => this.beneficiarias = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);

        this.toggleFilters = true;
        this.filters.dataInicio = primeiroDiaDoMesString();
        this.filters.dataFim = dataAtualString();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        const filters = { ...this.filters };

        this.loading = true;
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.getRelatorioAprovacao(filters).subscribe({
            next: (res: any) => {                            
                console.log('response aprovacoes: ', res);
                this.dataSource = res.data;

                this.dataInicioPesquisada = this.filters.dataInicio ? dayjs(this.filters.dataInicio).format('DD/MM/YYYY') : null;
                this.dataFimPesquisada = this.filters.dataFim ? dayjs(this.filters.dataFim).format('DD/MM/YYYY') : null;
            }
        }).add(() => {
            this.loading = false;
            this.toggleFilters = false;
        });
    }

    setBeneficiaria(beneficiaria: Beneficiaria) {
        this.buscaContratos(beneficiaria.id);
    }

    buildResponse(data: RelatorioConsumo) {
        this.contrato = data.contrato;
        this.beneficiaria = data.beneficiaria;

        return {
            contrato: data.contrato,
            prefixo: data.prefixo,
            placa: data.placa,
            grupo: data.grupo,
            numeroDio: data.numeroDio,
            dataInicioExecucao: data.dataInicioExecucao ? dayjs(data.dataInicioExecucao).format('DD/MM/YYYY') : '---',
            kmReal: data.kmReal + 'km',
            valorKm: this.currencyPipe.transform(data.valorKm, 'BRL') || data.valorKm,
            saldo: this.currencyPipe.transform(data.saldo, 'BRL') || data.saldo,
            excesso: this.currencyPipe.transform(data.excesso, 'BRL') || data.excesso,
            valorDevido: this.currencyPipe.transform(data.valorDevido, 'BRL') || data.valorDevido,
        }
    }


}

