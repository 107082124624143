export * from './contrato.model';
export * from './contrato.component';
export * from './contrato.service';

export * from './form-contrato/form-contrato.component';

export * from './view-contrato/detail-contrato/detail-contrato.component';
export * from './view-contrato/view-contrato.component';

export * from './view-contrato/alocacao/alocacao.model';
export * from './view-contrato/alocacao/alocacao.service';
export * from './view-contrato/alocacao/alocacao.component';
export * from './view-contrato/alocacao/form-alocacao/form-alocacao.component';
export * from './view-contrato/alocacao/view-alocacao/view-alocacao.component';

export * from './view-contrato/tarifa/tarifa.model';
export * from './view-contrato/tarifa/tarifa.service';
export * from './view-contrato/tarifa/tarifa.component';
export * from './view-contrato/tarifa/form-tarifa/form-tarifa.component';