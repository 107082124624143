import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EquipamentoCategoria, EquipamentoGrupo, EquipamentoTipo } from "../../equipamento/equipamento.model";

@Component({
    template: `
        <h1 mat-dialog-title>{{ data.nome }}</h1>
        <div class="items d-flex text-nowarp overflow-x-auto pb-0" #slider mat-dialog-content
            (mousedown)="mousedown($event)" (mouseup)="mouseup()" (mouseleave)="mouseleave()" (mousemove)="mousemove($event)">
            <img height="500" class="item" [ngClass]="{'me-3': !last}" [src]="imagem.url" [alt]="imagem.name" *ngFor="let imagem of data.imagens; last as last">
        </div>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Fechar</button>
        </mat-dialog-actions>
    `,
    styles: [`
        .items { cursor: pointer; }
        .items.active { cursor: grabbing; }
    `]
})
export class ImgDialog {

    isDown: boolean;
    startX: number;
    scrollLeft: number;

    @ViewChild('slider') slider: ElementRef;

    constructor(@Inject(MAT_DIALOG_DATA) public data: EquipamentoCategoria | EquipamentoGrupo | EquipamentoTipo | any) {}

    mousedown($event: MouseEvent) {
        const slider = this.slider.nativeElement;

        this.isDown = true;
        slider.classList.add('active');
        this.startX = $event.pageX - slider.offsetLeft;
        this.scrollLeft = slider.scrollLeft;
    }

    mouseup() {
        this.isDown = false;
        this.slider.nativeElement.classList.remove('active');
    };

    mouseleave() {
        this.isDown = false;
        this.slider.nativeElement.classList.remove('active');
    };

    mousemove($event: MouseEvent) {
        if (!this.isDown) return;
        $event.preventDefault();

        const slider = this.slider.nativeElement;
        const x = $event.pageX - slider.offsetLeft;
        const walk = (x - this.startX) * 1; //scroll-fast
        slider.scrollLeft = this.scrollLeft - walk;
    };

}