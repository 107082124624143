import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, RolesEnum } from 'src/app/core';
import { catchErr } from 'src/app/shared/utils';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { BeneficiariaService } from '../beneficiaria';
import { Condutor, CondutorService } from './';
import * as dayjs from 'dayjs';

@Component({
    selector: 'condutor',
    templateUrl: './condutor.component.html'
})
export class ListCondutorComponent implements OnInit {

    loading = true;
    dataSource: Condutor[];
    beneficiariaId: string;
    isContratadoAdm: boolean;

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Condutores' },
    ]

    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        {
            tdClass: 'td-w-auto',
            template: (rowData: Condutor) => `<img height="40" width="40" class="img-cover rounded-circle bg-default" 
                src="${rowData.redFlag ? '/assets/img/block.jpg' : rowData.avatarUrl || '/assets/img/default_user.jpeg'}"/>`
        },
        { header: 'Nome', attr: 'nome' },
        { header: 'CNH', attr: 'CNH' },
        { header: 'Validade CNH', attr: 'validadeCNH' },
        { header: 'Beneficiaria', attr: 'beneficiaria' },
        {
            header: 'Tem Alguma Anotação?',
            template: (rowData: Condutor) => rowData.redFlag
                ? '<span class="status-badge bg-danger text-white">Sim</span>'
                : '<span class="status-badge bg-primary text-white">Não</span>'
        },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Anotações', color: 'accent' },
            click: {
                action: (rowData: Condutor) => this.router.navigateByUrl(`beneficiaria/${this.beneficiariaId}/condutor/${rowData.id}/anotacao`)
            }
        },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
                action: (rowData: Condutor) => this.router.navigateByUrl(`beneficiaria/${this.beneficiariaId}/condutor/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private condutorService: CondutorService,
        private beneficiariaService: BeneficiariaService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.isContratadoAdm = this.authService.user.role == RolesEnum.CONTRATADO_ADM;
        this.beneficiariaId = this.activatedRoute.snapshot.params['idBeneficiaria'];
        if (this.beneficiariaId) {
            this.search();
            return;
        }
        
        this.beneficiariaService.getBeneficiariaByUserVirtualId(this.authService.user.sub).subscribe((beneficiaria) => {
            this.beneficiariaId = beneficiaria.data[0].id;
            this.search();
        });
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.condutorService.getPaginated<Condutor>(this.pagination.current, this.pagination.pageSize, this.beneficiariaId).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((condutor) => ({
                    ...condutor,
                    beneficiaria: condutor.beneficiaria?.nome,
                    nome: `${condutor.firstName} ${condutor.lastName}`,
                    validadeCNH: dayjs(condutor.validadeCNH).format('DD/MM/YYYY')
                }));

                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
