import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { 
    DetailOrgaoComponent,
    FormFuncionarioComponent,
    FormLocaisApresentacaoComponent,
    FormOrgaoComponent,
    FormPoloComponent,
    FormRegiaoAdminComponent,
    FuncionarioComponent,
    FuncionarioService,
    LocaisApresentacaoComponent,
    LocaisApresentacaoService,
    LocalidadeComponent,
    OrgaoComponent,
    OrgaoService,
    PoloComponent,
    PoloService,
    RegiaoAdminComponent,
    RegiaoAdminService,
    ViewOrgaoComponent,
} from '.';

@NgModule({
    declarations: [
        DetailOrgaoComponent,
        FormFuncionarioComponent,
        FormLocaisApresentacaoComponent,
        FormOrgaoComponent,
        FormPoloComponent,
        FormRegiaoAdminComponent,
        FuncionarioComponent,
        LocaisApresentacaoComponent,
        LocalidadeComponent,
        OrgaoComponent,
        PoloComponent,
        RegiaoAdminComponent,
        ViewOrgaoComponent,
    ],
    imports: [SharedModule],
    providers: [
        FuncionarioService,
        LocaisApresentacaoService,
        OrgaoService,
        PoloService,
        RegiaoAdminService
    ]
})
export class LocalidadeModule { }
