<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    
    <mat-card>
        <mat-card-content>        
            <div class="d-flex align-items-end mb-3">
                <div class="d-flex align-items-center mb-1">
                    <h1 class="fs-3 fw-light mb-0">Usuários</h1>
                    <mat-icon class="pointer text-warning mt-1 ms-2" (click)="toggleInfo = !toggleInfo">info</mat-icon>
                </div>
                <mat-form-field appearance="outline" class="input-sm ms-auto me-3">
                    <mat-label>Buscar usuário</mat-label>
                    <input matInput [(ngModel)]="filter" (keyup.enter)="search()">
                    <button mat-icon-button matSuffix (click)="search()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>                
                <button mat-flat-button color="primary" [routerLink]="['novo']">NOVO USUÁRIO</button>
            </div>
            <div>
                <!-- FILTROS -->
                <form class="border-top pt-3" name="form" (ngSubmit)="form.valid && search()" #form="ngForm" novalidate>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <ng-select name="role" [items]="rolesInfo" bindValue="value" placeholder="Perfil de Usuário"
                                [(ngModel)]="selectedRole" [clearable]="false">
                                <!-- selected -->
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{ item.nomeExibicao }}</span>
                                    <!-- <span class="opacity-50" *ngIf="item">{{ item.description }}</span> -->
                                </ng-template>
            
                                <!-- lista -->
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <div class="lh-sm mt-2">{{ item.nomeExibicao }}</div>
                                    <!-- <div class="fs-7 opacity-50">{{ item.value }}</div> -->
                                </ng-template>
                            </ng-select>
                        </div>                        
                        <div class="col-12 col-md-auto">
                            <button mat-fab color="accent" type="submit" [disabled]="form.form.invalid || loading">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                </form>
                
                <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>
            </div>
            <ng-collapse [toggle]="toggleInfo">
                <div class="callout border-warning bg-yellow-100 mb-3">
                    <mat-list role="list">
                        <mat-list-item class="p-0" role="listitem" [ngClass]="{'mb-1': !last}" *ngFor="let info of rolesInfo; last as last">
                            <span class="fw-bold">{{ info.value | translate }}</span>: {{ info.description }}
                        </mat-list-item>
                    </mat-list>
                </div>
            </ng-collapse>
            <generate-table
                [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                [total]="pagination.total" [pageSize]="pagination.pageSize" 
                (pageEvent)="search($event)"
            ></generate-table>
        </mat-card-content>
    </mat-card>
</div>
