import { Injectable } from "@angular/core";
import { GenericCRUDService } from "../shared/services";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UsuarioAusenciaService extends GenericCRUDService {

    constructor(
        http: HttpClient, 
    ) {
        super(http);
        this.url = `/usuarios-ausencias`;
    }

    getAusencias(usuarioId: string | undefined): Observable<any> {
        return this.http.get(`${this.url}/usuario/${usuarioId}`);
    }
}