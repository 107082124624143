import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { EquipamentoCategoria } from '../../equipamento.model';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipamentoCategoriaService } from '../equipamento-categoria.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-form-equipamento-categoria',
    templateUrl: './form-equipamento-categoria.component.html'
})
export class FormEquipamentoCategoriaComponent implements OnInit {

    loading: any = { main: false, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    categoria = {} as EquipamentoCategoria;
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private categoriaService: EquipamentoCategoriaService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.categoria.id = this.activatedRoute.snapshot.params['id'];
        this.title = this.categoria.id ? 'Editar' : 'Nova';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Equipamentos', route: '/equipamento', queryParams: { tab: 'categorias' } },
            { label: this.title + ' categoria' },
        ]
        if (this.categoria.id)
            this.getById();
    }

    save() {
        this.loading.save = true;
        let request: Observable<EquipamentoCategoria>

        request = this.categoria.id
            ? this.categoriaService.update<EquipamentoCategoria>(this.categoria.id, this.categoria)
            : this.categoriaService.create<EquipamentoCategoria>(this.categoria)

        request.subscribe({
            next: () => {
                this.snackBar.open('Categoria salva com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/equipamento'], { queryParams: { tab: 'categorias' } });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.categoria.nome, (dialogRef: MatDialogRef<any>) => {
            this.categoriaService.remove<EquipamentoCategoria>(this.categoria.id).subscribe({
                next: () => {
                    this.snackBar.open('Categoria apagada com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/equipamento'], { queryParams: { tab: 'categorias' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    filesOut($event: any) {
        this.categoria.imagens = this.categoria.imagens ? [...this.categoria.imagens, ...$event] : $event
    }

    fileRemove($event: { file: any, index: number }) {
        if ($event.file.id) {
            this.removeDialogService.show($event.file.name, (dialogRef: MatDialogRef<any>) => {
                this.categoriaService.removeImage($event.file.id).subscribe({
                    next: () => {
                        this.snackBar.open('Imagem apagada com sucesso!', '✕', { panelClass: ['success'] });
                        this.getById();
                        dialogRef.close();
                    }, error: (err) => catchErr(err, this.snackBar)
                }).add(() => this.loading.save = false);
            });
        } else {
            this.categoria.imagens.splice($event.index, 1)
        }
    }

    private getById() {
        this.loading.main = true;
        this.categoriaService.getById<EquipamentoCategoria>(this.categoria.id).subscribe({
            next: (res) => this.categoria = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }
}
