import { Component } from '@angular/core';
import { Pagination } from 'src/app/shared/models';
import { Contrato, ContratoService } from 'src/app/contrato';
import { Breadcrumb } from 'src/app/shared/components';
import { AuthService } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';

@Component({
    selector: 'app-view-beneficiaria',
    templateUrl: './view-beneficiaria.component.html'
})
export class ViewBeneficiariaComponent {

    loading: boolean;
    selectedTab: number;
    beneficiariaId: string;
    contratos: Pagination<Contrato>;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Beneficiárias', route: '/beneficiaria' },
        { label: 'Visualizar beneficiaria' },
    ]

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private contratoService: ContratoService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.selectedTab = this.getTab(this.activatedRoute.snapshot.queryParams['tab'])
        this.beneficiariaId = this.activatedRoute.snapshot.params['idBbeneficiaria'];
        this.loading = true;
        this.contratoService.getPaginated<Contrato>(0, 50, this.beneficiariaId).subscribe({
            next: (res) => this.contratos = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    private getTab(tabName: string) {
        switch (tabName) {
            case 'condutor': return 1;
            case 'contrato': return 2;
            default: return 0;
        }
    }
}
