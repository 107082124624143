import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GenericCRUDService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class EquipamentoCategoriaService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/equipamentos-categoria`;
    }

    override create<EquipamentoCategoria>(object: EquipamentoCategoria): Observable<EquipamentoCategoria> {
        const form = this.getFormData(object);
        return this.http.post<EquipamentoCategoria>(`${this.url}`, form);
    }

    override update<EquipamentoCategoria>(id: string, object: EquipamentoCategoria): Observable<EquipamentoCategoria> {
        const form = this.getFormData(object);
        return this.http.patch<EquipamentoCategoria>(`${this.url}/${id}`, form);
    }

    removeImage(idImg: string): Observable<any> {
        return this.http.delete(`${this.url}/imagem/${idImg}`);
    }

    private getFormData(object: any) {
        const formData: FormData = new FormData();
        for (const key in object) {
            if (Array.isArray(object[key])) {
                for (const imgKey in object[key]) {
                    formData.append(key, object[key][imgKey]);
                }
            } else {
                formData.append(key, object[key]);
            }
        }
        return formData;
    }

}
