import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/shared/components';
import { catchErr, convertFromBase64, showInputError } from 'src/app/shared/utils';
import { UsuarioService, UsuarioResponse } from '..';
import { Observable } from 'rxjs';
import { AuthService, RolesEnum, RolesInfo } from 'src/app/core';
import { StorageService } from 'src/app/shared/services';
import { StoragePageEnum } from 'src/app/shared/models';
import { Aferidores } from 'src/app/dio/dio.model';
import { OrgaoService } from 'src/app/localidade/orgao/orgao.service';
import { Orgao } from 'src/app/localidade';
import { AfericaoService } from 'src/app/dio';
import { AferidorService } from 'src/app/dio/aferidor.service';

@Component({
    selector: 'app-form-usuario',
    templateUrl: './form-usuario.component.html'
})
export class FormUsuarioComponent implements OnInit {

    loading = { main: false, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    usuario = {} as UsuarioResponse;
    rolesInfo = RolesInfo;
    showInputError = showInputError;
    currentRole: string;
    aferidorUser: Aferidores;
    orgaos: Orgao[];
    
    constructor(
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private router: Router,
        private snackBar: MatSnackBar,
        private storageService: StorageService,
        private orgaoService: OrgaoService,
        private authService: AuthService,
        private afericaoService: AfericaoService,
        private aferidorService: AferidorService
    ) { }

    ngOnInit() {
        this.currentRole = this.authService.user.realm_access.roles[0];
        if (this.currentRole === RolesEnum.COORDENADOR) {
          this.rolesInfo = this.rolesInfo.filter(role => role.value === RolesEnum.AFERIDOR)
        }

        this.usuario.id = this.activatedRoute.snapshot.params['id'];
        this.usuario.role = this.activatedRoute.snapshot.queryParams['userRole'];

        this.title = this.usuario.id ? 'Editar' : 'Novo';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Usuários', route: '/usuario' },
            { label: this.title + ' usuário' },
        ]
        if (this.usuario.id) {
            this.loading.main = true;
            this.usuarioService.getById<UsuarioResponse>(this.usuario.id).subscribe({
                next: (res) => {                     
                  this.usuario = res                  
                },
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }

        const userString: any = localStorage.getItem('SDuser');

        if (userString) {
          this.currentRole = JSON.parse(userString).realm_access.roles[0] as any;
        }
    }

    compareWith(item: any, selected: any) {
        return item.value == selected
    }

    async roleSelected(selectedRole: string) {
        this.usuario = {
            ... this.usuario,
            role: selectedRole
        }

        if(selectedRole === RolesEnum.FUNCIONARIO){
            this.orgaoService.getAll<Orgao>().subscribe(res => {
                this.orgaos = res.data;
            });                        
        }
    }

    enableOrDisableAferidor() {
      this.afericaoService.enableOrDisableAferidor(this.usuario.id || '').subscribe({
        next: (res) => this.aferidorUser = res, 
        error: (err) => catchErr(err, this.snackBar)
      });
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>

        if(this.usuario.numero){
            this.aferidorService.findAferidorByNumero(this.usuario.numero).subscribe({
                next: (res) => {
                  console.log("res by numero: ", res);
                  if(res && res.numero){
                    this.snackBar.open('Já existe um Aferidor cadastrado com o número ' + this.usuario.numero + '!', '✕', { panelClass: ['error'] });
                    return;
                  }
                }
            });
        }

        if (this.usuario.id) {
            request = this.usuarioService.update<UsuarioResponse>(this.usuario.id, this.usuario)
        } else {
            this.usuario.username = this.usuario.email;
            request = this.usuarioService.create<UsuarioResponse>(this.usuario)
        }        

        request.subscribe({
            next: (res) => {

                // CHECK IS HAS STORAGE REDIRECT
                const storage = this.storageService.storage[StoragePageEnum.FormBeneficiaria] || 
                                this.storageService.storage[StoragePageEnum.FormContrato] || 
                                this.storageService.storage[StoragePageEnum.FormCondutor] ||
                                this.storageService.storage[StoragePageEnum.ExecutorLocal];

                if (this.usuario.role === RolesEnum.AFERIDOR) {
                    this.usuarioService.createAferidorOnManager(res.id, this.usuario.numero).subscribe(); 
                }
                
                const returnObj = this.activatedRoute.snapshot.queryParams['returnObj'];
                const previewPageBase64 = this.activatedRoute.snapshot.queryParams['previewsPage'];     
                const isArray = !!this.activatedRoute.snapshot.queryParams['isArray'];                            

                if(storage) {
                    const obj = { ...storage, [storage.returnObj]: storage.isArray ? [res] : res };
                    console.log('object to replace in storage: ', obj);
                    this.storageService.set(this.storageService.getStorageKey(storage), obj);
                }

                if(previewPageBase64){
                    console.log('redirecting using preview page with base64');                    

                    const path = convertFromBase64(previewPageBase64);
                    this.router.navigateByUrl(path);
                    
                } else {
                    const redirect = this.activatedRoute.snapshot.queryParams['redirect'];
                    const id = this.activatedRoute.snapshot.queryParams['id'];
    
                    if (storage) {
    
                        storage.beneficiaria && this.storageUser(res, storage, returnObj, isArray, StoragePageEnum.FormBeneficiaria, 'beneficiaria', storage.beneficiaria.id ? `beneficiaria/${storage.beneficiaria.id}/condutor/novo` : '/beneficiaria/nova');
                        storage.contrato && this.storageUser(res, storage, returnObj, isArray, StoragePageEnum.FormContrato, 'contrato', '/contrato/novo');
    
                        // TODO REVIEW
                        storage.condutor && this.storageUser(res, storage, returnObj, isArray, StoragePageEnum.FormCondutor, 'condutor', '/condutor/novo');
                        // storage.funcionario && this.storageUser(res, storage, returnObj, isArray, StoragePageEnum.funcionario, 'funcionario', '/funcionario/novo');
                    } else if(redirect && id) {
                        this.router.navigate(['/' +redirect+ '/' +id+ '/editar?returnObj='+returnObj]);
    
                    } else {
                        this.router.navigate(['/usuario']);
                    }
                }

                this.snackBar.open('Usuário salvo com sucesso!', '✕', { panelClass: ['success'] });

            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    private storageUser(user: UsuarioResponse, storage: any, returnObj: any, isArray: boolean, storageKey: string, objKey: string, route: string) {
        const obj = { ...storage[objKey], [returnObj]: isArray ? [user] : user };
        
        this.storageService.set(storageKey, { [objKey]: obj })
        this.router.navigateByUrl(route);
    }

    isFuncionario(): boolean {
        return this.usuario && this.usuario.role === RolesEnum.FUNCIONARIO;
    }

    setOrgao(orgao?: Orgao) {        
        if (orgao) {
            this.usuario = {
                ...this.usuario,
                orgao: orgao.id                
            };
        } 
    }

    searchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.nome.toLocaleLowerCase().indexOf(term) > -1 ||
            item.titulo.toLocaleLowerCase().indexOf(term) > -1 ||
            item.firstName.toLocaleLowerCase().indexOf(term) > -1 ||
            item.lastName.toLocaleLowerCase().indexOf(term) > -1 ||
            item.codigo && item?.codigo.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.equipamento && item?.equipamento.toLocaleLowerCase().indexOf(term) > -1;
    }

    back() {
        const previewPageBase64 = this.activatedRoute.snapshot.queryParams['previewsPage'];
        this.router.navigateByUrl(previewPageBase64 ? convertFromBase64(previewPageBase64) : '/usuario');
    }
}

