<mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>

<div class="row" *ngIf="!loading">
    <div class="col-12 d-flex align-items-end mb-2">
        <h1 class="fs-3 fw-light mb-0">{{ orgao.nome }} ({{ orgao.sigla }})</h1>
        <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/localidade/orgao', orgao.id, 'editar']">EDITAR ORGÃO</button>
    </div>
    <div class="col-12 mb-3">
        <mat-divider></mat-divider>
    </div>
    <div class="col-12 d-flex mb-3">
        <div class="flex-shrink-0">
            <img height="120" width="120" class="img-cover rounded-circle bg-default" [src]="orgao.imagem ? orgao.imagem.url : '/assets/img/default_img.jpeg'" />
        </div>
        <div class="flex-grow-1 ms-4">            
            <div>
                <span class="fw-bold fs-7 opacity-50">DESCRICÃO</span>
                <div>{{ orgao.descricao }}</div>
            </div>
        </div>
    </div>
    <div class="col-12 mb-2" *ngIf="checkAdress()">
        <h2 class="fs-4 fw-light mb-0">Endereço</h2>
    </div>
    <div class="col-12 mb-2" *ngIf="checkAdress()">
        <mat-divider></mat-divider>
    </div>
    <div class="col-12 col-md-3 mb-3" *ngIf="orgao.endereco.cep">
        <span class="fw-bold fs-7 opacity-50">CEP</span>
        <div class="lh-sm">{{ orgao.endereco.cep | mask:'00000-00' }}</div>
    </div>
    <div class="col-12 col-md-9 mb-3" *ngIf="orgao.endereco.rua">
        <span class="fw-bold fs-7 opacity-50">LOGRADOURO</span>
        <div class="lh-sm">{{ orgao.endereco.rua }}, nº {{ orgao.endereco.numero }}</div>
    </div>
    <div class="col-12 col-md-3 mb-3 mb-md-0" *ngIf="orgao.endereco.bairro">
        <span class="fw-bold fs-7 opacity-50">BAIRRO</span>
        <div class="lh-sm">{{ orgao.endereco.bairro }}</div>
    </div>
    <div class="col-12 col-md-3 mb-3 mb-md-0" *ngIf="orgao.endereco.cidade">
        <span class="fw-bold fs-7 opacity-50">CIDADE/UF</span>
        <div class="lh-sm">{{ orgao.endereco.cidade }}/{{ orgao.endereco.estado }}</div>
    </div>
    <div class="col-12 col-md-6" *ngIf="orgao.endereco.complemento">
        <span class="fw-bold fs-7 opacity-50">COMPLEMENTO</span>
        <div class="lh-sm">{{ orgao.endereco.complemento }}</div>
    </div>
</div>
