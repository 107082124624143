<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Beneficiárias</h1>
                <mat-form-field appearance="outline" class="input-sm ms-auto me-3">
                    <mat-label>Buscar beneficiarias</mat-label>
                    <input matInput [(ngModel)]="filter" (keyup.enter)="search()">
                    <button mat-icon-button matSuffix (click)="search()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
                <button mat-flat-button color="primary" [routerLink]="['nova']">NOVA BENEFICIÁRIA</button>
            </div>
            <generate-table 
                [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                [total]="pagination.total" [pageSize]="pagination.pageSize" 
                (pageEvent)="search($event)"
            ></generate-table>
        </mat-card-content>
    </mat-card>
</div>
