import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DioModule } from '../dio/dio.module';
import {
    AlocacaoComponent,
    ContratoComponent,
    ContratoService,
    ViewContratoComponent,
    FormAlocacaoComponent,
    FormContratoComponent,
    FormTarifaComponent,
    TarifacaoComponent,
    DetailContratoComponent,
} from '.';
import { ViewAlocacaoComponent } from './view-contrato/alocacao/view-alocacao/view-alocacao.component';

@NgModule({
    declarations: [
        AlocacaoComponent,
        ViewAlocacaoComponent,
        ContratoComponent,
        ViewContratoComponent,
        FormAlocacaoComponent,
        FormContratoComponent,
        FormTarifaComponent,
        TarifacaoComponent,
        DetailContratoComponent,
    ],
    imports: [DioModule, SharedModule],
    providers: [ContratoService]
})
export class ContratoModule { }
