import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { DioService } from '../dio.service';
import { Condutor, CondutorService } from '../../condutor';
import { Dio } from '../dio.model';

@Component({
    selector: 'app-associar-condutor',
    templateUrl: './associar-condutor.component.html',
})
export class AssociarCondutorComponent implements OnInit {

    loading: boolean;
    condutores: Condutor[]
    condutoresFiltered: any;
    condutorSelected: any;

    dio: Dio;
    dioId: string;
    beneficiariaId: string;
    term: string;

    showInputError = showInputError;

    constructor(
        private dialogRef: MatDialogRef<AssociarCondutorComponent>,
        private dioService: DioService,
        private condutorService: CondutorService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { dio: Dio, beneficiaria: string },
    ) {
        this.dioId = data.dio.id || '';
        this.dio = data.dio;
        this.beneficiariaId = data.beneficiaria;
    }

    ngOnInit() {
        this.condutorService.getAllByBeneficiaria(this.beneficiariaId).subscribe({
            next: (data) => {
                this.condutores = data.data;
                this.condutoresFiltered = data.data;
            }, error: (err) => catchErr(err, this.snackBar)
        });
    }

    associarCondutor() {
        this.loading = true;
        this.dioService.update(this.dioId, { condutor: this.condutorSelected[0] }).subscribe({
            next: () => {
                this.snackBar.open('Condutor associado com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    searchCondutor() {
        this.condutoresFiltered = this.condutores;
        const term = this.term?.toLocaleLowerCase();
        this.condutoresFiltered = this.condutores.filter(c =>
            c.firstName.toLocaleLowerCase().indexOf(term) > -1 ||
            c.lastName.toLocaleLowerCase().indexOf(term) > -1
        );
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }
}
