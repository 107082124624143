<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} polo</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12">
                    <mat-label>Nome</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="polo.nome" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- ZOOM -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Zoom</mat-label>
                    <input matInput type="number" name="zoom" #zoom="ngModel" [(ngModel)]="polo.zoom" required>
                    <mat-error *ngIf="showInputError(zoom, 'required')">Zoom é obrigatório</mat-error>
                </mat-form-field>

                <!-- LATITUDE -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Latitude</mat-label>
                    <input matInput name="lat" #lat="ngModel" [(ngModel)]="polo.lat" required>
                    <mat-error *ngIf="showInputError(lat, 'required')">Latitude é obrigatória</mat-error>
                </mat-form-field>

                <!-- LONGITUDE -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Longitude</mat-label>
                    <input matInput name="lng" #lng="ngModel" [(ngModel)]="polo.lng" required>
                    <mat-error *ngIf="showInputError(lng, 'required')">Longitude é obrigatória</mat-error>
                </mat-form-field>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="polo.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" [routerLink]="['/localidade']">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
