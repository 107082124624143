import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Breadcrumb } from 'src/app/shared/components';
import { UsuarioService } from '../usuario.service';
import { UsuarioResponse } from '../usuario.model';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, RolesEnum, RolesInfo } from 'src/app/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html'
})
export class ProfileComponent implements OnInit {

    perfil: UsuarioResponse;
    fileInput: any;
    loading = {
        main: true,
        save: false,
        file: false
    };
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Perfil' },
    ];

    RolesEnum = RolesEnum;
    rolesInfo = RolesInfo;
    showInputError = showInputError;

    @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;

    constructor(
        private authService: AuthService,
        private usuarioService: UsuarioService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.getProfile();
    }

    compareWith(item: any, selected: any) {
        return item.value == selected
    }

    triggerIputFile() {
        this.fileDropRef.nativeElement.click();
    }

    changeAvatar($event: any) {
        const file = $event.target.files[0];
        this.loading.file = true
        this.usuarioService.updateAvatar(file).subscribe({
            next: (res) => {
                this.authService.user = { ...this.authService.user, avatarUrl: res.avatarUrl };
                this.snackBar.open('Imagem atualizada com sucesso!', '✕', { panelClass: ['success'] });
                this.getProfile();
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.file = false);
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>

        if (this.perfil.role == RolesEnum.GESTAO_ADMIN)
            request = this.usuarioService.update<UsuarioResponse>(this.perfil.id!, this.perfil)
        else
            request = this.usuarioService.updateProfile(this.perfil)

        request.subscribe({
            next: () => {
                this.snackBar.open('Perfil atualizado com sucesso!', '✕', { panelClass: ['success'] });
                this.getProfile();
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    checkPassword(password: string, confirmPassword: string) {
        return password && confirmPassword && password != confirmPassword
    }

    changePassword(form: NgForm) {
        this.loading.save = true
        this.usuarioService.updatePassword(this.perfil.id!, form.form.value.password).subscribe({
            next: () => this.snackBar.open('Senha atualizada com sucesso!', '✕', { panelClass: ['success'] }),
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    private getProfile() {
        this.loading.main = true;
        this.usuarioService.getProfile().subscribe({
            next: (res: UsuarioResponse) => this.perfil = { ...res, role: res.realm_access.roles[0] },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }
}
