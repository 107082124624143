<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} tipo</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12">
                    <mat-label>Nome</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="tipo.nome" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- SIGLA -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Sigla</mat-label>
                    <input matInput name="sigla" #sigla="ngModel" [(ngModel)]="tipo.sigla" required>
                    <mat-error *ngIf="showInputError(sigla, 'required')">Sigla é obrigatório</mat-error>
                </mat-form-field>

                <!-- CATEGORIA -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Categoria</mat-label>
                    <mat-select name="categoria" #categoria="ngModel" [(ngModel)]="tipo.categoria" [compareWith]="compareWith" required>
                        <mat-option *ngFor="let categoria of categorias" [value]="categoria.id">{{ categoria.nome }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="showInputError(categoria, 'required')">Categoria é obrigatório</mat-error>
                </mat-form-field>

                <!-- DESCRIÇÃO -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="tipo.descricao"></textarea>
                </mat-form-field>

                <!-- ARQUIVOS -->
                <div class="col-12">
                    <ng-dd-file 
                        [filesIn]="tipo.imagens" [multiple]="true" 
                        [btnClass]="{ file: 'mat-button mat-primary', remove: 'mat-button mat-warn' }"
                        (filesOut)="filesOut($event)" (fileRemove)="fileRemove($event)"
                    ></ng-dd-file>
                </div>

                <div class="col-12">
                    <hr class="border-gray">
                </div>
                <div class="col-12 col-md-auto" *ngIf="tipo.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" [routerLink]="['/equipamento']" [queryParams]="{tab:'tipos'}">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
