import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/shared/models';
import { GenericCRUDService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class FuncionarioService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/orgao`;
    }

    getFuncionariosByOrgao<Funcionario>(page: number, pageSize: number, orgaoId?: string): Observable<Funcionario[]> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);        

        return this.http.get<Funcionario[]>(`${this.url}/${orgaoId}/funcionarios?${params}`);
    }
}
