import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericCRUDService } from '../shared/services';
import { Pagination } from '../shared/models';
import { Beneficiaria } from './beneficiaria.model';

@Injectable({
    providedIn: 'root'
})
export class BeneficiariaService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/beneficiaria`;
    }

    override getPaginated<Beneficiaria>(page: number, pageSize: number, byNameOrCnpj?: string): Observable<Pagination<Beneficiaria>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        byNameOrCnpj && params.append('term', `${byNameOrCnpj}`);

        return this.http.get<Pagination<Beneficiaria>>(`${this.url}?${params}`);
    }

    removeResponsavel(id: string): Observable<void> {
        return this.http.delete<void>(`${this.url}/responsavel/${id}`);
    }

    getBeneficiariaByUserVirtualId(responsavel: string) {
        const params = new URLSearchParams();
        params.append('responsavel', `${responsavel}`);

        return this.http.get<Pagination<Beneficiaria>>(`${this.url}?${params}`);
    }
}
