import { Injectable } from '@angular/core';
import { RelatorioFilter } from './relatorio.model';
import { Contrato, ContratoService } from '../contrato';
import { Beneficiaria } from '../beneficiaria';
import { catchErr } from '../shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class GenericRelatorioComponent {

    loading: boolean;
    filters = {} as RelatorioFilter;
    toggleFilters: boolean;

    beneficiarias: Beneficiaria[];
    contratos: Contrato[];
    classificacoes = ['VEICULO', 'MAQUINA']
    listaStatus = ['ATIVO', 'INATIVO']

    constructor(
        protected contratoService: ContratoService,
        protected snackBar: MatSnackBar
    ) { }

    protected buscaContratos(beneficiariaId: string) {
        this.loading = true;

        this.contratoService.getAllByBeneficiaria(beneficiariaId).subscribe({
            next: (res) => this.contratos = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    protected searchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.nome.toLocaleLowerCase().indexOf(term) > -1;
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    print() {
        window.print();
    }
    
}
