import { EquipamentoGrupoTarifacaoTipoEnum } from '../../../equipamento/equipamento.model';
export interface Tarifa {
    id: string;
    contrato?: any;
    grupoEquipamento?: any;
    grupoTarifacaoTipo?: EquipamentoGrupoTarifacaoTipoEnum;
    valorHoraDisponivelMaquina?: any;
    valorHoraDisponivelVeiculo?: any;
    valorHoraProduzida?: any;
    valorKmRodado?: any;
}