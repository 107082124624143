import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent, AuthGuard, RegisterComponent, ResetPasswordComponent } from './core';
import { HomeComponent } from './home/home.component';

import { AferirDioComponent, DioComponent, FormDioComponent, ViewDioComponent } from './dio';
import { BeneficiariaComponent, ViewBeneficiariaComponent, FormBeneficiariaComponent } from './beneficiaria';
import { AnotacaoComponent, FormCondutorComponent, ListCondutorComponent } from './condutor';
import { ContratoComponent, FormAlocacaoComponent, FormContratoComponent, ViewContratoComponent, FormTarifaComponent, ViewAlocacaoComponent } from './contrato';
import { EquipamentoComponent, FormEquipamentoCategoriaComponent, FormEquipamentoComponent, FormEquipamentoGrupoComponent, FormEquipamentoTipoComponent, ViewEquipamentoComponent } from './equipamento';
import { FinanceiroComponent } from './financeiro';
import { FormLocaisApresentacaoComponent, FormOrgaoComponent, FormPoloComponent, FormRegiaoAdminComponent, LocalidadeComponent, ViewOrgaoComponent } from './localidade';
import { DioNaoExecutadoComponent, EquipamentosExistentesComponent, RelatorioComponent, RelatorioConsumoPorEquipamentoComponent, ValorBrutoBeneficiarioComponent, ValorBrutoMonetarioComponent, ValoresBrutosEquipamentosComponent } from './relatorio';
import { ServicoComponent } from './servico';
import { FormUsuarioComponent, ProfileComponent, UsuarioComponent, ViewUsuarioComponent } from './usuario';
import { ConfigComponent, FormConfigComponent } from './config';
import { ApmErrorHandler } from '@elastic/apm-rum-angular';
import { FormUsuarioAusenciaComponent } from './usuario/form-ausencia/form-ausencia.component';
import { FaturamentoComponent } from './financeiro/faturamento/faturamento.component';
import { RelatorioAfericoesComponent } from './relatorio/relatorio-afericoes/relatorio-afericoes.component';
import { RelatorioAprovacoesComponent } from './relatorio/relatorio-aprovacoes/relatorio-aprovacoes.component';
import { ViewFaturamentoComponent } from './financeiro/view-faturamento/view-faturamento.component';

const routes: Routes = [
    { path: 'sign-in', component: AuthComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'reset-password', component: ResetPasswordComponent },

    { path: '', component: HomeComponent, canActivate: [AuthGuard] },

    { path: 'dio', children: [
        { path: '', component: DioComponent },
        { path: 'novo', component: FormDioComponent },
        { path: ':idDio/aferir', component: AferirDioComponent },
        { path: ':idDio/mapa', component: AferirDioComponent },
        { path: ':idDio/editar', component: FormDioComponent },
        { path: ':idDio/visualizar', component: ViewDioComponent },
    ]},
    { path: 'pendencias/:statusExecucao', component: DioComponent },
    { path: 'beneficiaria', canActivate: [AuthGuard], children: [
        { path: '', component: BeneficiariaComponent },
        { path: 'nova', component: FormBeneficiariaComponent },
        { path: ':idBeneficiaria/editar', component: FormBeneficiariaComponent },
        { path: ':idBeneficiaria/visualizar', component: ViewBeneficiariaComponent },

        { path: ':idBeneficiaria/condutor/novo', component: FormCondutorComponent },
        { path: ':idBeneficiaria/condutor/:idCondutor/editar', component: FormCondutorComponent },
        { path: ':idBeneficiaria/condutor/:idCondutor/anotacao', component: AnotacaoComponent },
    ]},
    { path: 'condutores', children: [
        { path: '', component: ListCondutorComponent },
        { path: 'novo', component: FormCondutorComponent },
        { path: ':idCondutor/editar', component: FormCondutorComponent },
        { path: ':idCondutor/anotacao', component: AnotacaoComponent },
    ]},
    { path: 'contrato', canActivate: [AuthGuard], children: [
        { path: '', component: ContratoComponent },
        { path: 'novo', component: FormContratoComponent },
        { path: ':idContrato/editar', component: FormContratoComponent },
        { path: ':idContrato/visualizar', component: ViewContratoComponent },
        
        { path: ':idContrato/alocacao/nova', component: FormAlocacaoComponent },
        { path: ':idContrato/alocacao/:idAlocacao/editar', component: FormAlocacaoComponent },
        { path: ':idContrato/alocacao/:idAlocacao/visualizar', component: ViewAlocacaoComponent },

        { path: ':idContrato/tarifa/nova', component: FormTarifaComponent },
        { path: ':idContrato/tarifa/:idTarifa/editar', component: FormTarifaComponent },

        { path: ':idContrato/dio/novo', component: FormDioComponent },
        { path: ':idContrato/dio/:idDio/editar', component: FormDioComponent },
    ]},
    { path: 'equipamento', canActivate: [AuthGuard], children: [
        { path: '', component: EquipamentoComponent },
        { path: 'novo', component: FormEquipamentoComponent },
        { path: ':id/editar', component: FormEquipamentoComponent },
        { path: ':id/visualizar', component: ViewEquipamentoComponent },

        { path: 'categoria/nova', component: FormEquipamentoCategoriaComponent },
        { path: 'categoria/:id/editar', component: FormEquipamentoCategoriaComponent },

        { path: 'grupo/novo', component: FormEquipamentoGrupoComponent },
        { path: 'grupo/:id/editar', component: FormEquipamentoGrupoComponent },

        { path: 'tipo/novo', component: FormEquipamentoTipoComponent },
        { path: 'tipo/:id/editar', component: FormEquipamentoTipoComponent },
    ]},
    { path: 'financeiro', canActivate: [AuthGuard], children: [
        { path: '', component: FinanceiroComponent },
        
    ]},
    { path: 'faturamentos', canActivate: [AuthGuard], children: [
        { path: '', component: FaturamentoComponent },
        { path: 'novo', component: FinanceiroComponent },
        { path: ':idFaturamento/visualizar', component: ViewFaturamentoComponent },
        
    ]},
    { path: 'localidade', canActivate: [AuthGuard], children: [
        { path: '', component: LocalidadeComponent },
        { path: 'polo/novo', component: FormPoloComponent },
        { path: 'polo/:id/editar', component: FormPoloComponent },

        { path: 'regiao-admin/nova', component: FormRegiaoAdminComponent },
        { path: 'regiao-admin/:id/editar', component: FormRegiaoAdminComponent },

        { path: 'orgao', children: [
            { path: 'novo', component: FormOrgaoComponent },
            { path: ':idOrgao/editar', component: FormOrgaoComponent },
            { path: ':idOrgao/visualizar', component: ViewOrgaoComponent },
            { path: ':idOrgao/locais-apresentacao/novo', component: FormLocaisApresentacaoComponent },
            { path: ':idOrgao/locais-apresentacao/:idLocal/editar', component: FormLocaisApresentacaoComponent },
        ]},
    ]},
    { path: 'relatorio', canActivate: [AuthGuard], children: [
        { path: '', component: RelatorioComponent },
        { path: 'dio-nao-executado', component: DioNaoExecutadoComponent },
        { path: 'consumo-por-equipamento', component: RelatorioConsumoPorEquipamentoComponent },
        { path: 'valor-bruto-beneficiario', component: ValorBrutoBeneficiarioComponent },
        { path: 'valores-brutos-equipamentos', component: ValoresBrutosEquipamentosComponent },
        { path: 'valor-bruto-monetario', component: ValorBrutoMonetarioComponent },
        { path: 'equipamentos-existentes', component: EquipamentosExistentesComponent },
        { path: 'relatorio-afericoes', component: RelatorioAfericoesComponent },
    ]},
    { path: 'aprovacoes', component: RelatorioAprovacoesComponent },
    { path: 'servico', canActivate: [AuthGuard], component: ServicoComponent },
    { path: 'usuario', canActivate: [AuthGuard], children: [
        { path: '', component: UsuarioComponent },
        { path: 'novo', component: FormUsuarioComponent },
        { path: ':id/editar', component: FormUsuarioComponent },
        { path: ':id/visualizar', component: ViewUsuarioComponent },
        { path: ':id/ausencia', component: FormUsuarioAusenciaComponent }
    ]},
    { path: 'configuracoes', canActivate: [AuthGuard], children: [
        { path: '', component: ConfigComponent },
        { path: ':idConfig/editar', component: FormConfigComponent },
    ]},
    { path: 'perfil', component: ProfileComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: 'sign-in' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [{ provide: ErrorHandler, useClass: ApmErrorHandler }]
})
export class AppRoutingModule { }
