<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} orgão</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Nome</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="orgao.nome" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- SIGLA -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Sigla</mat-label>
                    <input matInput name="sigla" #sigla="ngModel" [(ngModel)]="orgao.sigla" required>
                    <mat-error *ngIf="showInputError(sigla, 'required')">Sigla é obrigatório</mat-error>
                </mat-form-field>                                

                <!-- DESCRIÇÃO -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="orgao.descricao"></textarea>
                </mat-form-field>

                <!-- Funcionários -->
                <div class="col-12">
                    <ng-select name="user" [items]="funcionarios$ | async" bindValue="id" [multiple]="true" required
                        placeholder="Funcionários" typeToSearchText="Buscar funcionários..." notFoundText="Nenhum item encontrado" loadingText="Buscando..."
                        [loading]="loading.funcionarios" [typeahead]="funcionariosInput$" [(ngModel)]="orgao.funcionarios" [compareWith]="compareWith">
                        
                        <!-- selected -->
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right" (click)="removeExecutorLocal(item); clear(item);" aria-hidden="true">×</span>
                            <span class="ng-value-label">{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- lista -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <img class="rounded-circle me-2 img-cover" width="25" height="25" [src]="item.avatarUrl || 'assets/img/default_user.jpeg'" />
                            <span>{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>                        
                    </ng-select>
                </div>

                <div class="col-12">
                    <h2 class="fs-4 fw-light">Endereço</h2>
                    <hr class="border-gray mt-0">
                </div>

                <!-- CEP -->
                <mat-form-field class="col-12 col-md-2">
                    <mat-label>CEP</mat-label>
                    <input matInput name="cep" #cep="ngModel" [(ngModel)]="orgao.endereco.cep" mask="00000-000">
                </mat-form-field>

                <!-- RUA -->
                <mat-form-field class="col-12 col-md-10">
                    <mat-label>Logradouro</mat-label>
                    <input matInput name="rua" #rua="ngModel" [(ngModel)]="orgao.endereco.rua">
                </mat-form-field>

                <!-- NUMERO -->
                <mat-form-field class="col-12 col-md-2">
                    <mat-label>Número</mat-label>
                    <input matInput name="numero" #numero="ngModel" [(ngModel)]="orgao.endereco.numero">
                </mat-form-field>

                <!-- BAIRRO -->
                <mat-form-field class="col-12 col-md-5">
                    <mat-label>Bairro</mat-label>
                    <input matInput name="bairro" #bairro="ngModel" [(ngModel)]="orgao.endereco.bairro">
                </mat-form-field>

                <!-- CIDADE -->
                <mat-form-field class="col-12 col-md-5">
                    <mat-label>Cidade</mat-label>
                    <input matInput name="cidade" #cidade="ngModel" [(ngModel)]="orgao.endereco.cidade">
                </mat-form-field>

                <!-- ESTADO -->
                <mat-form-field class="col-12 col-md-2">
                    <mat-label>Estado</mat-label>
                    <mat-select name="estado" #estado="ngModel" [(ngModel)]="orgao.endereco.estado">
                        <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- COMPLEMENTO -->
                <mat-form-field class="col-12 col-md-10">
                    <mat-label>Complemento</mat-label>
                    <input matInput name="complemento" #complemento="ngModel" [(ngModel)]="orgao.endereco.complemento">
                </mat-form-field>

                <!-- IMAGEM -->
                <div class="col-12 mb-3">
                    <h2 class="fs-4 fw-light">Imagem</h2>
                    <ng-dd-file (filesOut)="orgao.imagem = $event" (fileRemove)="orgao.imagem = null"
                        [filesIn]="orgao.imagem" [btnClass]="{ file: 'mat-button mat-primary', remove: 'mat-button mat-warn' }"
                    ></ng-dd-file>
                </div>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="orgao.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" [routerLink]="['/localidade']" [queryParams]="{tab:'orgao'}">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
