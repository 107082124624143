<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Valores Brutos por Equipamentos</h1>
                <ng-container>
                    <button mat-icon-button color="primary" class="d-print-none btn-sm ms-auto" (click)="toggleFilters = !toggleFilters">
                        <mat-icon>tune</mat-icon>
                    </button>
                    <button mat-flat-button class="d-print-none ms-3 bg-gray-400" (click)="print()">IMPRIMIR</button>
                    <button mat-flat-button color="primary" class="d-print-none ms-3" [matMenuTriggerFor]="menu">EXPORTAR</button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="export('pdf')">PDF</button>
                        <button mat-menu-item (click)="export('csv')">Excel</button>
                    </mat-menu>
                </ng-container>
            </div>

            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters">
                <div class="border-top px-1 py-3">
                    <div class="row align-items-end">
                        <mat-form-field class="col-12 col-md-6">
                            <mat-label>Beneficiaria/CNPJ</mat-label>
                            <input matInput name="beneficiaria" #beneficiaria="ngModel" [(ngModel)]="filters.beneficiaria">
                        </mat-form-field>
                        <div class="col-12 col-md-6">
                            <ng-select name="contrato" [items]="contratos" bindValue="id" bindLabel="nome" placeholder="Contrato" 
                                [searchFn]="searchFn" [(ngModel)]="filters.contrato" [compareWith]="compareWith">
                            </ng-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="d-flex">
                                <mat-form-field class="flex-fill me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="dataInicio" name="inicio" #inicio="ngModel" autocomplete="off" [(ngModel)]="filters.dataInicio">
                                    <mat-datepicker-toggle matIconSuffix [for]="dataInicio"></mat-datepicker-toggle>
                                    <mat-datepicker #dataInicio></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field class="flex-fill">
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="dataFim" name="fim" #fim="ngModel" autocomplete="off" [(ngModel)]="filters.dataFim">
                                    <mat-datepicker-toggle matIconSuffix [for]="dataFim"></mat-datepicker-toggle>
                                    <mat-datepicker #dataFim></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <ng-select name="classificacao" [items]="classificacoes" placeholder="Classificação" [(ngModel)]="filters.classificacao"></ng-select>
                        </div>
                        <div class="col-12 col-md-2 mx-auto">
                            <button mat-flat-button color="accent" class="w-100" (click)="search()">FILTRAR</button>
                        </div>
                    </div>
                </div>
            </ng-collapse>

            <div class="w-100 mb-3">
                <!-- TOTALIZADORES -->
                <div class="callout border-blue-300 mb-3" *ngIf="!loading">
                    <div class="row">
                        <div class="col-12 col-md-3 mb-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL VALORES DAS DIOS</div>
                            <div class="lh-sm">{{ totalizadores.totalValorDio | currency: 'BRL' }}</div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL DE REGISTROS</div>
                            <div class="lh-sm">{{ totalizadores.totalRegistros }}</div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL DE KM RODADOS</div>
                            <div class="lh-sm">{{ totalizadores.totalKmRodados }} km</div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL DE PAGAMENTO POR KM RODADOS</div>
                            <div class="lh-sm">{{ totalizadores.totalValorKmRodado | currency: 'BRL' }}</div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL DE HORAS DISPONÍVEIS</div>
                            <div class="lh-sm">{{ totalizadores.totalHorasDisponiveis }}</div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL DE HORAS DISPONÍVEIS VEÍCULO</div>
                            <div class="lh-sm">{{ totalizadores.totalPagamentoHoraDisponivelVeiculo | currency: 'BRL' }}</div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL DE HORAS DISPONÍVEIS MÁQUINA</div>
                            <div class="lh-sm">{{ totalizadores.totalPagamentoHoraDisponivelMaquina | currency: 'BRL' }}</div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div class="fw-bold fs-7 opacity-50">TOTAL DE EQUIPAMENTOS UTILIZADOS</div>
                            <div class="lh-sm">{{ totalizadores.equipamentosUtilizados }}</div>
                        </div>
                    </div>
                </div>

                <!-- TABLE -->
                <div class="overflow-x-auto">
                    <generate-table [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                        [total]="pagination.total" [pageSize]="pagination.pageSize" (pageEvent)="search($event)"></generate-table>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
