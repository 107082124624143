<mat-dialog-content [ngClass]="{'pb-0': !approveDio}">
    <mat-slide-toggle color="accent" class="mb-3" [(ngModel)]="approveDio">
        <span class="fw-bold ms-1">
            DIO {{ data.statusExecucao === 'AGUARDANDO_FUNCIONARIO' ? 'ATESTADO' : 'VALIDADO' }}
        </span>
    </mat-slide-toggle>

    <div class="rounded p-3 border" *ngIf="approveDio">
        <div class="fst-italic mb-4">Eu, {{ userName | titlecase }}</div>
        <div class="fst-italic mb-3">
            {{ data.statusExecucao === 'AGUARDANDO_FUNCIONARIO' ? 'atesto' : 'valido' }} que o DIO de nº {{ data.dioNumber }} foi executado conforme determinado.
        </div>
        <mat-form-field class="w-100 mb-4">
            <mat-label>Observações (Informe caso haja alguma resalva)</mat-label>
            <textarea type="textarea" rows="6" matInput name="motivo" [(ngModel)]="observacao"></textarea>
        </mat-form-field>
        <div class="text-center border-bottom pb-1 mb-1 fst-signature fs-1 text-blue-600">{{ userName }}</div>
        <div class="text-center fs-7">{{ today }}</div>
    </div>

    <div *ngIf="!approveDio">
        <mat-form-field class="w-100">
            <mat-label>Motivo da reprovação</mat-label>
            <textarea type="textarea" rows="8" matInput name="motivo" [(ngModel)]="motivoReprovacao"></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="px-4 pb-3 pt-0 justify-content-end">
    <button mat-button mat-dialog-close class="px-3">Fechar</button>
    <button mat-flat-button color="accent" (click)="confirmReprove()" [disabled]="loading" *ngIf="!approveDio">
        <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">Reprovar</span>
    </button>
    <button mat-flat-button color="accent" (click)="confirmSignature()" [disabled]="loading" *ngIf="approveDio">
        <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">{{ data.statusExecucao === 'AGUARDANDO_FUNCIONARIO' ? 'Atestar' : 'Validar' }}</span>
    </button>
</mat-dialog-actions>
