import { Component, OnInit } from '@angular/core';
import { Polo } from '../localidade.model';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { PoloService } from './polo.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Pagination } from 'src/app/shared/models';
import { catchErr } from 'src/app/shared/utils';

@Component({
    selector: 'polo',
    templateUrl: './polo.component.html',
})
export class PoloComponent implements OnInit {

    loading = true;
    dataSource: Polo[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: { action: (rowData: Polo) => this.router.navigateByUrl(`/localidade/polo/${rowData.id}/editar`)}
        }
    ]

    constructor(
        private poloService: PoloService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.poloService.getPaginated<Polo>(this.pagination.current, this.pagination.pageSize).subscribe({
            next: (res: Pagination<Polo>) => {
                this.dataSource = res.data;
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
