import { NgModule } from '@angular/core';
import { AuthComponent, AuthService, RegisterComponent, ResetPasswordComponent, SidebarComponent } from './';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        AuthComponent,
        RegisterComponent,
        ResetPasswordComponent,
        SidebarComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SidebarComponent
    ],
    providers: [
        AuthService
    ]
})
export class CoreModule { }
