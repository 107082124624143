<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-tab-group mat-stretch-tabs="false" [selectedIndex]="selectedTab">
            <mat-tab label="Detalhes">
                <mat-card-content>
                    <detail-orgao></detail-orgao>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Funcionários">
                <mat-card-content>
                    <funcionario></funcionario>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Locais de Apresentação">
                <mat-card-content>
                    <locais-apresentacao></locais-apresentacao>
                </mat-card-content>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>
