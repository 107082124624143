import { Component, OnInit } from "@angular/core";
import { GenericRelatorioComponent } from "../generic-relatorio.component";
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from "src/app/shared/components";
import { RelatorioService } from "../relatorio.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ContratoService } from "src/app/contrato";
import * as dayjs from "dayjs";
import { Beneficiaria, BeneficiariaService } from "src/app/beneficiaria";
import { catchErr } from "src/app/shared/utils";

@Component({
    selector: 'equipamentos-existentes',
    templateUrl: './equipamentos-existentes.component.html'
})
export class EquipamentosExistentesComponent extends GenericRelatorioComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios', route: '/relatorio' },
        { label: 'Equipamentos Existentes' },
    ]

    count = 1;
    dataSource: any[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
    columns: GenerateTableColumn[] = [
        { header: 'Contrato', attr: 'contrato' },
        { header: 'Nome Beneficiaria', attr: 'nomeBeneficiaria' },
        { header: 'Prefixo', attr: 'prefixo' },
        { header: 'Placa/Série', attr: 'placaSerie' },
        { header: 'Marca', attr: 'marca' },
        { header: 'Modelo', attr: 'modelo' },
        { header: 'Ano Fabricação', attr: 'ano' },
        { header: 'Tipo', attr: 'tipo' },
        { header: 'Grupo', attr: 'grupo' },
        { header: 'Status', attr: 'status' },
        { header: 'Data de Ativação', attr: 'dataInicioAtivacao' },
        { header: 'Data de Inativação', attr: 'dataInativacao' },
    ];

    constructor(
        private relatorioService: RelatorioService,
        private beneficiariaService: BeneficiariaService,
        protected override contratoService: ContratoService,
        protected override snackBar: MatSnackBar,
    ) {
        super(contratoService, snackBar);
    }

    ngOnInit(): void {
        this.beneficiariaService.getAll<Beneficiaria>().subscribe({
            next: (res) => this.beneficiarias = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);

        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        const filters = { ...this.filters };

        this.relatorioService.getEquipamentosExistentes(filters, this.pagination).subscribe({
            next: (res: any) => {
                if (res.report.data) {
                    this.dataSource = res.report.data.map((item: any) => this.buildResponse(item))
                }
            }
        }).add(() => this.loading = false);
    }

    buildResponse(item: any,) {
        return {
            contrato: item.contrato,
            prefixo: item.prefixo,
            nomeBeneficiaria: item.nomeBeneficiaria,
            placaSerie: item.placaSerie,
            ano: item.ano,
            proprietario: item.proprietario,
            grupo: item.grupo,
            tipo: item.tipo,
            marca: item.marca,
            modelo: item.modelo,
            status: item.status,
            dataInicioAtivacao: item.dataInicioAtivacao ? dayjs(item.dataInicioAtivacao).format('DD/MM/YYYY') : '',
            dataInativacao: item.dataInativacao ? dayjs(item.dataInativacao).format('DD/MM/YYYY') : '',
        }
    }

    setBeneficiaria(beneficiaria: Beneficiaria) {
        this.buscaContratos(beneficiaria.id);
    }

    export(type: string = 'csv') {
        this.loading = true;

        const filters = { ...this.filters, removePaingation: true };
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.downloadArquivoCsvOrPdf(filters, 'equipamentos', type).subscribe({
            next: (res: any) => {
                const url = window.URL.createObjectURL(res.body as Blob);
                const startReport = filters.dataInicio ? dayjs(filters.dataInicio).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const endReport = filters.dataFim ? dayjs(filters.dataFim).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const fileName = `Equipamentos_existentes${startReport}_${endReport}`
                const anchor = document.createElement("a");
                anchor.download = `${fileName}.${type}`;
                anchor.href = url;
                anchor.click();
                this.toggleFilters = false;
                this.loading = false;
            }, error: (err) => console.log('err', err)
        });
    }
}