import { Component, OnInit } from '@angular/core';
import { EquipamentoTipo } from '../equipamento.model';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { EquipamentoTipoService } from './equipamento-tipo.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Pagination } from 'src/app/shared/models';
import { catchErr } from 'src/app/shared/utils';
import { ImgDialog } from '../../shared/components/img-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'equipamento-tipo',
    templateUrl: './equipamento-tipo.component.html'
})
export class EquipamentoTipoComponent implements OnInit {

    loading = true;
    filter: string;
    dataSource: EquipamentoTipo[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        { header: 'Sigla', attr: 'sigla' },
        { header: 'Categoria', attr: 'categoria' },
        { header: 'Informações Adicionais', attr: 'descricao' },
        {
            header: 'Imagens',
            template: (rowData: EquipamentoTipo) => rowData.imagens && rowData.imagens.length ? `<span class="material-symbols-outlined text-primary pointer">photo_library</span>` : '---',
            click: { 
              action: (rowData: EquipamentoTipo) => rowData.imagens && rowData.imagens.length ? this.openDialog(rowData) : null 
            }
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: { 
              action: (rowData: EquipamentoTipo) => this.router.navigateByUrl(`/equipamento/tipo/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private dialog: MatDialog,
        private tipoService: EquipamentoTipoService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.tipoService.getPaginated<EquipamentoTipo>(this.pagination.current, this.pagination.pageSize, this.filter).subscribe({
            next: (res: Pagination<EquipamentoTipo>) => {
                this.dataSource = res.data;
                this.dataSource = res.data.map((tipo) => ({ ...tipo, categoria: tipo.categoria?.nome }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    private openDialog(grupo: EquipamentoTipo) {
        this.dialog.open(ImgDialog, { data: grupo });
    }
}
