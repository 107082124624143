import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, RolesEnum } from 'src/app/core';
import { Breadcrumb, GenerateTableColumn, GenerateTablePagination, RemoveDialogService } from 'src/app/shared/components';
import { catchErr } from 'src/app/shared/utils';
import { AnotacaoService } from './anotacao.service';
import { Anotacao, Condutor } from '../condutor.model';
import { CondutorService } from '../condutor.service';
import { FormAnotacaoComponent } from './form-anotacao/form-anotacao.component';

@Component({
    selector: 'anotacao',
    templateUrl: './anotacao.component.html'
})
export class AnotacaoComponent implements OnInit {

    loading = true;
    dataSource: Anotacao[];
    condutorId: string;
    condutor: Condutor;
    beneficiariaId: string;
    breadcrumb: Breadcrumb[];
    isContratadoAdm: boolean;

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private anotacaoService: AnotacaoService,
        private condutorService: CondutorService,
        private dialog: MatDialog,
        private router: Router,
        private removeDialogService: RemoveDialogService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.condutorId = this.activatedRoute.snapshot.params['idCondutor'];
        this.beneficiariaId = this.activatedRoute.snapshot.params['idBeneficiaria'];
        this.isContratadoAdm = this.authService.user.role == RolesEnum.CONTRATADO_ADM;
        this.breadcrumb = this.isContratadoAdm ? [
            { label: 'Início' },
            { label: 'Visualizar Condutores', route: `/condutores` },
            { label: 'Anotaçoes' },
        ] : [
            { label: 'Início' },
            { label: 'Beneficiárias', route: '/beneficiaria' },
            { label: 'Visualizar beneficiaria', route: `/beneficiaria/${this.beneficiariaId}/visualizar`, queryParams: { tab: 'condutor' } },
            { label: 'Anotações' },
        ]

        this.getCondutor();
    }

    getCondutor() {
        this.loading = true;
        this.condutorService.getById<Condutor>(this.condutorId).subscribe({
            next: (res) => {
                this.condutor = res;
                this.condutor.anotacao = this.condutor.anotacao ? this.condutor.anotacao.sort((a, b) => new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()) : this.condutor.anotacao;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    showFormDialog() {
        const dialogRef = this.dialog.open(FormAnotacaoComponent, { data: { condutorId: this.condutorId } });
        dialogRef.afterClosed().subscribe((reload) => !!reload && this.getCondutor());
    }

    showRemoveDialog() {
        this.removeDialogService.show('TODAS as anotações', (dialogRef: MatDialogRef<any>) => {
            this.anotacaoService.removeByCondutor(this.condutorId).subscribe({
                next: () => {
                    this.snackBar.open('Anotações apagadas com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigateByUrl(this.isContratadoAdm ? `/condutores` : `/beneficiaria/${this.beneficiariaId}/visualizar?tab=condutor`);
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }
}
