import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../shared/components';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-equipamento',
    templateUrl: './equipamento.component.html',
})
export class EquipamentoComponent implements OnInit {

    selectedTab: number;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Equipamentos' },
    ];

    constructor(private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.selectedTab = this.getTab(this.activatedRoute.snapshot.queryParams['tab'])
    }

    private getTab(tabName: string) {
        switch (tabName) {
            case 'tipos': return 1;
            case 'grupos': return 2;
            case 'equipamentos': return 3;
            default: return 0;
        }
    }

}
