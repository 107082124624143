import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ConfigComponent, FormConfigComponent, ConfigService } from "./";

@NgModule({
    declarations: [
        ConfigComponent,
        FormConfigComponent,
    ],
    imports: [SharedModule],
    providers: [ConfigService]
})
export class ConfigModule { }