import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RemoveDialog } from './remove.dialog';

@Injectable({
    providedIn: 'root'
})
export class RemoveDialogService {

    constructor(private dialog: MatDialog,) {}

    show(name: string, success: Function, title?: string, modalMessage?: string, actionNameButton?: string, ) {
        this.dialog.open(RemoveDialog, {
            data: { name, title, modalMessage, actionNameButton, confirm: (dialogRef: MatDialogRef<RemoveDialog>) => success(dialogRef) }
        });
    }
}
