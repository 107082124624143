import { Component } from '@angular/core';
import { Breadcrumb } from 'src/app/shared/components';
import { Equipamento } from '../../equipamento.model';
import { ActivatedRoute } from '@angular/router';
import { EquipamentoService } from '../../equipamento.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';
import { Alocacao, AlocacaoService, Contrato } from 'src/app/contrato';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-view-equipamento',
    templateUrl: './view-equipamento.component.html'
})
export class ViewEquipamentoComponent {

    loading = true;
    equipamento = {} as Equipamento;
    alocacoes: Alocacao[];
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Equipamentos', route: '/equipamento', queryParams: { tab: 'equipamentos' } },
        { label: 'Visualizar equipamento' },
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private alocacaoService: AlocacaoService,
        private equipamentoService: EquipamentoService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.equipamento.id = this.activatedRoute.snapshot.params['id'];
        if (this.equipamento.id) {
            forkJoin([
                this.equipamentoService.getById<Equipamento>(this.equipamento.id),
                this.alocacaoService.getAll<Alocacao>(undefined, this.equipamento.id)
            ]).subscribe({
                next: ([resEquip, resAlocacao]) => {
                    this.equipamento = resEquip;
                    this.alocacoes = resAlocacao.data;
                }, error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading = false);
        }
    }
}
