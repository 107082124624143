export * from './equipamento.model';
export * from './equipamento.component';
export * from './equipamento.service';

export * from './equipamento-list/equipamento-list.component';
export * from './equipamento-list/form-equipamento/form-equipamento.component';
export * from './equipamento-list/view-equipamento/view-equipamento.component';

export * from './equipamento-categoria/equipamento-categoria.component';
export * from './equipamento-categoria/equipamento-categoria.service';
export * from './equipamento-categoria/form-equipamento-categoria/form-equipamento-categoria.component';

export * from './equipamento-grupo/equipamento-grupo.component';
export * from './equipamento-grupo/equipamento-grupo.service';
export * from './equipamento-grupo/form-equipamento-grupo/form-equipamento-grupo.component';

export * from './equipamento-tipo/equipamento-tipo.component';
export * from './equipamento-tipo/equipamento-tipo.service';
export * from './equipamento-tipo/form-equipamento-tipo/form-equipamento-tipo.component';