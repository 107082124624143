import { HttpErrorResponse } from "@angular/common/http";
import { NgModel } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

export function catchErr(err: HttpErrorResponse, snackBar: MatSnackBar) {
    if (err && err.error && err.error.errors && Array.isArray(err.error.errors)) {
        err.error.errors.map((e: { message: string }) => {
            snackBar.open(e.message, '✕', { panelClass: 'danger' });
        })
    } else if (err && err.error && err.error.message) {
        snackBar.open(err.error.message, '✕', { panelClass: 'danger' });
    } else {
        snackBar.open(`Error ${err.status} (${err.name})`, '✕', { panelClass: 'danger' });
    }
}

export function showInputError(el: NgModel, err: string) {
    return el.invalid && el.errors && el.errors[err]
}

export function convertToDatePicker(serverDate: string) {
    const dt = new Date(Date.parse(serverDate));
    const min = dt.getTime() / 1000 / 60; // convert gmt date to minutes
    const localNow = new Date().getTimezoneOffset(); // get the timezone
    const localTime = min + localNow; // get the local time with timezone
    const dateStr: any = new Date(localTime * 1000 * 60);
    return dateStr.toISOString();
}

export function getBadgeColor(status: any) {
    switch (status.toUpperCase()) {
        case "AGUARDANDO_AUDITORIA":
        case "AGUARDANDO_EXECUCAO":
        case "AGUARDANDO_FUNCIONARIO":
        case "AGUARDANDO_FISCAL":
            return 'bg-warning text-dark';
        case "AUDITADA_CANCELADA_INTEGRALMENTE":
        case "AUDITADA_CANCELADA_PARCIALMENTE":
        case "DIO_NAO_ACOLHIDO":
        case "DIO_NAO_ATESTADA":
            return 'bg-danger text-white';
        case "DEFINITIVA":
        case "EM_EXECUCAO":
            return 'bg-primary text-white';
        case "AUDITADA_ACEITA_INTEGRALMENTE":
        case "EXECUTADA":
        case "FINALIZADA_COM_DESVIO":
        case "FINALIZADA":
            return 'bg-success text-white';
        case "FINALIZANDO_ASSINADA":
        case "FINALIZANDO_RELATADA":
            return 'bg-pink text-white';
        default: 
            return 'bg-secondary text-white';
    }
}

export function formatHumanTime(time?: number, showSeconds?: boolean, type: 'minutes' | 'seconds' = 'minutes') {
    if (!time || isNaN(time) || time < 0) {
        return '---'
    }

    const SEC_IN_HR = 3600;
    const timeSeconds = type === 'minutes' ? time*60 : time;
    const days = Math.floor(timeSeconds / (SEC_IN_HR*24));
    const hours = Math.floor(timeSeconds % (SEC_IN_HR*24) / SEC_IN_HR);
    const minutes = Math.floor(timeSeconds % SEC_IN_HR / 60);
    const seconds = Math.floor(timeSeconds % 60);

    return `${days && days > 0 ? `${days}d ` : ''}
            ${hours && hours > 0 ? `${hours.toString().padStart(2,'0')}h ` : ''}
            ${minutes && minutes > 0 ? `${minutes.toString().padStart(2,'0')}min` : ''}
            ${showSeconds && seconds && seconds > 0 ? `${seconds.toString().padStart(2,'0')}s` : ''}`
}

export function convertToBase64(input: string): string {
    return btoa(input);
}

export function convertFromBase64(base64: string): string {
    return atob(base64);
}