import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService, Profiles, RolesEnum } from 'src/app/core';

@Directive({
    selector: '[accessControl]'
})
export class AccessControlDirective implements OnInit {
    @Input() accessControl: RolesEnum | RolesEnum[];

    constructor(
        private elementRef: ElementRef,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.elementRef.nativeElement.style.display = this.checkAccess(this.accessControl) ? "inherit" : "none";
    }

    checkAccess(role: RolesEnum | RolesEnum[]): boolean {
      if (Array.isArray(role)) {
        const userRoles = this.authService.user.realm_access ? this.authService.user.realm_access.roles : [];
        return role.some((access) => userRoles.includes(access));
      }

      return (Profiles as any)[role] ? this.authService.user.realm_access.roles.some((profile: string) => (Profiles as any)[profile] && (Profiles as any)[profile].includes(role)) : false;
    }
}
