import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { showInputError } from 'src/app/shared/utils';
import { AuthService } from './auth.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styles: [`:host { display: flex; flex-direction: column; height: 100%; }`],
})
export class AuthComponent implements OnInit {

    loading: boolean;
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        if (this.authService.token)
            this.router.navigateByUrl('/dio');
    }
    
    sigIn(form: NgForm) {
        this.loading = true;
        this.authService.signInForm(form.form.value).then(() => {
            this.loading = false;
            const url = this.activatedRoute.snapshot.queryParams['returnUrl'] || '';
            this.router.navigate([url]);
        }).catch((err: any) => {
            this.loading = false;
            this.snackBar.open(err.error.error_description || 'Error', '✕', { panelClass:'danger' });
        });
    }

}
