<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} beneficiaria</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Nome</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="beneficiaria.nome" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- CNPJ -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>CNPJ</mat-label>
                    <input matInput name="cnpj" #cnpj="ngModel" [(ngModel)]="beneficiaria.cnpj" mask="CPF_CNPJ" required>
                    <mat-error *ngIf="showInputError(cnpj, 'required')">CNPJ é obrigatório</mat-error>
                </mat-form-field>

                <!-- RESPONSÁVEIS -->
                <div class="col-12">
                    <ng-select name="user" [items]="responsaveis$ | async" bindValue="id" [multiple]="true" required
                        placeholder="Responsáveis" typeToSearchText="Buscar usuário..." notFoundText="Nenhum item encontrado" loadingText="Buscando..."
                        [loading]="loading.responsaveis" [typeahead]="responsaveisInput$" [(ngModel)]="beneficiaria.responsaveis" [compareWith]="compareWith">
                        
                        <!-- selected -->
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right" (click)="removeResponsavel(item); clear(item);" aria-hidden="true">×</span>
                            <span class="ng-value-label">{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- lista -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <img class="rounded-circle me-2 img-cover" width="25" height="25" [src]="item.avatarUrl || 'assets/img/default_user.jpeg'" />
                            <span>{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- add novo -->
                        <ng-template ng-footer-tmp>
                            <div class="d-flex align-items-center text-primary pointer" (click)="routeWithStorage('/usuario/novo')">
                                <mat-icon class="me-2">person_add</mat-icon> Adicionar novo responsável
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- OBJETO DO CONTRATO -->
                <mat-form-field class="col-12">
                    <mat-label>Objeto do Contrato</mat-label>
                    <input matInput name="objetoContrato" #objetoContrato="ngModel" [(ngModel)]="beneficiaria.objetoContrato">
                </mat-form-field>

                <!-- DESCRIPTION -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="beneficiaria.descricao"></textarea>
                </mat-form-field>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="beneficiaria.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" (click)="voltar()">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
