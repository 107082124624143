import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { catchErr } from '../shared/utils';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from '../shared/components';
import { Contrato, ContratoFilter, ContratoService } from './';
import * as dayjs from 'dayjs';
import { pickBy } from 'lodash-es';

@Component({
    selector: 'app-contrato',
    templateUrl: './contrato.component.html',
    providers: [CurrencyPipe]
})
export class ContratoComponent implements OnInit {

    loading = true;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Contratos' },
    ]

    filters = {} as ContratoFilter;
    toggleFilters: boolean;

    dataSource: Contrato[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        { header: 'Beneficiaria', attr: 'beneficiaria' },
        { header: 'Início', attr: 'inicioVigencia' },
        { header: 'Fim', attr: 'fimVigencia' },
        { header: 'Valor Contratado', attr: 'valorContratado' },
        { header: 'Valor Executado', attr: 'valorExecutado' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Visualizar', color: 'accent' },
            click: {
              action:(rowData: Contrato) => this.router.navigateByUrl(`/contrato/${rowData.id}/visualizar`)
            }
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
              action: (rowData: Contrato) => this.router.navigateByUrl(`/contrato/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private contratoService: ContratoService,
        private currencyPipe: CurrencyPipe,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        const filters = { ...this.filters }

        if(/^\d+$/.test(filters.beneficiaria)) {
            filters.beneficiariaCNPJ = filters.beneficiaria;
        } else {
            filters.beneficiariaNome = filters.beneficiaria;
        }

        filters.startInicioVigencia = filters.startInicioVigencia ? dayjs(filters.startInicioVigencia).format('YYYY-MM-DD') : filters.startInicioVigencia;
        filters.endInicioVigencia = filters.endInicioVigencia ? dayjs(filters.endInicioVigencia).format('YYYY-MM-DD') : filters.endInicioVigencia;
        filters.startFimVigencia = filters.startFimVigencia ? dayjs(filters.startFimVigencia).format('YYYY-MM-DD') : filters.startFimVigencia;
        filters.endFimVigencia = filters.endFimVigencia ? dayjs(filters.endFimVigencia).format('YYYY-MM-DD') : filters.endFimVigencia;
        filters.startCreatedAt = filters.startCreatedAt ? dayjs(filters.startCreatedAt).format('YYYY-MM-DD') : filters.startCreatedAt;
        filters.endCreatedAt = filters.endCreatedAt ? dayjs(filters.endCreatedAt).format('YYYY-MM-DD') : filters.endCreatedAt;

        this.contratoService.getPaginated<Contrato>(this.pagination.current, this.pagination.pageSize, undefined, pickBy(filters)).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((contrato) => ({
                    ...contrato,
                    beneficiaria: contrato.beneficiaria!.nome,
                    valorExecutado: this.currencyPipe.transform(contrato.valorExecutado, 'BRL')!,
                    valorContratado: this.currencyPipe.transform(contrato.valorContratado, 'BRL')!,
                    inicioVigencia: dayjs(contrato.inicioVigencia).format('DD/MM/YYYY'),
                    fimVigencia: dayjs(contrato.fimVigencia).format('DD/MM/YYYY')
                }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    clean(){
        this.filters.nome = '';
        this.filters.beneficiaria = '';
        this.filters.beneficiariaNome = '';
        this.filters.beneficiariaCNPJ = '';
        this.filters.startCreatedAt = '';
        this.filters.endCreatedAt = '';
        this.filters.startInicioVigencia = '';
        this.filters.endInicioVigencia = '';  
        this.filters.startFimVigencia = '';      
        this.filters.endFimVigencia = '';
    }
}
