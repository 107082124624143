<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-tab-group mat-stretch-tabs="false" [selectedIndex]="selectedTab">
            <mat-tab label="Orgão">
                <mat-card-content>
                    <orgao></orgao>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Região Administrativa">
                <mat-card-content>
                    <regiao-admin></regiao-admin>
                </mat-card-content>
            </mat-tab>
            <!--
            <mat-tab label="Polo">
                <mat-card-content>
                    <polo></polo>
                </mat-card-content>
            </mat-tab>
            -->
        </mat-tab-group>
    </mat-card>
</div>
