<div class="d-flex justify-content-center align-items-center px-3 py-5 bg-waves">
    <mat-card class="col-12 col-md-4">
        <mat-card-content>
            <div class="text-center p-3 mb-4">
                <img height="60" src="/assets/img/logo.png" alt="logo">
            </div>
            <form class="d-flex flex-column" name="form" (ngSubmit)="form.valid && sigIn(form)" #form="ngForm" novalidate>
                <!-- EMAIL -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" name="username" #username="ngModel" ngModel required>
                    <mat-error *ngIf="showInputError(username, 'required')">Email é obrigatório</mat-error>
                </mat-form-field>

                <!-- SENHA -->
                <mat-form-field class="w-100 mb-2">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" name="password" #password="ngModel" ngModel required minlength="6">
                    <mat-error *ngIf="showInputError(password, 'required')">Senha é obrigatório</mat-error>
                    <mat-error *ngIf="showInputError(password, 'minlength')">Mínimo de 6 caracteres</mat-error>
                </mat-form-field>

                <button mat-flat-button color="primary" class="btn-lg" [disabled]="form.form.invalid || loading">
                    <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
                    <span *ngIf="!loading">EFETUAR LOGIN</span>
                </button>
                
                <div class="text-center my-3">
                    <button type="button" mat-button color="primary" [routerLink]="['/reset-password']">Esqueceu a senha?</button>
                </div>
                
                <mat-divider></mat-divider>
                <div class="text-center mb-2">
                    <div class="text-muted mt-4 mb-2">Não possui cadastro?</div>
                    <button type="button" mat-button color="primary" [routerLink]="['/register']">CRIE SUA CONTA!</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>

        