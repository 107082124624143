import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Dio } from '../dio.model';
import { isEmpty } from 'lodash-es';
import { AuthService } from 'src/app/core';

@Component({
    selector: 'print-dio',
    templateUrl: './print-dio.component.html',
    styleUrls: ['./print-dio.component.css']
})
export class PrintDioComponent implements OnChanges {

    @Input() dio: Dio;

    now = new Date();

    constructor(public authService: AuthService) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['dio'] && changes['dio'].currentValue) {
            this.populateNullValues(this.dio);
        }
    }

    populateNullValues(obj: any) {
        for (var key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                this.populateNullValues(obj[key]);
            } else if (obj[key] == null) {
                obj[key] = '';
            }
        }
    }

    hasDio() {
        return !isEmpty(this.dio);
    }

}
