<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">Nova Ausência</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>
                <!-- DATA INICIO -->                
                <mat-form-field class="col-12 col-md-3">
                    <mat-label>Data de Inicio</mat-label>
                    <input matInput [matDatepicker]="pickerStart" name="dataInicio" #dataInicio="ngModel"
                        autocomplete="off" [(ngModel)]="ausencia.dataInicio" required>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                    <mat-error *ngIf="showInputError(dataInicio, 'required')">Data de Inicio é obrigatório</mat-error>
                </mat-form-field>

                <!-- DATA FIM -->                
                <mat-form-field class="col-12 col-md-3">
                    <mat-label>Data de Fim</mat-label>
                    <input matInput [matDatepicker]="pickerEnd" name="dataFim" #dataFim="ngModel"
                        autocomplete="off" [(ngModel)]="ausencia.dataFim" required>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                    <mat-error *ngIf="showInputError(dataFim, 'required')">Data de Fim é obrigatório</mat-error>
                </mat-form-field>


                <!-- MOTIVO -->
                <mat-form-field class="col-12">
                    <mat-label>Motivo</mat-label>
                    <textarea matInput name="motivo" #motivo="ngModel" [(ngModel)]="ausencia.motivo" required></textarea>
                    <mat-error *ngIf="showInputError(motivo, 'required')">Motivo é obrigatório</mat-error>
                </mat-form-field>

                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" (click)="voltar()">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
