<mat-list class="d-flex flex-column d-print-none h-100">
    <div class="p-3 mb-2">
        <img height="30" src="/assets/img/logo-white.png" alt="logo">
    </div>
    <mat-list-item *ngFor="let menu of menus" [routerLink]="[menu.route]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: !!menu.exact }" [accessControl]="menu.accessControl" >
        <mat-icon [fontSet]="menu.fontSet" matListItemIcon class="me-2">{{menu.icon}}</mat-icon>
        <div matListItemTitle>
            {{menu.name}}
            <span class="status-badge bg-danger" *ngIf="menu.badge">{{menu.badge}}</span>
        </div>
    </mat-list-item>
    <mat-list-item class="mt-auto" (click)="authService.signOut()">
        <mat-icon matListItemIcon class="me-2">logout</mat-icon>
        <div matListItemTitle>Sair</div>
    </mat-list-item>
</mat-list>