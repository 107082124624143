import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { Tarifa } from '../tarifa.model';
import { EquipamentoGrupo, EquipamentoGrupoService } from 'src/app/equipamento';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TarifaService } from '../tarifa.service';
import { Observable, forkJoin } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-form-tarifa',
    templateUrl: './form-tarifa.component.html'
})
export class FormTarifaComponent implements OnInit {

    loading: any = { main: true, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    tarifa = {} as Tarifa;
    grupos: EquipamentoGrupo[];

    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private tarifaService: TarifaService,
        private grupoService: EquipamentoGrupoService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.tarifa.id = this.activatedRoute.snapshot.params['idTarifa'];
        this.tarifa.contrato = { id: this.activatedRoute.snapshot.params['idContrato'] };
        this.title = this.tarifa.id ? 'Editar' : 'Nova';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Contratos', route: '/contrato' },
            { label: 'Visualizar contrato', route: `/contrato/${this.tarifa.contrato.id}/visualizar`, queryParams: { tab: 'tarifa' } },
            { label: this.title + ' tarifa' },
        ];

        forkJoin([
            this.tarifaService.getAll<Tarifa>(this.tarifa.contrato.id),
            this.grupoService.getAll<EquipamentoGrupo>()
        ]).subscribe({
            next: ([grupoTarifa, grupos]) => {
                this.grupos = !this.tarifa.id ? grupos.data.filter((grupo) => !grupoTarifa.data.some((tarifa) => tarifa.grupoEquipamento.id == grupo.id)) : grupos.data;
                if (this.tarifa.id) {
                    this.tarifaService.getById<Tarifa>(this.tarifa.id).subscribe({
                        next: (res) => this.tarifa = res,
                        error: (err) => catchErr(err, this.snackBar)
                    }).add(() => this.loading.main = false);
                }
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    compareWith(item: any, selected: any) {
        return selected && item == selected.id
    }

    save() {
        this.loading.save = true;
        let request: Observable<Tarifa>

        const tarifaToSave = { ...this.tarifa }
        tarifaToSave.contrato = tarifaToSave.contrato.id;
        if (tarifaToSave.id) {
            tarifaToSave.grupoEquipamento = tarifaToSave.grupoEquipamento.id;
            request = this.tarifaService.update<Tarifa>(tarifaToSave.id, tarifaToSave)
        } else {
            request = this.tarifaService.create<Tarifa>(tarifaToSave)
        }

        request.subscribe({
            next: () => {
                this.snackBar.open('Tarifa salva com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigateByUrl(`/contrato/${this.tarifa.contrato.id}/visualizar?tab=tarifa`);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show('tarifa', (dialogRef: MatDialogRef<any>) => {
            this.tarifaService.remove<Tarifa>(this.tarifa.id).subscribe({
                next: () => {
                    this.snackBar.open('Tarifa apagada com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigateByUrl(`/contrato/${this.tarifa.contrato.id}/visualizar?tab=tarifa`);
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    selectGrupo(grupoSelectedId: string) {
        if (this.grupos && this.grupos.length) {
            const grupoSelected = this.grupos.find((grupo) => grupo.id == grupoSelectedId);
            if (grupoSelected) {
                this.tarifa.grupoTarifacaoTipo = grupoSelected.tarifacoes;
                this.tarifa.valorHoraDisponivelMaquina = grupoSelected.valorHoraDisponivelMaquina;
                this.tarifa.valorHoraDisponivelVeiculo = grupoSelected.valorHoraDisponivelVeiculo;
                this.tarifa.valorHoraProduzida = grupoSelected.valorHoraProduzida;
                this.tarifa.valorKmRodado = grupoSelected.valorKmRodado;
            }
        }
    }
}
