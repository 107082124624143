import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, GenerateTableColumn, RemoveDialogService } from 'src/app/shared/components';
import { Ausencia, UsuarioResponse } from '../usuario.model';
import { catchErr } from 'src/app/shared/utils';
import { UsuarioService } from '../usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core';
import { UsuarioAusenciaService } from '../usuario-ausencia.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-view-usuario',
    templateUrl: './view-usuario.component.html'
})
export class ViewUsuarioComponent implements OnInit {

    loading: boolean;
    usuario = {} as UsuarioResponse;
    ausencias: Ausencia[];
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Usuários', route: '/usuario' },
        { label: 'Visualizar usuário' },
    ]

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private ausenciaService: UsuarioAusenciaService,
        private snackBar: MatSnackBar,
        private removeDialogService: RemoveDialogService,
    ) { }

    columns: GenerateTableColumn[] = [
        { header: 'Data Inicio', attr: 'dataInicio' },
        { header: 'Data Fim', attr: 'dataFim' },
        { header: 'Motivo', attr: 'motivo' },
        { header: 'Responsável Cadastro', attr: 'responsavel' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Apagar', color: 'warn' },
            click: { action: (rowData: Ausencia) => this.showRemoveDialog(rowData)}
        }
    ]

    ngOnInit() {
        this.loading = true;
        this.usuarioService.getById<UsuarioResponse>(this.activatedRoute.snapshot.params['id']).subscribe({
            next: (res) => {
                this.usuario = res,

                this.ausenciaService.getAusencias(this.usuario.id).subscribe({
                    next: (res) => {
                        this.ausencias = res
                    },
                    error: (err) => catchErr(err, this.snackBar)
                });
                
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);        
    }

    sendResetPasswordEmail() {
        this.loading = true

        const userId = this.activatedRoute.snapshot.params['id'];
        console.log('user id on page: ', userId);
        this.usuarioService.sendResetPasswordEmail(userId).subscribe({
            next: (res) => {
                console.log('sucesso: ', res);
                this.snackBar.open('E-mail para redefinição de senha enviado com sucesso!', '✕', { panelClass: ['success'] });
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    showRemoveDialog(ausencia: Ausencia) {
        const title = ausencia.dataInicio + ' até ' + ausencia.dataFim;
        this.removeDialogService.show(title, (dialogRef: MatDialogRef<any>) => {
            this.ausenciaService.remove<Ausencia>(ausencia.id).subscribe({
                next: () => {
                    this.snackBar.open('Serviço apagado com sucesso!', '✕', { panelClass: ['success'] });
                    dialogRef.close();                    
                }, error: (err) => catchErr(err, this.snackBar)
            });
        });        
    }

}
