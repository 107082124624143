<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} contrato</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm"
                novalidate>
                <!-- NOME -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Nome/Código do contrato</mat-label>
                    <input matInput name="nome" #nome="ngModel" [(ngModel)]="contrato.nome" required>
                    <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                </mat-form-field>

                <!-- INICIO -->
                <mat-form-field class="col-12 col-md-3">
                    <mat-label>Inicio</mat-label>
                    <input matInput [matDatepicker]="pickerStart" name="inicioVigencia" #inicioVigencia="ngModel"
                        autocomplete="off" [(ngModel)]="contrato.inicioVigencia" required>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                    <mat-error *ngIf="showInputError(inicioVigencia, 'required')">Inicio da vigência é
                        obrigatório</mat-error>
                </mat-form-field>

                <!-- FIM -->
                <mat-form-field class="col-12 col-md-3">
                    <mat-label>Fim</mat-label>
                    <input matInput [matDatepicker]="pickerEnd" name="fimVigencia" #fimVigencia="ngModel"
                        autocomplete="off" [(ngModel)]="contrato.fimVigencia" required>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                    <mat-error *ngIf="showInputError(fimVigencia, 'required')">Fim da vigência é obrigatório</mat-error>
                </mat-form-field>

                <!-- BENEFICIARIA -->
                <div class="col-12 col-md-6">
                    <ng-select name="beneficiaria" [items]="beneficiaria$ | async" bindLabel="nome" required placeholder="Beneficiaria"
                        typeToSearchText="Buscar beneficiaria..." notFoundText="Nenhum item encontrado"
                        loadingText="Buscando..." [loading]="loading.beneficiaria" [typeahead]="beneficiariaInput$"
                        (change)="setBeneficiaria($event)" [(ngModel)]="contrato.beneficiaria" [compareWith]="compareWith">

                        <!-- lista -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="d-flex flex-column py-2">
                                <span class="lh-sm">{{ item.nome }}</span>
                                <div class="fs-7 opacity-50">{{ item.cnpj | mask:'CPF_CNPJ' }}</div>
                            </div>
                        </ng-template>

                        <!-- add nova -->
                        <ng-template ng-footer-tmp>
                            <div class="d-flex align-items-center text-primary pointer"
                                (click)="routeWithStorage('/beneficiaria/nova', 'beneficiaria')">
                                <mat-icon class="me-2">add_business</mat-icon> Adicionar nova beneficiaria
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- CONTRATADO -->
                <div class="col-12 col-md-6">
                    <ng-select name="responsavel" [items]="responsaveis" bindValue="id" placeholder="Responsável" required
                        [searchFn]="searchFn" [(ngModel)]="contrato.contratado" [compareWith]="compareWith">
                      <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                        <img class="rounded-circle me-2 img-cover" width="25" height="25"
                        [src]="item.avatarUrl || 'assets/img/default_user.jpeg'" /> {{ item.firstName }} {{ item.lastName }}
                      </ng-template>
                      <ng-template ng-footer-tmp>
                          <div class="d-flex align-items-center text-primary pointer"
                            (click)="routeWithStorage('/usuario/novo', 'contratado', 'CONTRATADO_ADM')">
                          <mat-icon class="me-2">person_add</mat-icon> Adicionar novo contratado
                        </div>
                      </ng-template>
                    </ng-select>
                </div>

                <!-- RESPONSÁVEL -->
                <div class="col-12 col-md-6">
                    <ng-select name="contratanteResponsavel" [items]="responsavel$ | async" bindValue="id" required
                        placeholder="Servidor Novacap Responsável" typeToSearchText="Buscar usuário..."
                        notFoundText="Nenhum item encontrado" loadingText="Buscando..." [loading]="loading.responsavel"
                        [typeahead]="responsavelInput$" [(ngModel)]="contrato.contratanteResponsavel"
                        [compareWith]="compareWith">

                        <!-- selected -->
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            <span class="ng-value-label">{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- lista -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <img class="rounded-circle me-2 img-cover" width="25" height="25"
                                [src]="item.avatarUrl || 'assets/img/default_user.jpeg'" />
                            <span>{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- add novo -->
                        <ng-template ng-footer-tmp>
                            <div class="d-flex align-items-center text-primary pointer"
                                (click)="routeWithStorage('/usuario/novo', 'contratanteResponsavel', 'GESTAO_ADMIN')">
                                <mat-icon class="me-2">person_add</mat-icon> Adicionar novo responsável
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- SUPLENTE -->
                <div class="col-12 col-md-6">
                    <ng-select name="contratanteSuplente" [items]="suplente$ | async" bindValue="id"
                        loadingText="Buscando..." required placeholder="Servidor Novacap Responsável (suplente)"
                        typeToSearchText="Buscar usuário..." notFoundText="Nenhum item encontrado"
                        [loading]="loading.suplente" [typeahead]="suplenteInput$"
                        [(ngModel)]="contrato.contratanteSuplente" [compareWith]="compareWith">

                        <!-- selected -->
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            <span class="ng-value-label">{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- lista -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <img class="rounded-circle me-2 img-cover" width="25" height="25"
                                [src]="item.avatarUrl || 'assets/img/default_user.jpeg'" />
                            <span>{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- add novo -->
                        <ng-template ng-footer-tmp>
                            <div class="d-flex align-items-center text-primary pointer"
                                (click)="routeWithStorage('/usuario/novo', 'contratanteSuplente', 'GESTAO_ADMIN')">
                                <mat-icon class="me-2">person_add</mat-icon> Adicionar novo suplente
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- VALOR CONTRATADO -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Valor contratado</mat-label>
                    <input matInput name="valorContratado" #valorContratado="ngModel"
                        [(ngModel)]="contrato.valorContratado" currencyMask
                        [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }" autocomplete="off"
                        required>
                    <mat-error *ngIf="showInputError(valorContratado, 'required')">Valor contratado é
                        obrigatório</mat-error>
                </mat-form-field>

                <!-- VALOR EXECUTADO -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Valor executado</mat-label>
                    <input matInput name="valorExecutado" #valorExecutado="ngModel"
                        [(ngModel)]="contrato.valorExecutado" currencyMask
                        [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }" autocomplete="off"
                        required>
                    <mat-error *ngIf="showInputError(valorExecutado, 'required')">Valor executado é
                        obrigatório</mat-error>
                </mat-form-field>

                <!-- SEI -->
                <mat-form-field class="col-12">
                    <mat-label>SEI</mat-label>
                    <input matInput name="sei" #sei="ngModel" [(ngModel)]="contrato.sei">
                </mat-form-field>

                <!-- OBJETO DO CONTRATO -->
                <mat-form-field class="col-12">
                    <mat-label>Objeto do Contrato</mat-label>
                    <input matInput name="objetoContrato" #objetoContrato="ngModel"
                        [(ngModel)]="contrato.objetoContrato">
                </mat-form-field>

                <!-- DESCRIÇÃO -->
                <mat-form-field class="col-12">
                    <mat-label>Informações Adicionais</mat-label>
                    <textarea matInput name="descricao" #descricao="ngModel"
                        [(ngModel)]="contrato.descricao"></textarea>
                </mat-form-field>

                <!-- Ativo -->
                <mat-form-field class="col-12 col-md-4" *ngIf="!contrato.ativo">
                    <mat-label>Ativo</mat-label>
                    <input matInput name="ativo" #ativo="ngModel"
                        [(ngModel)]="contrato.ativo" disabled>    
                </mat-form-field>

                <!-- DATA INATIVACAO -->
                <mat-form-field class="col-12 col-md-4"  *ngIf="!contrato.ativo">
                    <mat-label>Data Inativação</mat-label>
                    <input matInput name="dataInativacao" #dataInativacao="ngModel"
                        [(ngModel)]="contrato.dataInativacao" disabled>     
                </mat-form-field>

                <!-- RESPONSAVEL INATIVACAO -->
                <mat-form-field class="col-12 col-md-4"  *ngIf="!contrato.ativo">
                    <mat-label>Responsável Inativacao</mat-label>
                    <input matInput name="responsavelInativacao" #responsavelInativacao="ngModel"
                        [(ngModel)]="contrato.responsavelInativacao" disabled> 
                </mat-form-field>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="contrato.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4"
                        (click)="showRemoveDialog()" [disabled]="!contrato.ativo">INATIVAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4"
                        [routerLink]="['/contrato']">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100"
                        [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>