export interface Contrato {
	id: string;
	alocacoes?: any[];
	nome: string;
	descricao: string;
	contratado: any;
	contratanteResponsavel: any;
	contratanteSuplente: any;
	inicioVigencia: string;
	fimVigencia: string;
	valorContratado: any;
	valorExecutado: any;
	objetoContrato: string;
	sei: string;
	beneficiariaId?: string;
	beneficiaria?: any;
	ativo?: boolean;
	dataInativacao?: string;
	responsavelInativacao?: string;
}

export interface ContratoFilter {
	nome: string;
	beneficiaria: string;
  beneficiariaCNPJ: string;
  beneficiariaNome: string;
	startInicioVigencia: string;
	endInicioVigencia: string;
	startFimVigencia: string;
	endFimVigencia: string;
	startCreatedAt: string;
	endCreatedAt: string;
}