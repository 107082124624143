import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericCRUDService } from '../shared/services';
import { Data, Pagination } from '../shared/models';
import { Contrato } from './contrato.model';
import { Faturamento } from '../financeiro';

@Injectable({
    providedIn: 'root'
})
export class ContratoService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/contrato`;
    }

    override getAll<T>(ativo?: boolean): Observable<Data<T>> {
        let queryParamAtivo = '';

        if(ativo){
            queryParamAtivo += '&ativo=true';
        }

        return this.http.get<Data<T>>(`${this.url}?removePagination=true${queryParamAtivo}`);
    }

    override getPaginated<Contrato>(page: number, pageSize: number, beneficiariaId?: string, filters?: any): Observable<Pagination<Contrato>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        beneficiariaId && params.append('beneficiariaId', `${beneficiariaId}`);

        if (filters)
            for (const key in filters)
                params.append(key, filters[key]);

        return this.http.get<Pagination<Contrato>>(`${this.url}?${params}`);
    }

    getAllByBeneficiaria(beneficiariaId: string) {
        const params = new URLSearchParams();
        params.append('removePagination', `true`);
        params.append('beneficiariaId', beneficiariaId);
        return this.http.get<Data<Contrato>>(`${this.url}?${params}`);
    }

    getFaturamento(contratoId: string, startDate: string, endDate: string) {
        const params = new URLSearchParams();
        params.append('startDate', startDate);
        params.append('endDate', endDate);
        return this.http.get<Faturamento>(`${this.url}/faturamento/${contratoId}?${params}`);
    }
}
