import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';
import * as translate from '../jsons/translate.json';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {

    transform(value: any) {
        return get((translate as any), value) || value
    }
}
