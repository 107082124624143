import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface RemoveDialogData {
    name: string;
    title: string;
    modalMessage: string;
    actionNameButton: string;
    confirm: Function;
    reject?: Function;
}

@Component({
    selector: 'remove-dialog',
    template: `
        <div mat-dialog-title [innerHTML]="title"></div>
        <div mat-dialog-content [innerHTML]="message"></div>
        <div mat-dialog-actions class="px-3 pb-3 justify-content-end">
            <button mat-button class="px-3" (click)="reject()">Fechar</button>
            <button mat-flat-button color="warn" (click)="confirm()">
                <mat-spinner class="btn-spinner" *ngIf="load"></mat-spinner>
                <span *ngIf="!load">{{ actionNameButton }}</span>
            </button>
        </div>`
})
export class RemoveDialog {
    constructor(
        public dialogRef: MatDialogRef<RemoveDialog>,
        @Inject(MAT_DIALOG_DATA) public data: RemoveDialogData,
    ) { }

    title = this.data?.title || `Apagar`;
    load = false;
    message = this.data?.modalMessage || `Deseja apagar <strong>${this.data.name}</strong>?`
    actionNameButton = this.data?.actionNameButton || `Apagar`;
      
    confirm() {
        this.data.confirm(this.dialogRef);
    }

    reject() {
        this.data.reject ? this.data.reject(this.dialogRef) : this.dialogRef.close();
    }

    showLoading(load: boolean) {
        this.load = load;
    }
}