<mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>

<div class="row" *ngIf="!loading">
    <div class="col-12 d-flex align-items-end mb-2">
        <h1 class="fs-3 fw-light mb-0">{{ beneficiaria.nome }}</h1>
        <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/beneficiaria', beneficiaria.id, 'editar']">EDITAR BENEFICIARIA</button>
    </div>
    <div class="col-12 mb-3">
        <mat-divider></mat-divider>
    </div>
    <div class="col-12 mb-3">
        <span class="fw-bold fs-7 opacity-50">CNPJ</span>
        <div class="lh-sm">{{ beneficiaria.cnpj | mask:'CPF_CNPJ' }}</div>
    </div>
    <div class="col-12 mb-3">
        <span class="fw-bold fs-7 opacity-50">DESCRICÃO</span>
        <div class="lh-sm">{{ beneficiaria.descricao || '---' }}</div>
    </div>

    <!-- RESPONSÁVEIS -->
    <div class="col-12" *ngIf="beneficiaria.responsaveis && beneficiaria.responsaveis.length">
        <span class="fw-bold fs-7 opacity-50">RESPONSÁVEIS</span>
        <mat-list role="list" class="list-border">
            <mat-list-item class="py-1 ps-2 pe-0" role="listitem" *ngFor="let responsavel of beneficiaria.responsaveis">
                <div class="d-flex align-items-center">
                    <img height="40" width="40" class="img-cover rounded-circle bg-default" [src]="responsavel.avatarUrl || '/assets/img/default_user.jpeg'" />
                    <div class="d-flex flex-column ms-3 me-auto">
                        <span class="lh-sm">{{ responsavel.firstName }} {{ responsavel.lastName }}</span>
                        <span class="opacity-50 fs-7">{{ responsavel.email }}</span>
                    </div>
                    <button mat-icon-button color="primary" [routerLink]="['/usuario', responsavel.id, 'visualizar']" >
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
