import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchErr } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Orgao, OrgaoService } from '../../..';

@Component({
    selector: 'detail-orgao',
    templateUrl: './detail-orgao.component.html',
})
export class DetailOrgaoComponent implements OnInit {

    loading: boolean;
    orgao = {} as Orgao;

    constructor(
        private activatedRoute: ActivatedRoute,
        private orgaoService: OrgaoService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.loading = true;
        this.orgaoService.getById<Orgao>(this.activatedRoute.snapshot.params['idOrgao']).subscribe({
            next: (res) => this.orgao = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    checkAdress() {
        return this.orgao.endereco.cep || this.orgao.endereco.rua || this.orgao.endereco.bairro || this.orgao.endereco.cidade || this.orgao.endereco.complemento;
    }
}
