<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>
        <mat-tab-group mat-stretch-tabs="false" [selectedIndex]="selectedTab" *ngIf="!loading">
            <mat-tab label="Detalhes">
                <mat-card-content>
                    <detail-contrato [contrato]="contrato"></detail-contrato>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Tarifas">
                <mat-card-content>
                    <tarifa [contrato]="contrato"></tarifa>
                </mat-card-content>
            </mat-tab>
            <mat-tab label="Alocação de Equipamentos">
                <mat-card-content>
                    <alocacao [contrato]="contrato"></alocacao>
                </mat-card-content>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>