import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ApmService } from '@elastic/apm-rum-angular';
import { init as initApm } from '@elastic/apm-rum';
import { environment } from './environments/environment';

const apm = initApm({
  serviceName: 'sisdio-web',
  serverUrl: environment.apmServiceUrl,
  environment: environment.production ? 'production' : 'development',
});

apm.addFilter((payload) => {
  //payload['secretToken'] = 'M29aZUJKQUIzT2JDVHVpejNYRnE6MWlPc29ONGlUMS1BcHVwM2dQYmg1Zw==';
  payload['secretToken'] = 'eZoKGw1E7PPUGcEHBU';
  return payload;
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
