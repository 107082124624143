import { Component } from '@angular/core';
import { LocaisApresentacaoService } from '../../..';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';
import { LocaisApresentacao } from './locais-apresentacao.model';

@Component({
    selector: 'locais-apresentacao',
    templateUrl: './locais-apresentacao.component.html',
})
export class LocaisApresentacaoComponent {

    loading: boolean;
    dataSource: LocaisApresentacao[];
    orgaoId: string;

    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        { header: 'Orgão', attr: 'orgaoName' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: { action: (rowData: LocaisApresentacao) => this.router.navigateByUrl(`/localidade/orgao/${this.orgaoId}/locais-apresentacao/${rowData.id}/editar`) }
        }
    ]

    constructor(
        private activatedRoute: ActivatedRoute,
        private locaisApresentacaoService: LocaisApresentacaoService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.orgaoId = this.activatedRoute.snapshot.params['idOrgao'];
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.locaisApresentacaoService.getPaginated<LocaisApresentacao>(this.pagination.current, this.pagination.pageSize, this.orgaoId).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((data) => ({
                  ...data,
                  orgaoName: data.orgao?.nome
                }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
