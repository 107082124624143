import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, catchError, concat, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { UsuarioResponse, UsuarioService } from 'src/app/usuario';
import { FuncionarioService } from '../funcionario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Funcionario } from '../../../orgao.model';
import { catchErr } from 'src/app/shared/utils';
import { Pagination } from 'src/app/shared/models';
import { RolesEnum } from 'src/app/core';

@Component({
    selector: 'dialog-form-executor-local',
    templateUrl: './form-funcionario.component.html'
})
export class FormFuncionarioComponent implements OnInit {

    loading = { save: false, usuario: false };
    user: UsuarioResponse;

    usuario$: Observable<UsuarioResponse[]>;
    usuarioInput$ = new Subject<string>();

    constructor(
        private dialogRef: MatDialogRef<FormFuncionarioComponent>,
        private funcionarioLocalService: FuncionarioService,
        private snackBar: MatSnackBar,
        private usuarioService: UsuarioService,

        @Inject(MAT_DIALOG_DATA) public data: { orgaoId: string },
    ) { }

    ngOnInit() {
        this.getUsuarios();
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>
        
        const funcionarioToSave = {
            orgao: this.data.orgaoId,
            userVirtualId: this.user.id
        } as Funcionario;

        request = funcionarioToSave.id
            ? this.funcionarioLocalService.update<Funcionario>(funcionarioToSave.id, funcionarioToSave)
            : this.funcionarioLocalService.create<Funcionario>(funcionarioToSave)

        request.subscribe({
            next: () => {
                this.snackBar.open('Funcionário salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    private getUsuarios() {
        this.usuario$ = concat(
            of([]), // default items
            this.usuarioInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.loading.usuario = true),
                switchMap((term) => {
                    return term ?
                        this.usuarioService.getPaginated<UsuarioResponse>(0, 20, term, RolesEnum.FUNCIONARIO).pipe(
                            map((res: Pagination<any>) => res.data), // select by attr
                            catchError(() => of([])), // empty list on error
                            tap(() => this.loading.usuario = false)
                        ) : new Promise(() => this.loading.usuario = false) // not search all
                })
            ) as Observable<UsuarioResponse[]>
        );
    }
}
