import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, showInputError } from 'src/app/shared/utils';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styles: [`:host { display: flex; flex-direction: column; height: 100%; }`],
})
export class ResetPasswordComponent implements OnInit {

    loading: boolean;
    key: string;
    showInputError = showInputError;

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.key = this.activatedRoute.snapshot.queryParams['key'];
    }

    requestPassword(form: NgForm) {
        this.loading = true;
        this.authService.resetPassword(form.form.value.email).subscribe({
            next: () => {
                this.router.navigateByUrl('/sign-in');
                this.snackBar.open('As instruções para recuperar a senha foram enviadas para o email digitado!', '✕', { panelClass: ['success'] });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
    
    checkPassword(password: string, confirmPassword: string) {
        return password && confirmPassword && password != confirmPassword
    }
    
    changePassword(form: NgForm) {
        console.log(form)
    }

}
