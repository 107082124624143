import { Component, OnInit } from '@angular/core';
import { Servico } from './servico.model';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination, RemoveDialogService } from '../shared/components';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ServicoService } from './servico.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from '../shared/utils';
import { FormServicoComponent } from './form-servico/form-servico.component';

@Component({
    selector: 'app-servico',
    templateUrl: './servico.component.html'
})
export class ServicoComponent implements OnInit {

    loading: boolean;
    filter: string;
    dataSource: Servico[];
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Serviços' },
    ]

    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Título', attr: 'titulo' },
        { header: 'Informações Adicionais', attr: 'descricao' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: { action: (rowData: Servico) => this.shorFormDialog(rowData)}
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Apagar', color: 'warn' },
            click: { action: (rowData: Servico) => this.showRemoveDialog(rowData)}
        }
    ]

    constructor(
        private dialog: MatDialog,
        private servicoService: ServicoService,
        private removeDialogService: RemoveDialogService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        console.log('filter: ', this.filter);

        this.servicoService.getPaginated<Servico>(this.pagination.current, this.pagination.pageSize, this.filter).subscribe({
            next: (res) => {
                this.dataSource = res.data;
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    shorFormDialog(servico?: Servico) {
        servico = servico || {} as Servico;
        const dialogRef = this.dialog.open(FormServicoComponent, { data: { servico } });
        dialogRef.afterClosed().subscribe((reload) => !!reload && this.search());
    }

    showRemoveDialog(servico: Servico) {
        this.removeDialogService.show(servico.titulo, (dialogRef: MatDialogRef<any>) => {
            this.servicoService.remove<Servico>(servico.id).subscribe({
                next: () => {
                    this.snackBar.open('Serviço apagado com sucesso!', '✕', { panelClass: ['success'] });
                    dialogRef.close();
                    this.search();
                }, error: (err) => catchErr(err, this.snackBar)
            });
        });
    }
}
