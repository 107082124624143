import { MonitoramentoGeolocalizacao } from "../dio.model";

export const geosMock: MonitoramentoGeolocalizacao[] = [
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121318",
        "data_carregamento": "2024-04-26 11:54:39",
        "data_transmissao": "2024-04-26 08:48:38",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121318",
        "velocidade": 6,
        "odometro": -1386077.86,
        "ignicao": false,
        "bateria": "14.589",
        "geolocalizacao": "-15.8920216,-47.8148316",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121320",
        "data_carregamento": "2024-04-26 11:54:39",
        "data_transmissao": "2024-04-26 08:48:40",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121320",
        "velocidade": 9,
        "odometro": -1386077.85,
        "ignicao": false,
        "bateria": "14.581",
        "geolocalizacao": "-15.8920283,-47.8148916",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121321",
        "data_carregamento": "2024-04-26 11:54:39",
        "data_transmissao": "2024-04-26 08:48:41",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121321",
        "velocidade": 9,
        "odometro": -1386077.85,
        "ignicao": false,
        "bateria": "14.581",
        "geolocalizacao": "-15.8920449,-47.8149083",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121322",
        "data_carregamento": "2024-04-26 11:54:38",
        "data_transmissao": "2024-04-26 08:48:42",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121322",
        "velocidade": 11,
        "odometro": -1386077.85,
        "ignicao": false,
        "bateria": "14.574",
        "geolocalizacao": "-15.8920683,-47.8149133",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121324",
        "data_carregamento": "2024-04-26 11:54:38",
        "data_transmissao": "2024-04-26 08:48:44",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121324",
        "velocidade": 14,
        "odometro": -1386077.84,
        "ignicao": false,
        "bateria": "14.566",
        "geolocalizacao": "-15.8921216,-47.8148816",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121328",
        "data_carregamento": "2024-04-26 11:54:38",
        "data_transmissao": "2024-04-26 08:48:48",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121328",
        "velocidade": 13,
        "odometro": -1386077.82,
        "ignicao": false,
        "bateria": "14.57",
        "geolocalizacao": "-15.8921766,-47.8147449",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121350",
        "data_carregamento": "2024-04-26 11:54:37",
        "data_transmissao": "2024-04-26 08:49:10",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121350",
        "velocidade": 13,
        "odometro": -1386077.74,
        "ignicao": false,
        "bateria": "14.597",
        "geolocalizacao": "-15.8924766,-47.8139683",
        "horimetro": "",
        "logradouro": "Rua I, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.08
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121352",
        "data_carregamento": "2024-04-26 11:54:37",
        "data_transmissao": "2024-04-26 08:49:12",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121352",
        "velocidade": 17,
        "odometro": -1386077.73,
        "ignicao": false,
        "bateria": "14.552",
        "geolocalizacao": "-15.892455,-47.813935",
        "horimetro": "",
        "logradouro": "Rua I, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121356",
        "data_carregamento": "2024-04-26 11:54:37",
        "data_transmissao": "2024-04-26 08:49:16",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121356",
        "velocidade": 10,
        "odometro": -1386077.71,
        "ignicao": false,
        "bateria": "14.581",
        "geolocalizacao": "-15.8922966,-47.8138316",
        "horimetro": "",
        "logradouro": "Rua I, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121361",
        "data_carregamento": "2024-04-26 11:54:36",
        "data_transmissao": "2024-04-26 08:49:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121361",
        "velocidade": 12,
        "odometro": -1386077.69,
        "ignicao": false,
        "bateria": "14.564",
        "geolocalizacao": "-15.8921516,-47.8138133",
        "horimetro": "",
        "logradouro": "Rua F, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121363",
        "data_carregamento": "2024-04-26 11:54:36",
        "data_transmissao": "2024-04-26 08:49:23",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121363",
        "velocidade": 8,
        "odometro": -1386077.69,
        "ignicao": false,
        "bateria": "14.57",
        "geolocalizacao": "-15.8921216,-47.8138499",
        "horimetro": "",
        "logradouro": "Rua F, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121375",
        "data_carregamento": "2024-04-26 11:54:35",
        "data_transmissao": "2024-04-26 08:49:35",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121375",
        "velocidade": 11,
        "odometro": -1386077.63,
        "ignicao": false,
        "bateria": "14.554",
        "geolocalizacao": "-15.8919066,-47.8143566",
        "horimetro": "",
        "logradouro": "Rua F, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.06
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121376",
        "data_carregamento": "2024-04-26 11:54:35",
        "data_transmissao": "2024-04-26 08:49:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121376",
        "velocidade": 9,
        "odometro": -1386077.62,
        "ignicao": false,
        "bateria": "14.531",
        "geolocalizacao": "-15.8918816,-47.8143633",
        "horimetro": "",
        "logradouro": "Rua F, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121388",
        "data_carregamento": "2024-04-26 11:54:35",
        "data_transmissao": "2024-04-26 08:49:48",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121388",
        "velocidade": 24,
        "odometro": -1386077.56,
        "ignicao": false,
        "bateria": "14.541",
        "geolocalizacao": "-15.8913116,-47.8141449",
        "horimetro": "",
        "logradouro": "Rua B, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.06
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121396",
        "data_carregamento": "2024-04-26 11:54:34",
        "data_transmissao": "2024-04-26 08:49:56",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121396",
        "velocidade": 18,
        "odometro": -1386077.52,
        "ignicao": false,
        "bateria": "14.592",
        "geolocalizacao": "-15.8910066,-47.81397",
        "horimetro": "",
        "logradouro": "Rua B, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.04
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121411",
        "data_carregamento": "2024-04-26 11:54:34",
        "data_transmissao": "2024-04-26 08:50:11",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121411",
        "velocidade": 11,
        "odometro": -1386077.44,
        "ignicao": false,
        "bateria": "14.553",
        "geolocalizacao": "-15.8911983,-47.8132833",
        "horimetro": "",
        "logradouro": "Rua B, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.08
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121425",
        "data_carregamento": "2024-04-26 11:54:34",
        "data_transmissao": "2024-04-26 08:50:25",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121425",
        "velocidade": 21,
        "odometro": -1386077.37,
        "ignicao": false,
        "bateria": "14.569",
        "geolocalizacao": "-15.8906583,-47.8130116",
        "horimetro": "",
        "logradouro": "Colégio Digital, Rua A, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.07
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121427",
        "data_carregamento": "2024-04-26 11:54:33",
        "data_transmissao": "2024-04-26 08:50:27",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121427",
        "velocidade": 24,
        "odometro": -1386077.36,
        "ignicao": false,
        "bateria": "14.553",
        "geolocalizacao": "-15.890625,-47.8129",
        "horimetro": "",
        "logradouro": "Colégio Digital, Rua A, Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121429",
        "data_carregamento": "2024-04-26 11:54:33",
        "data_transmissao": "2024-04-26 08:50:29",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121429",
        "velocidade": 29,
        "odometro": -1386077.34,
        "ignicao": false,
        "bateria": "14.543",
        "geolocalizacao": "-15.8906766,-47.812765",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121435",
        "data_carregamento": "2024-04-26 11:54:32",
        "data_transmissao": "2024-04-26 08:50:35",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121435",
        "velocidade": 27,
        "odometro": -1386077.3,
        "ignicao": false,
        "bateria": "14.576",
        "geolocalizacao": "-15.8906633,-47.8124266",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.04
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121447",
        "data_carregamento": "2024-04-26 11:54:32",
        "data_transmissao": "2024-04-26 08:50:47",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121447",
        "velocidade": 9,
        "odometro": -1386077.23,
        "ignicao": false,
        "bateria": "14.581",
        "geolocalizacao": "-15.8901066,-47.8121533",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.07
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121449",
        "data_carregamento": "2024-04-26 11:54:32",
        "data_transmissao": "2024-04-26 08:50:49",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121449",
        "velocidade": 18,
        "odometro": -1386077.22,
        "ignicao": false,
        "bateria": "14.549",
        "geolocalizacao": "-15.8900916,-47.8120816",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121462",
        "data_carregamento": "2024-04-26 11:54:31",
        "data_transmissao": "2024-04-26 08:51:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121462",
        "velocidade": 12,
        "odometro": -1386077.17,
        "ignicao": false,
        "bateria": "14.525",
        "geolocalizacao": "-15.8902016,-47.8116583",
        "horimetro": "",
        "logradouro": "Avenida Mangueiral, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-028, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.05
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121464",
        "data_carregamento": "2024-04-26 11:54:31",
        "data_transmissao": "2024-04-26 08:51:04",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121464",
        "velocidade": 16,
        "odometro": -1386077.17,
        "ignicao": false,
        "bateria": "14.545",
        "geolocalizacao": "-15.8901483,-47.8116949",
        "horimetro": "",
        "logradouro": "Avenida Mangueiral, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-028, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121470",
        "data_carregamento": "2024-04-26 11:54:31",
        "data_transmissao": "2024-04-26 08:51:10",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121470",
        "velocidade": 32,
        "odometro": -1386077.13,
        "ignicao": false,
        "bateria": "14.57",
        "geolocalizacao": "-15.8900366,-47.8120283",
        "horimetro": "",
        "logradouro": "Quadra 04, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.04
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121500",
        "data_carregamento": "2024-04-26 11:54:30",
        "data_transmissao": "2024-04-26 08:51:40",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121500",
        "velocidade": 67,
        "odometro": -1386076.63,
        "ignicao": false,
        "bateria": "14.597",
        "geolocalizacao": "-15.888755,-47.8165166",
        "horimetro": "",
        "logradouro": "Avenida Mangueiral, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-028, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.5
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121518",
        "data_carregamento": "2024-04-26 11:54:30",
        "data_transmissao": "2024-04-26 08:51:58",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121518",
        "velocidade": 68,
        "odometro": -1386076.26,
        "ignicao": false,
        "bateria": "14.614",
        "geolocalizacao": "-15.888,-47.8198033",
        "horimetro": "",
        "logradouro": "Avenida Mangueiral, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-266, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.37
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121527",
        "data_carregamento": "2024-04-26 11:54:30",
        "data_transmissao": "2024-04-26 08:52:07",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121527",
        "velocidade": 38,
        "odometro": -1386076.13,
        "ignicao": false,
        "bateria": "14.563",
        "geolocalizacao": "-15.8881816,-47.82098",
        "horimetro": "",
        "logradouro": "Avenida Mangueiral, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-028, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.13
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121529",
        "data_carregamento": "2024-04-26 11:54:29",
        "data_transmissao": "2024-04-26 08:52:09",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121529",
        "velocidade": 38,
        "odometro": -1386076.11,
        "ignicao": false,
        "bateria": "14.569",
        "geolocalizacao": "-15.8880333,-47.8210933",
        "horimetro": "",
        "logradouro": "Avenida Mangueiral, Jardins Mangueiral, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-028, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121546",
        "data_carregamento": "2024-04-26 11:54:29",
        "data_transmissao": "2024-04-26 08:52:26",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121546",
        "velocidade": 38,
        "odometro": -1386075.89,
        "ignicao": false,
        "bateria": "14.577",
        "geolocalizacao": "-15.8860716,-47.8216033",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, Setor Residencial Oeste, São Sebastião, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71699-028, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.22
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121556",
        "data_carregamento": "2024-04-26 11:54:28",
        "data_transmissao": "2024-04-26 08:52:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121556",
        "velocidade": 40,
        "odometro": -1386075.77,
        "ignicao": false,
        "bateria": "14.526",
        "geolocalizacao": "-15.885085,-47.8216183",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680364, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.12
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121585",
        "data_carregamento": "2024-04-26 11:54:28",
        "data_transmissao": "2024-04-26 08:53:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121585",
        "velocidade": 30,
        "odometro": -1386075.37,
        "ignicao": false,
        "bateria": "14.519",
        "geolocalizacao": "-15.88168,-47.8226933",
        "horimetro": "",
        "logradouro": "Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71686-301, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.4
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121615",
        "data_carregamento": "2024-04-26 11:54:28",
        "data_transmissao": "2024-04-26 08:53:35",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121615",
        "velocidade": 52,
        "odometro": -1386075.01,
        "ignicao": false,
        "bateria": "14.46",
        "geolocalizacao": "-15.87843,-47.823065",
        "horimetro": "",
        "logradouro": "Avenida Comercial, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680364, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.36
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121645",
        "data_carregamento": "2024-04-26 11:54:28",
        "data_transmissao": "2024-04-26 08:54:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121645",
        "velocidade": 49,
        "odometro": -1386074.6,
        "ignicao": false,
        "bateria": "14.495",
        "geolocalizacao": "-15.8747866,-47.8230033",
        "horimetro": "",
        "logradouro": "Avenida Comercial, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71681-740, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.41
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121675",
        "data_carregamento": "2024-04-26 12:54:42",
        "data_transmissao": "2024-04-26 08:54:35",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121675",
        "velocidade": 47,
        "odometro": -1386074.3,
        "ignicao": false,
        "bateria": "14.512",
        "geolocalizacao": "-15.8719983,-47.822605",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-362, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.3
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121705",
        "data_carregamento": "2024-04-26 12:54:41",
        "data_transmissao": "2024-04-26 08:55:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121705",
        "velocidade": 44,
        "odometro": -1386073.9,
        "ignicao": false,
        "bateria": "14.515",
        "geolocalizacao": "-15.8685716,-47.821715",
        "horimetro": "",
        "logradouro": "Ponto de Táxi Jardim Botânico, Avenida Comercial, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71681-740, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.4
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121736",
        "data_carregamento": "2024-04-26 12:54:41",
        "data_transmissao": "2024-04-26 08:55:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121736",
        "velocidade": 0,
        "odometro": -1386073.71,
        "ignicao": false,
        "bateria": "13.871",
        "geolocalizacao": "-15.86689,-47.82121",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-362, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.19
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121766",
        "data_carregamento": "2024-04-26 12:54:41",
        "data_transmissao": "2024-04-26 08:56:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121766",
        "velocidade": 27,
        "odometro": -1386073.57,
        "ignicao": false,
        "bateria": "14.463",
        "geolocalizacao": "-15.865795,-47.8208466",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-362, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.14
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121796",
        "data_carregamento": "2024-04-26 12:54:40",
        "data_transmissao": "2024-04-26 08:56:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121796",
        "velocidade": 46,
        "odometro": -1386073.28,
        "ignicao": false,
        "bateria": "14.102",
        "geolocalizacao": "-15.8634466,-47.8197983",
        "horimetro": "",
        "logradouro": "Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71686-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.29
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121826",
        "data_carregamento": "2024-04-26 12:54:40",
        "data_transmissao": "2024-04-26 08:57:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121826",
        "velocidade": 63,
        "odometro": -1386072.84,
        "ignicao": false,
        "bateria": "14.512",
        "geolocalizacao": "-15.8595916,-47.8187033",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-285, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.44
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121856",
        "data_carregamento": "2024-04-26 12:54:40",
        "data_transmissao": "2024-04-26 08:57:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121856",
        "velocidade": 63,
        "odometro": -1386072.35,
        "ignicao": false,
        "bateria": "14.5",
        "geolocalizacao": "-15.8553383,-47.8174033",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-285, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.49
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121886",
        "data_carregamento": "2024-04-26 12:54:39",
        "data_transmissao": "2024-04-26 08:58:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121886",
        "velocidade": 0,
        "odometro": -1386072.03,
        "ignicao": false,
        "bateria": "14.132",
        "geolocalizacao": "-15.8525966,-47.8165733",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-285, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.32
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121915",
        "data_carregamento": "2024-04-26 12:54:39",
        "data_transmissao": "2024-04-26 08:58:35",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121915",
        "velocidade": 43,
        "odometro": -1386071.95,
        "ignicao": false,
        "bateria": "14.464",
        "geolocalizacao": "-15.8519283,-47.8163216",
        "horimetro": "",
        "logradouro": "Estrada Parque Contorno, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-285, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.08
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121946",
        "data_carregamento": "2024-04-26 12:54:39",
        "data_transmissao": "2024-04-26 08:59:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121946",
        "velocidade": 15,
        "odometro": -1386071.74,
        "ignicao": false,
        "bateria": "14.337",
        "geolocalizacao": "-15.8502133,-47.815425",
        "horimetro": "",
        "logradouro": "Ciclovia Estrada Parque Contorno, Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-285, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.21
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121967",
        "data_carregamento": "2024-04-26 12:54:38",
        "data_transmissao": "2024-04-26 08:59:27",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121967",
        "velocidade": 33,
        "odometro": -1386071.6,
        "ignicao": false,
        "bateria": "14.029",
        "geolocalizacao": "-15.849315,-47.8146116",
        "horimetro": "",
        "logradouro": "Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.14
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121969",
        "data_carregamento": "2024-04-26 12:54:38",
        "data_transmissao": "2024-04-26 08:59:29",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121969",
        "velocidade": 35,
        "odometro": -1386071.58,
        "ignicao": false,
        "bateria": "14.41",
        "geolocalizacao": "-15.8491366,-47.814645",
        "horimetro": "",
        "logradouro": "Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121971",
        "data_carregamento": "2024-04-26 12:54:37",
        "data_transmissao": "2024-04-26 08:59:31",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714121971",
        "velocidade": 37,
        "odometro": -1386071.56,
        "ignicao": false,
        "bateria": "14.404",
        "geolocalizacao": "-15.8489816,-47.8147883",
        "horimetro": "",
        "logradouro": "Condomínio Solar de Brasília, Jardim Botânico, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122001",
        "data_carregamento": "2024-04-26 12:54:37",
        "data_transmissao": "2024-04-26 09:00:01",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122001",
        "velocidade": 95,
        "odometro": -1386071,
        "ignicao": false,
        "bateria": "14.44",
        "geolocalizacao": "-15.845185,-47.8180149",
        "horimetro": "",
        "logradouro": "Estrada Parque Juscelino Kubitschek, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-290, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.56
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122031",
        "data_carregamento": "2024-04-26 12:54:37",
        "data_transmissao": "2024-04-26 09:00:31",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122031",
        "velocidade": 74,
        "odometro": -1386070.28,
        "ignicao": false,
        "bateria": "14.449",
        "geolocalizacao": "-15.8410783,-47.8232216",
        "horimetro": "",
        "logradouro": "Estrada Parque Juscelino Kubitschek, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-290, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.72
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122043",
        "data_carregamento": "2024-04-26 12:54:36",
        "data_transmissao": "2024-04-26 09:00:43",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122043",
        "velocidade": 91,
        "odometro": -1386069.99,
        "ignicao": false,
        "bateria": "14.438",
        "geolocalizacao": "-15.8388533,-47.8246299",
        "horimetro": "",
        "logradouro": "Estrada Parque Juscelino Kubitschek, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-290, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.29
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122052",
        "data_carregamento": "2024-04-26 12:54:36",
        "data_transmissao": "2024-04-26 09:00:52",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122052",
        "velocidade": 70,
        "odometro": -1386069.77,
        "ignicao": false,
        "bateria": "14.458",
        "geolocalizacao": "-15.8369583,-47.8249683",
        "horimetro": "",
        "logradouro": "Estrada Parque Juscelino Kubitschek, SMDB Conjunto 12A, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-290, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.22
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122082",
        "data_carregamento": "2024-04-26 12:54:36",
        "data_transmissao": "2024-04-26 09:01:22",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122082",
        "velocidade": 32,
        "odometro": -1386069.33,
        "ignicao": false,
        "bateria": "14.468",
        "geolocalizacao": "-15.8330066,-47.82483",
        "horimetro": "",
        "logradouro": "Estrada Parque Juscelino Kubitschek, SMDB Conjunto 22, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-290, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.44
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122097",
        "data_carregamento": "2024-04-26 12:54:35",
        "data_transmissao": "2024-04-26 09:01:37",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122097",
        "velocidade": 47,
        "odometro": -1386069.14,
        "ignicao": false,
        "bateria": "14.474",
        "geolocalizacao": "-15.8315516,-47.8256016",
        "horimetro": "",
        "logradouro": "Estrada Parque Juscelino Kubitschek, SMDB Conjunto 22, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71680-290, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.19
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122127",
        "data_carregamento": "2024-04-26 12:54:35",
        "data_transmissao": "2024-04-26 09:02:07",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122127",
        "velocidade": 75,
        "odometro": -1386068.64,
        "ignicao": false,
        "bateria": "14.452",
        "geolocalizacao": "-15.82751,-47.8276633",
        "horimetro": "",
        "logradouro": "Ponte Juscelino Kubitschek, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200002, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.5
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122158",
        "data_carregamento": "2024-04-26 12:54:35",
        "data_transmissao": "2024-04-26 09:02:38",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122158",
        "velocidade": 56,
        "odometro": -1386068.04,
        "ignicao": false,
        "bateria": "14.365",
        "geolocalizacao": "-15.822625,-47.8301016",
        "horimetro": "",
        "logradouro": "Ponte Juscelino Kubitschek, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200002, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.6
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122188",
        "data_carregamento": "2024-04-26 12:54:34",
        "data_transmissao": "2024-04-26 09:03:08",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122188",
        "velocidade": 47,
        "odometro": -1386067.61,
        "ignicao": false,
        "bateria": "14.373",
        "geolocalizacao": "-15.819475,-47.8324216",
        "horimetro": "",
        "logradouro": "Ponte Juscelino Kubitschek, Lago Sul, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200002, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.43
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122216",
        "data_carregamento": "2024-04-26 12:54:34",
        "data_transmissao": "2024-04-26 09:03:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122216",
        "velocidade": 66,
        "odometro": -1386067.13,
        "ignicao": false,
        "bateria": "14.424",
        "geolocalizacao": "-15.8167216,-47.8358366",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200002, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.48
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122245",
        "data_carregamento": "2024-04-26 12:54:33",
        "data_transmissao": "2024-04-26 09:04:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122245",
        "velocidade": 69,
        "odometro": -1386066.55,
        "ignicao": false,
        "bateria": "14.38",
        "geolocalizacao": "-15.8144549,-47.8406783",
        "horimetro": "",
        "logradouro": "Clube da Associação dos Magistrados do Distrito Federal, Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200002, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.58
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122275",
        "data_carregamento": "2024-04-26 12:54:33",
        "data_transmissao": "2024-04-26 09:04:35",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122275",
        "velocidade": 56,
        "odometro": -1386066.01,
        "ignicao": false,
        "bateria": "14.347",
        "geolocalizacao": "-15.812,-47.84499",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200002, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.54
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122305",
        "data_carregamento": "2024-04-26 12:54:33",
        "data_transmissao": "2024-04-26 09:05:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122305",
        "velocidade": 87,
        "odometro": -1386065.44,
        "ignicao": false,
        "bateria": "14.371",
        "geolocalizacao": "-15.8085433,-47.8488883",
        "horimetro": "",
        "logradouro": "Clube Olímpico Assefaz, Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200-003, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.57
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122333",
        "data_carregamento": "2024-04-26 12:54:32",
        "data_transmissao": "2024-04-26 09:05:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122333",
        "velocidade": 70,
        "odometro": -1386064.86,
        "ignicao": false,
        "bateria": "14.363",
        "geolocalizacao": "-15.8060866,-47.853585",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200-003, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.58
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122344",
        "data_carregamento": "2024-04-26 12:54:32",
        "data_transmissao": "2024-04-26 09:05:44",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122344",
        "velocidade": 40,
        "odometro": -1386064.66,
        "ignicao": false,
        "bateria": "14.348",
        "geolocalizacao": "-15.8045433,-47.8545699",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200-003, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.2
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122346",
        "data_carregamento": "2024-04-26 12:54:31",
        "data_transmissao": "2024-04-26 09:05:46",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122346",
        "velocidade": 40,
        "odometro": -1386064.63,
        "ignicao": false,
        "bateria": "14.317",
        "geolocalizacao": "-15.80436,-47.854465",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200-003, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.03
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122348",
        "data_carregamento": "2024-04-26 12:54:31",
        "data_transmissao": "2024-04-26 09:05:48",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122348",
        "velocidade": 41,
        "odometro": -1386064.61,
        "ignicao": false,
        "bateria": "14.321",
        "geolocalizacao": "-15.80428,-47.8542566",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200-003, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122350",
        "data_carregamento": "2024-04-26 12:54:31",
        "data_transmissao": "2024-04-26 09:05:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122350",
        "velocidade": 40,
        "odometro": -1386064.59,
        "ignicao": false,
        "bateria": "14.3",
        "geolocalizacao": "-15.80433,-47.8540449",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70000-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122352",
        "data_carregamento": "2024-04-26 12:54:30",
        "data_transmissao": "2024-04-26 09:05:52",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122352",
        "velocidade": 35,
        "odometro": -1386064.57,
        "ignicao": false,
        "bateria": "14.295",
        "geolocalizacao": "-15.8044516,-47.8539266",
        "horimetro": "",
        "logradouro": "Sces Trecho 3, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70000-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122354",
        "data_carregamento": "2024-04-26 12:54:30",
        "data_transmissao": "2024-04-26 09:05:54",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122354",
        "velocidade": 32,
        "odometro": -1386064.55,
        "ignicao": false,
        "bateria": "14.33",
        "geolocalizacao": "-15.8046283,-47.8539066",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70000-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122356",
        "data_carregamento": "2024-04-26 12:54:30",
        "data_transmissao": "2024-04-26 09:05:56",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122356",
        "velocidade": 35,
        "odometro": -1386064.53,
        "ignicao": false,
        "bateria": "14.338",
        "geolocalizacao": "-15.80479,-47.8539966",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70000-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122375",
        "data_carregamento": "2024-04-26 12:54:29",
        "data_transmissao": "2024-04-26 09:06:15",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122375",
        "velocidade": 55,
        "odometro": -1386064.22,
        "ignicao": false,
        "bateria": "14.343",
        "geolocalizacao": "-15.806575,-47.85619",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70042-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.31
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122405",
        "data_carregamento": "2024-04-26 12:54:29",
        "data_transmissao": "2024-04-26 09:06:45",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122405",
        "velocidade": 81,
        "odometro": -1386063.66,
        "ignicao": false,
        "bateria": "14.332",
        "geolocalizacao": "-15.80927,-47.86059",
        "horimetro": "",
        "logradouro": "Anexo da Procuradoria Geral da República, AFS 5, Setor de Autarquias Sul, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70042-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.56
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122435",
        "data_carregamento": "2024-04-26 12:54:29",
        "data_transmissao": "2024-04-26 09:07:15",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122435",
        "velocidade": 91,
        "odometro": -1386062.95,
        "ignicao": false,
        "bateria": "14.362",
        "geolocalizacao": "-15.8124616,-47.8663466",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Brasília, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, 70070-600, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.71
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122465",
        "data_carregamento": "2024-04-26 12:54:28",
        "data_transmissao": "2024-04-26 09:07:45",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122465",
        "velocidade": 0,
        "odometro": -1386062.78,
        "ignicao": false,
        "bateria": "14.299",
        "geolocalizacao": "-15.8131883,-47.8677283",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, SQS 402, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70070-600, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.17
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122495",
        "data_carregamento": "2024-04-26 12:54:28",
        "data_transmissao": "2024-04-26 09:08:15",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122495",
        "velocidade": 0,
        "odometro": -1386062.78,
        "ignicao": false,
        "bateria": "14.195",
        "geolocalizacao": "-15.8131883,-47.8677283",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, SQS 402, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70070-600, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122525",
        "data_carregamento": "2024-04-26 12:54:27",
        "data_transmissao": "2024-04-26 09:08:45",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122525",
        "velocidade": 45,
        "odometro": -1386062.45,
        "ignicao": false,
        "bateria": "14.33",
        "geolocalizacao": "-15.8145299,-47.87045",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, SQS 402, Vila Planalto, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70070-600, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.33
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122555",
        "data_carregamento": "2024-04-26 12:54:27",
        "data_transmissao": "2024-04-26 09:09:15",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122555",
        "velocidade": 92,
        "odometro": -1386061.81,
        "ignicao": false,
        "bateria": "14.359",
        "geolocalizacao": "-15.8171466,-47.8757783",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, SQS 403, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70412-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.64
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122585",
        "data_carregamento": "2024-04-26 12:54:27",
        "data_transmissao": "2024-04-26 09:09:45",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122585",
        "velocidade": 81,
        "odometro": -1386061.04,
        "ignicao": false,
        "bateria": "14.359",
        "geolocalizacao": "-15.82033,-47.88213",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, SQS 405, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70415-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.77
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122606",
        "data_carregamento": "2024-04-26 12:54:26",
        "data_transmissao": "2024-04-26 09:10:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122606",
        "velocidade": 82,
        "odometro": -1386060.59,
        "ignicao": false,
        "bateria": "14.358",
        "geolocalizacao": "-15.8228433,-47.8854749",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, SQS 405, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70415-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.45
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122636",
        "data_carregamento": "2024-04-26 12:54:26",
        "data_transmissao": "2024-04-26 09:10:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122636",
        "velocidade": 103,
        "odometro": -1386059.77,
        "ignicao": false,
        "bateria": "14.391",
        "geolocalizacao": "-15.8283233,-47.89052",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, SQS 405, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70415-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.82
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122666",
        "data_carregamento": "2024-04-26 12:54:26",
        "data_transmissao": "2024-04-26 09:11:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122666",
        "velocidade": 68,
        "odometro": -1386058.99,
        "ignicao": false,
        "bateria": "14.389",
        "geolocalizacao": "-15.8335166,-47.895035",
        "horimetro": "",
        "logradouro": "Clube da Associação dos Servidores do Supremo Tribunal, Estrada Parque das Nações, SQS 406, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70200-900, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.78
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122696",
        "data_carregamento": "2024-04-26 12:54:25",
        "data_transmissao": "2024-04-26 09:11:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122696",
        "velocidade": 104,
        "odometro": -1386058.24,
        "ignicao": false,
        "bateria": "14.389",
        "geolocalizacao": "-15.8364216,-47.9016283",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70210-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.75
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122726",
        "data_carregamento": "2024-04-26 12:54:25",
        "data_transmissao": "2024-04-26 09:12:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122726",
        "velocidade": 95,
        "odometro": -1386057.48,
        "ignicao": false,
        "bateria": "14.398",
        "geolocalizacao": "-15.8392233,-47.9080933",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70210-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.76
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122757",
        "data_carregamento": "2024-04-26 12:54:25",
        "data_transmissao": "2024-04-26 09:12:37",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122757",
        "velocidade": 88,
        "odometro": -1386056.63,
        "ignicao": false,
        "bateria": "14.395",
        "geolocalizacao": "-15.8423516,-47.9153899",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70210-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.85
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122787",
        "data_carregamento": "2024-04-26 12:54:24",
        "data_transmissao": "2024-04-26 09:13:07",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122787",
        "velocidade": 0,
        "odometro": -1386056.4,
        "ignicao": false,
        "bateria": "14.363",
        "geolocalizacao": "-15.8431966,-47.9173533",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70210-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.23
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122817",
        "data_carregamento": "2024-04-26 12:54:24",
        "data_transmissao": "2024-04-26 09:13:37",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122817",
        "velocidade": 51,
        "odometro": -1386056.27,
        "ignicao": false,
        "bateria": "14.351",
        "geolocalizacao": "-15.8436733,-47.9184566",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70210-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.13
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122847",
        "data_carregamento": "2024-04-26 12:54:23",
        "data_transmissao": "2024-04-26 09:14:07",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122847",
        "velocidade": 86,
        "odometro": -1386055.69,
        "ignicao": false,
        "bateria": "14.345",
        "geolocalizacao": "-15.8453783,-47.9235616",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70210-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.58
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122877",
        "data_carregamento": "2024-04-26 12:54:23",
        "data_transmissao": "2024-04-26 09:14:37",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122877",
        "velocidade": 85,
        "odometro": -1386055,
        "ignicao": false,
        "bateria": "14.361",
        "geolocalizacao": "-15.8451166,-47.9299333",
        "horimetro": "",
        "logradouro": "Estrada Parque das Nações, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70210-000, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.69
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122906",
        "data_carregamento": "2024-04-26 12:54:23",
        "data_transmissao": "2024-04-26 09:15:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122906",
        "velocidade": 77,
        "odometro": -1386054.34,
        "ignicao": false,
        "bateria": "14.374",
        "geolocalizacao": "-15.84474,-47.9361566",
        "horimetro": "",
        "logradouro": "Estrada Parque Guará, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70610-100, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.66
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122936",
        "data_carregamento": "2024-04-26 12:54:22",
        "data_transmissao": "2024-04-26 09:15:36",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122936",
        "velocidade": 85,
        "odometro": -1386053.67,
        "ignicao": false,
        "bateria": "14.329",
        "geolocalizacao": "-15.84394,-47.9423483",
        "horimetro": "",
        "logradouro": "Zoológico de Brasília, Estrada Parque Guará, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70610-100, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.67
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122967",
        "data_carregamento": "2024-04-26 12:54:22",
        "data_transmissao": "2024-04-26 09:16:07",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122967",
        "velocidade": 78,
        "odometro": -1386052.99,
        "ignicao": false,
        "bateria": "14.368",
        "geolocalizacao": "-15.842025,-47.94836",
        "horimetro": "",
        "logradouro": "Zoológico de Brasília, Estrada Parque Guará, Vila da Telebrasília, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70610-100, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.68
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122994",
        "data_carregamento": "2024-04-26 12:54:22",
        "data_transmissao": "2024-04-26 09:16:34",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122994",
        "velocidade": 43,
        "odometro": -1386052.49,
        "ignicao": false,
        "bateria": "14.293",
        "geolocalizacao": "-15.83944,-47.9522433",
        "horimetro": "",
        "logradouro": "Churrascaria Potência do sul, SHIP, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70610-100, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.5
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122999",
        "data_carregamento": "2024-04-26 12:54:21",
        "data_transmissao": "2024-04-26 09:16:39",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714122999",
        "velocidade": 46,
        "odometro": -1386052.43,
        "ignicao": false,
        "bateria": "14.299",
        "geolocalizacao": "-15.8390449,-47.9524766",
        "horimetro": "",
        "logradouro": "Marginal EPIA, Candangolândia, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-100, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.06
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123029",
        "data_carregamento": "2024-04-26 12:54:21",
        "data_transmissao": "2024-04-26 09:17:09",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123029",
        "velocidade": 48,
        "odometro": -1386052.04,
        "ignicao": false,
        "bateria": "14.285",
        "geolocalizacao": "-15.8356216,-47.9520149",
        "horimetro": "",
        "logradouro": "Estrada Parque Indústria e Abastecimento, Candangolândia, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-720, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.39
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123059",
        "data_carregamento": "2024-04-26 12:54:20",
        "data_transmissao": "2024-04-26 09:17:39",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123059",
        "velocidade": 24,
        "odometro": -1386051.67,
        "ignicao": false,
        "bateria": "14.281",
        "geolocalizacao": "-15.8323133,-47.9516749",
        "horimetro": "",
        "logradouro": "Estrada Parque Indústria e Abastecimento, Candangolândia, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-720, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.37
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123089",
        "data_carregamento": "2024-04-26 12:54:20",
        "data_transmissao": "2024-04-26 09:18:09",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123089",
        "velocidade": 19,
        "odometro": -1386051.44,
        "ignicao": false,
        "bateria": "14.265",
        "geolocalizacao": "-15.8303033,-47.9513833",
        "horimetro": "",
        "logradouro": "Estrada Parque Indústria e Abastecimento, Candangolândia, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-720, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.23
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123119",
        "data_carregamento": "2024-04-26 12:54:20",
        "data_transmissao": "2024-04-26 09:18:39",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123119",
        "velocidade": 24,
        "odometro": -1386051.29,
        "ignicao": false,
        "bateria": "14.275",
        "geolocalizacao": "-15.8289066,-47.9511749",
        "horimetro": "",
        "logradouro": "Estrada Parque Indústria e Abastecimento, Candangolândia, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-720, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.15
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123149",
        "data_carregamento": "2024-04-26 12:54:19",
        "data_transmissao": "2024-04-26 09:19:09",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123149",
        "velocidade": 42,
        "odometro": -1386051,
        "ignicao": false,
        "bateria": "14.222",
        "geolocalizacao": "-15.8263183,-47.9507783",
        "horimetro": "",
        "logradouro": "SQS 316, Asa Sul, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-720, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.29
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123180",
        "data_carregamento": "2024-04-26 12:54:19",
        "data_transmissao": "2024-04-26 09:19:40",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123180",
        "velocidade": 0,
        "odometro": -1386050.65,
        "ignicao": false,
        "bateria": "13.56",
        "geolocalizacao": "-15.8231716,-47.950295",
        "horimetro": "",
        "logradouro": "Estrada Parque Indústria e Abastecimento, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-720, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.35
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123209",
        "data_carregamento": "2024-04-26 12:54:19",
        "data_transmissao": "2024-04-26 09:20:09",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123209",
        "velocidade": 34,
        "odometro": -1386050.58,
        "ignicao": false,
        "bateria": "14.224",
        "geolocalizacao": "-15.82258,-47.950195",
        "horimetro": "",
        "logradouro": "Estrada Parque Indústria e Abastecimento, Plano Piloto, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-720, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.07
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123239",
        "data_carregamento": "2024-04-26 12:54:18",
        "data_transmissao": "2024-04-26 09:20:39",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123239",
        "velocidade": 31,
        "odometro": -1386050.25,
        "ignicao": false,
        "bateria": "14.252",
        "geolocalizacao": "-15.819695,-47.949725",
        "horimetro": "",
        "logradouro": "Estrada Parque Indústria e Abastecimento, Cruzeiro, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.33
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123459",
        "data_carregamento": "2024-04-26 12:54:18",
        "data_transmissao": "2024-04-26 09:24:19",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123459",
        "velocidade": 26,
        "odometro": -1386048.02,
        "ignicao": false,
        "bateria": "14.237",
        "geolocalizacao": "-15.8136033,-47.9569916",
        "horimetro": "",
        "logradouro": "IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 2.23
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123543",
        "data_carregamento": "2024-04-26 12:54:18",
        "data_transmissao": "2024-04-26 09:25:43",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123543",
        "velocidade": 20,
        "odometro": -1386047.31,
        "ignicao": false,
        "bateria": "14.232",
        "geolocalizacao": "-15.8157566,-47.9530699",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.71
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123546",
        "data_carregamento": "2024-04-26 12:54:17",
        "data_transmissao": "2024-04-26 09:25:46",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123546",
        "velocidade": 15,
        "odometro": -1386047.29,
        "ignicao": false,
        "bateria": "14.208",
        "geolocalizacao": "-15.81574,-47.9529466",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123548",
        "data_carregamento": "2024-04-26 12:54:17",
        "data_transmissao": "2024-04-26 09:25:48",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123548",
        "velocidade": 17,
        "odometro": -1386047.29,
        "ignicao": false,
        "bateria": "14.236",
        "geolocalizacao": "-15.8157333,-47.9528749",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123555",
        "data_carregamento": "2024-04-26 12:54:16",
        "data_transmissao": "2024-04-26 09:25:55",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123555",
        "velocidade": 21,
        "odometro": -1386047.24,
        "ignicao": false,
        "bateria": "14.244",
        "geolocalizacao": "-15.81595,-47.9525249",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.05
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123557",
        "data_carregamento": "2024-04-26 12:54:16",
        "data_transmissao": "2024-04-26 09:25:57",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123557",
        "velocidade": 17,
        "odometro": -1386047.23,
        "ignicao": false,
        "bateria": "14.236",
        "geolocalizacao": "-15.8159883,-47.9524333",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123565",
        "data_carregamento": "2024-04-26 12:54:15",
        "data_transmissao": "2024-04-26 09:26:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123565",
        "velocidade": 0,
        "odometro": -1386047.22,
        "ignicao": true,
        "bateria": "13.54",
        "geolocalizacao": "-15.8160016,-47.9523516",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123595",
        "data_carregamento": "2024-04-26 12:54:15",
        "data_transmissao": "2024-04-26 09:26:35",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123595",
        "velocidade": 0,
        "odometro": -1386047.22,
        "ignicao": true,
        "bateria": "13.029",
        "geolocalizacao": "-15.8160016,-47.9523516",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123625",
        "data_carregamento": "2024-04-26 12:54:15",
        "data_transmissao": "2024-04-26 09:27:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123625",
        "velocidade": 0,
        "odometro": -1386047.22,
        "ignicao": true,
        "bateria": "13.001",
        "geolocalizacao": "-15.8160016,-47.9523516",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123626",
        "data_carregamento": "2024-04-26 12:54:15",
        "data_transmissao": "2024-04-26 09:27:06",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714123626",
        "velocidade": 0,
        "odometro": -1386047.22,
        "ignicao": true,
        "bateria": "12.999",
        "geolocalizacao": "-15.8160016,-47.9523516",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125377",
        "data_carregamento": "2024-04-26 13:54:21",
        "data_transmissao": "2024-04-26 09:56:17",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125377",
        "velocidade": 0,
        "odometro": -1386047.22,
        "ignicao": true,
        "bateria": "12.226",
        "geolocalizacao": "-15.8160016,-47.9523516",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125413",
        "data_carregamento": "2024-04-26 13:54:21",
        "data_transmissao": "2024-04-26 09:56:53",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125413",
        "velocidade": 22,
        "odometro": -1386047.07,
        "ignicao": false,
        "bateria": "14.375",
        "geolocalizacao": "-15.8164516,-47.9531083",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.15
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125415",
        "data_carregamento": "2024-04-26 13:54:21",
        "data_transmissao": "2024-04-26 09:56:55",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125415",
        "velocidade": 24,
        "odometro": -1386047.05,
        "ignicao": false,
        "bateria": "14.416",
        "geolocalizacao": "-15.8164966,-47.9532199",
        "horimetro": "",
        "logradouro": "Novacap (Anexo), Estrada Parque Indústria e Abastecimento, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 70660-086, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125419",
        "data_carregamento": "2024-04-26 13:54:20",
        "data_transmissao": "2024-04-26 09:56:59",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125419",
        "velocidade": 15,
        "odometro": -1386047.02,
        "ignicao": false,
        "bateria": "14.385",
        "geolocalizacao": "-15.8164333,-47.9534749",
        "horimetro": "",
        "logradouro": "Secretaria de Obras do DF, SOF SUL Quadra 1, Guará, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-217, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.03
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125450",
        "data_carregamento": "2024-04-26 13:54:20",
        "data_transmissao": "2024-04-26 09:57:30",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125450",
        "velocidade": 42,
        "odometro": -1386046.76,
        "ignicao": false,
        "bateria": "14.482",
        "geolocalizacao": "-15.8160249,-47.9558666",
        "horimetro": "",
        "logradouro": "Secretaria de Obras do DF, SOF SUL Quadra 1, Guará, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-217, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.26
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125469",
        "data_carregamento": "2024-04-26 13:54:20",
        "data_transmissao": "2024-04-26 09:57:49",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125469",
        "velocidade": 31,
        "odometro": -1386046.62,
        "ignicao": false,
        "bateria": "14.432",
        "geolocalizacao": "-15.8157583,-47.9572166",
        "horimetro": "",
        "logradouro": "IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.14
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125471",
        "data_carregamento": "2024-04-26 13:54:19",
        "data_transmissao": "2024-04-26 09:57:51",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125471",
        "velocidade": 19,
        "odometro": -1386046.6,
        "ignicao": false,
        "bateria": "14.401",
        "geolocalizacao": "-15.81565,-47.9572816",
        "horimetro": "",
        "logradouro": "IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.02
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125485",
        "data_carregamento": "2024-04-26 13:54:19",
        "data_transmissao": "2024-04-26 09:58:05",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125485",
        "velocidade": 13,
        "odometro": -1386046.5,
        "ignicao": false,
        "bateria": "14.445",
        "geolocalizacao": "-15.8147516,-47.9571783",
        "horimetro": "",
        "logradouro": "IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.1
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125498",
        "data_carregamento": "2024-04-26 13:54:19",
        "data_transmissao": "2024-04-26 09:58:18",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125498",
        "velocidade": 12,
        "odometro": -1386046.4,
        "ignicao": false,
        "bateria": "14.422",
        "geolocalizacao": "-15.8138916,-47.95704",
        "horimetro": "",
        "logradouro": "IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.1
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125499",
        "data_carregamento": "2024-04-26 13:54:18",
        "data_transmissao": "2024-04-26 09:58:19",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125499",
        "velocidade": 13,
        "odometro": -1386046.4,
        "ignicao": false,
        "bateria": "14.361",
        "geolocalizacao": "-15.8138683,-47.9570616",
        "horimetro": "",
        "logradouro": "IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125501",
        "data_carregamento": "2024-04-26 13:54:18",
        "data_transmissao": "2024-04-26 09:58:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125501",
        "velocidade": 9,
        "odometro": -1386046.39,
        "ignicao": false,
        "bateria": "14.335",
        "geolocalizacao": "-15.8138499,-47.957115",
        "horimetro": "",
        "logradouro": "IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125528",
        "data_carregamento": "2024-04-26 13:54:17",
        "data_transmissao": "2024-04-26 09:58:48",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125528",
        "velocidade": 11,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "14.378",
        "geolocalizacao": "-15.8137233,-47.9581466",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.11
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125529",
        "data_carregamento": "2024-04-26 13:54:17",
        "data_transmissao": "2024-04-26 09:58:49",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125529",
        "velocidade": 7,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "14.365",
        "geolocalizacao": "-15.8137433,-47.9581566",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125559",
        "data_carregamento": "2024-04-26 13:54:17",
        "data_transmissao": "2024-04-26 09:59:19",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125559",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.865",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125589",
        "data_carregamento": "2024-04-26 13:54:16",
        "data_transmissao": "2024-04-26 09:59:49",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125589",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.595",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125619",
        "data_carregamento": "2024-04-26 13:54:16",
        "data_transmissao": "2024-04-26 10:00:19",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125619",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.551",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125649",
        "data_carregamento": "2024-04-26 13:54:16",
        "data_transmissao": "2024-04-26 10:00:49",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125649",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.547",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125680",
        "data_carregamento": "2024-04-26 13:54:15",
        "data_transmissao": "2024-04-26 10:01:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125680",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.528",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125710",
        "data_carregamento": "2024-04-26 13:54:15",
        "data_transmissao": "2024-04-26 10:01:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125710",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.507",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125740",
        "data_carregamento": "2024-04-26 13:54:14",
        "data_transmissao": "2024-04-26 10:02:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125740",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.502",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125770",
        "data_carregamento": "2024-04-26 13:54:14",
        "data_transmissao": "2024-04-26 10:02:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125770",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.465",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125800",
        "data_carregamento": "2024-04-26 13:54:14",
        "data_transmissao": "2024-04-26 10:03:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125800",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.461",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125829",
        "data_carregamento": "2024-04-26 13:54:13",
        "data_transmissao": "2024-04-26 10:03:49",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125829",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.429",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125860",
        "data_carregamento": "2024-04-26 13:54:13",
        "data_transmissao": "2024-04-26 10:04:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125860",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.421",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125890",
        "data_carregamento": "2024-04-26 13:54:13",
        "data_transmissao": "2024-04-26 10:04:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125890",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.413",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125920",
        "data_carregamento": "2024-04-26 13:54:12",
        "data_transmissao": "2024-04-26 10:05:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125920",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.412",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125950",
        "data_carregamento": "2024-04-26 13:54:12",
        "data_transmissao": "2024-04-26 10:05:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125950",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.392",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125980",
        "data_carregamento": "2024-04-26 13:54:12",
        "data_transmissao": "2024-04-26 10:06:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714125980",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.382",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126010",
        "data_carregamento": "2024-04-26 13:54:11",
        "data_transmissao": "2024-04-26 10:06:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126010",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.382",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126040",
        "data_carregamento": "2024-04-26 13:54:11",
        "data_transmissao": "2024-04-26 10:07:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126040",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.38",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126071",
        "data_carregamento": "2024-04-26 13:54:10",
        "data_transmissao": "2024-04-26 10:07:51",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126071",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.377",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126101",
        "data_carregamento": "2024-04-26 13:54:10",
        "data_transmissao": "2024-04-26 10:08:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126101",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.358",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126131",
        "data_carregamento": "2024-04-26 13:54:10",
        "data_transmissao": "2024-04-26 10:08:51",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126131",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.373",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126160",
        "data_carregamento": "2024-04-26 13:54:09",
        "data_transmissao": "2024-04-26 10:09:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126160",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.364",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126190",
        "data_carregamento": "2024-04-26 13:54:09",
        "data_transmissao": "2024-04-26 10:09:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126190",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.358",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126220",
        "data_carregamento": "2024-04-26 13:54:09",
        "data_transmissao": "2024-04-26 10:10:20",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126220",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.321",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126250",
        "data_carregamento": "2024-04-26 13:54:08",
        "data_transmissao": "2024-04-26 10:10:50",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126250",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.343",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126281",
        "data_carregamento": "2024-04-26 13:54:08",
        "data_transmissao": "2024-04-26 10:11:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126281",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.34",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126311",
        "data_carregamento": "2024-04-26 13:54:08",
        "data_transmissao": "2024-04-26 10:11:51",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126311",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.334",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126341",
        "data_carregamento": "2024-04-26 13:54:07",
        "data_transmissao": "2024-04-26 10:12:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126341",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.33",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126371",
        "data_carregamento": "2024-04-26 13:54:07",
        "data_transmissao": "2024-04-26 10:12:51",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126371",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.326",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126401",
        "data_carregamento": "2024-04-26 13:54:06",
        "data_transmissao": "2024-04-26 10:13:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126401",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.332",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126431",
        "data_carregamento": "2024-04-26 13:54:06",
        "data_transmissao": "2024-04-26 10:13:51",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126431",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.301",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126461",
        "data_carregamento": "2024-04-26 13:54:06",
        "data_transmissao": "2024-04-26 10:14:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126461",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "14.012",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126491",
        "data_carregamento": "2024-04-26 13:54:05",
        "data_transmissao": "2024-04-26 10:14:51",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126491",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "14.232",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126521",
        "data_carregamento": "2024-04-26 13:54:05",
        "data_transmissao": "2024-04-26 10:15:21",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126521",
        "velocidade": 0,
        "odometro": -1386046.28,
        "ignicao": false,
        "bateria": "12.685",
        "geolocalizacao": "-15.8137083,-47.9580283",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126531",
        "data_carregamento": "2024-04-26 13:54:05",
        "data_transmissao": "2024-04-26 10:15:31",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126531",
        "velocidade": 6,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.595",
        "geolocalizacao": "-15.8136933,-47.9579999",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0.01
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126561",
        "data_carregamento": "2024-04-26 13:54:04",
        "data_transmissao": "2024-04-26 10:16:01",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126561",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.418",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126591",
        "data_carregamento": "2024-04-26 13:54:04",
        "data_transmissao": "2024-04-26 10:16:31",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126591",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.356",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126621",
        "data_carregamento": "2024-04-26 13:54:04",
        "data_transmissao": "2024-04-26 10:17:01",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126621",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.331",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126651",
        "data_carregamento": "2024-04-26 13:54:03",
        "data_transmissao": "2024-04-26 10:17:31",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126651",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.332",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126681",
        "data_carregamento": "2024-04-26 13:54:03",
        "data_transmissao": "2024-04-26 10:18:01",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126681",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.318",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126712",
        "data_carregamento": "2024-04-26 13:54:02",
        "data_transmissao": "2024-04-26 10:18:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126712",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.295",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126742",
        "data_carregamento": "2024-04-26 13:54:02",
        "data_transmissao": "2024-04-26 10:19:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126742",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.291",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126772",
        "data_carregamento": "2024-04-26 13:54:02",
        "data_transmissao": "2024-04-26 10:19:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126772",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.291",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126801",
        "data_carregamento": "2024-04-26 13:54:01",
        "data_transmissao": "2024-04-26 10:20:01",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126801",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.289",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126831",
        "data_carregamento": "2024-04-26 13:54:01",
        "data_transmissao": "2024-04-26 10:20:31",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126831",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.291",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126861",
        "data_carregamento": "2024-04-26 13:54:01",
        "data_transmissao": "2024-04-26 10:21:01",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126861",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.284",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126891",
        "data_carregamento": "2024-04-26 13:54:00",
        "data_transmissao": "2024-04-26 10:21:31",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126891",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.281",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126922",
        "data_carregamento": "2024-04-26 13:54:00",
        "data_transmissao": "2024-04-26 10:22:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126922",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.273",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126952",
        "data_carregamento": "2024-04-26 13:53:59",
        "data_transmissao": "2024-04-26 10:22:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126952",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.256",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126982",
        "data_carregamento": "2024-04-26 13:53:59",
        "data_transmissao": "2024-04-26 10:23:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714126982",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.247",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127012",
        "data_carregamento": "2024-04-26 13:53:59",
        "data_transmissao": "2024-04-26 10:23:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127012",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.247",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127042",
        "data_carregamento": "2024-04-26 13:53:58",
        "data_transmissao": "2024-04-26 10:24:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127042",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.247",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127072",
        "data_carregamento": "2024-04-26 13:53:58",
        "data_transmissao": "2024-04-26 10:24:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127072",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.245",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127102",
        "data_carregamento": "2024-04-26 13:53:58",
        "data_transmissao": "2024-04-26 10:25:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127102",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.245",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127132",
        "data_carregamento": "2024-04-26 13:53:57",
        "data_transmissao": "2024-04-26 10:25:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127132",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.242",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127162",
        "data_carregamento": "2024-04-26 13:53:57",
        "data_transmissao": "2024-04-26 10:26:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127162",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.242",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127192",
        "data_carregamento": "2024-04-26 13:53:57",
        "data_transmissao": "2024-04-26 10:26:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127192",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.232",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127222",
        "data_carregamento": "2024-04-26 13:53:56",
        "data_transmissao": "2024-04-26 10:27:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127222",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.227",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127252",
        "data_carregamento": "2024-04-26 13:53:56",
        "data_transmissao": "2024-04-26 10:27:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127252",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.213",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127282",
        "data_carregamento": "2024-04-26 13:53:55",
        "data_transmissao": "2024-04-26 10:28:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127282",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.213",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127313",
        "data_carregamento": "2024-04-26 13:53:55",
        "data_transmissao": "2024-04-26 10:28:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127313",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.202",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127343",
        "data_carregamento": "2024-04-26 13:53:55",
        "data_transmissao": "2024-04-26 10:29:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127343",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.204",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127373",
        "data_carregamento": "2024-04-26 13:53:54",
        "data_transmissao": "2024-04-26 10:29:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127373",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.204",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127403",
        "data_carregamento": "2024-04-26 13:53:54",
        "data_transmissao": "2024-04-26 10:30:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127403",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.203",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127433",
        "data_carregamento": "2024-04-26 13:53:54",
        "data_transmissao": "2024-04-26 10:30:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127433",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.204",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127462",
        "data_carregamento": "2024-04-26 13:53:53",
        "data_transmissao": "2024-04-26 10:31:02",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127462",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.203",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127492",
        "data_carregamento": "2024-04-26 13:53:53",
        "data_transmissao": "2024-04-26 10:31:32",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127492",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.202",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127523",
        "data_carregamento": "2024-04-26 13:53:53",
        "data_transmissao": "2024-04-26 10:32:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127523",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.198",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127553",
        "data_carregamento": "2024-04-26 13:53:52",
        "data_transmissao": "2024-04-26 10:32:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127553",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.202",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127583",
        "data_carregamento": "2024-04-26 13:53:52",
        "data_transmissao": "2024-04-26 10:33:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127583",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.202",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127613",
        "data_carregamento": "2024-04-26 13:53:51",
        "data_transmissao": "2024-04-26 10:33:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127613",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.195",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127643",
        "data_carregamento": "2024-04-26 13:53:51",
        "data_transmissao": "2024-04-26 10:34:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127643",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.182",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127673",
        "data_carregamento": "2024-04-26 13:53:51",
        "data_transmissao": "2024-04-26 10:34:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127673",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.187",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127703",
        "data_carregamento": "2024-04-26 13:53:50",
        "data_transmissao": "2024-04-26 10:35:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127703",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.191",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127734",
        "data_carregamento": "2024-04-26 13:53:50",
        "data_transmissao": "2024-04-26 10:35:34",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127734",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.18",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127764",
        "data_carregamento": "2024-04-26 13:53:50",
        "data_transmissao": "2024-04-26 10:36:04",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127764",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.173",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127793",
        "data_carregamento": "2024-04-26 13:53:49",
        "data_transmissao": "2024-04-26 10:36:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127793",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.169",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127823",
        "data_carregamento": "2024-04-26 13:53:49",
        "data_transmissao": "2024-04-26 10:37:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127823",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.165",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127853",
        "data_carregamento": "2024-04-26 13:53:49",
        "data_transmissao": "2024-04-26 10:37:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127853",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.161",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127883",
        "data_carregamento": "2024-04-26 13:53:48",
        "data_transmissao": "2024-04-26 10:38:03",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127883",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.16",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127913",
        "data_carregamento": "2024-04-26 13:53:48",
        "data_transmissao": "2024-04-26 10:38:33",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127913",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.16",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127944",
        "data_carregamento": "2024-04-26 13:53:47",
        "data_transmissao": "2024-04-26 10:39:04",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127944",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.16",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127974",
        "data_carregamento": "2024-04-26 13:53:47",
        "data_transmissao": "2024-04-26 10:39:34",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714127974",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.16",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714128004",
        "data_carregamento": "2024-04-26 13:53:47",
        "data_transmissao": "2024-04-26 10:40:04",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714128004",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.16",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714128034",
        "data_carregamento": "2024-04-26 13:53:46",
        "data_transmissao": "2024-04-26 10:40:34",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714128034",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.169",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    },
    {
        "_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714128064",
        "data_carregamento": "2024-04-26 13:53:46",
        "data_transmissao": "2024-04-26 10:41:04",
        "geolocalizacao_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5_1714128064",
        "velocidade": 0,
        "odometro": -1386046.27,
        "ignicao": false,
        "bateria": "12.166",
        "geolocalizacao": "-15.8136816,-47.9579983",
        "horimetro": "",
        "logradouro": "Secretaria de Estado de Educação do Distrito Federal - Unidade III, IA SP, SIA, Região Geográfica Imediata do Distrito Federal,   Distrito Federal, Região Centro-Oeste, 71215-901, Brasil",
        "placa": "MR00001",
        "equipamento_id": "a3f78b72-ae48-44df-81a3-fe7ab11828f5",
        "marca": "Mitsubishi",
        "modelo": "Outlander",
        "descricao": "MR-01 (rastreador Teltonika)",
        "grupo_nome": "CAMINHÃO BASCULANTE DE 14 M³",
        "alocacao_id": "026c814b-447d-4676-a8f9-953a2fdd063e",
        "contrato_id": "34849537-721d-40be-9af3-f6221298cc09",
        "codigo_alocacao": "MR-02",
        "frota": "SUV",
        "distancia_granular": 0
    }
]