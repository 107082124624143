<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h1 class="fs-3 fw-light mb-0">{{ usuario.firstName }} {{ usuario.lastName }}</h1>
                <div>
                    <button type="button" mat-button color="accent" class="me-3" [disabled]="loading.reserPassword" 
                        (click)="sendResetPasswordEmail()">
                        <mat-spinner class="btn-spinner" *ngIf="loading.reserPassword"></mat-spinner>
                        <span *ngIf="!loading.reserPassword">ENVIAR EMAIL DE SENHA</span>
                    </button>
                    <button mat-flat-button color="primary" class="px-4" [routerLink]="authService.user.id == usuario.id ? ['/perfil'] : ['/usuario', usuario.id, 'editar']">
                        EDITAR
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-3">
                    <mat-divider></mat-divider>
                </div>
                <div class="col-12 col-md-auto d-flex flex-column align-items-center divAvatar pe-3">
                    <img height="140" width="140" class="img-cover rounded-circle mb-2 bg-default" [src]="usuario.avatarUrl || '/assets/img/default_user.jpeg'" />
                    <div class="status-badge text-white px-3 py-1 fs-7 {{ usuario.enabled ? 'bg-success' : 'bg-danger' }}">
                        {{ usuario.enabled ? 'ATIVO' : 'INATIVO' }}
                    </div>
                </div>
                <div class="col-12 col-md">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-3">
                            <span class="fw-bold fs-7 opacity-50">EMAIL</span>
                            <div class="lh-sm">{{ usuario.email }}</div>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <span class="fw-bold fs-7 opacity-50">TELEFONE</span>
                            <div class="lh-sm">{{ usuario.telefone | mask: '(00) 00000-0000' }}</div>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <span class="fw-bold fs-7 opacity-50">PERFIL</span>
                            <div class="lh-sm">{{ usuario.role | translate }}</div>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <span class="fw-bold fs-7 opacity-50">CARGO</span>
                            <div class="lh-sm">{{ usuario.cargo }}</div>
                        </div>
                        <div class="col-12 col-md-6 mb-3" *ngIf="aferidor">
                            <span class="fw-bold fs-7 opacity-50">NÚMERO AFERIDOR</span>
                            <div>{{ aferidor.numero }}</div>
                        </div>
                        <div class="col-12 mb-3" [ngClass]="{'col-md-6': aferidor}">
                            <span class="fw-bold fs-7 opacity-50">DESCRIÇÃO</span>
                            <div>{{ usuario.descricao }}</div>
                        </div>                    
                    </div>
                </div>

                <!-- AUSÊNCIA -->
                <div class="col-12 mb-3">
                    <mat-divider></mat-divider>
                </div>
                <div class="col-12 d-flex align-items-end mb-3">
                    <h1 class="fs-3 fw-light mb-1">Ausências</h1>
                    <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/usuario', usuario.id, 'ausencia']">
                        NOVA AUSÊNCIA
                    </button>             
                </div>
                <div class="col-12 overflow-x-auto">
                    <generate-table [dataSource]="ausencias" [columns]="columns" [loading]="loading.ausencia"></generate-table>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>