import { Injectable } from "@angular/core";
import { GenericCRUDService } from "../shared/services";
import { HttpClient } from "@angular/common/http";
import { Data, Pagination } from 'src/app/shared/models';
import { Equipamento } from "../equipamento";

@Injectable({
    providedIn: 'root'
})
export class MetricaService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/etrack/metrics`;
    }

    getMetrics(placa: string, start: string, end: string) {
        const params = new URLSearchParams();
        params.append('placa', placa);
        params.append('start', start);
        params.append('end', end);
        return this.http.get<Data<Equipamento>>(`${this.url}?${params}`);
    }

    getMetricsByOdometro(placa: string, start: string, end: string) {
        const params = new URLSearchParams();
        params.append('placa', placa);
        params.append('start', start);
        params.append('end', end);
        return this.http.get<Data<Equipamento>>(`${this.url}/by-odometro?${params}`);
    }

}