import { Component, OnInit } from '@angular/core';
import { Beneficiaria } from '../../beneficiaria.model';
import { AuthService } from 'src/app/core';
import { ActivatedRoute } from '@angular/router';
import { BeneficiariaService } from '../../beneficiaria.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';

@Component({
    selector: 'detail-beneficiaria',
    templateUrl: './detail-beneficiaria.component.html'
})
export class DetailBeneficiariaComponent implements OnInit {

    loading: boolean;
    beneficiaria = {} as Beneficiaria;

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private beneficiariaService: BeneficiariaService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.loading = true;
        this.beneficiariaService.getById<Beneficiaria>(this.activatedRoute.snapshot.params['idBeneficiaria']).subscribe({
            next: (res) => this.beneficiaria = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

}
